// @ts-ignore
import { components } from 'react-select'
import { ChevronDownSolid } from '../../leroy-merlin-br/backyard-icons'

export function ClearIcon() {
  return (
    <svg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='10' cy='10.5' r='10' fill='#EEEEF0' />
      <path
        d='M11.0213 10.5L14 7.52128L12.9787 6.5L10 9.47872L7.10638 6.5L6 7.60639L8.97872 10.5L6 13.4787L7.02128 14.5L10 11.5213L12.9787 14.5L14 13.4787L11.0213 10.5Z'
        fill='#3C3738'
      />
    </svg>
  )
}

export function DropdownIndicator(props: any) {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronDownSolid className='css-1opf1jk-input__suffix' color='#BAB6BC' />
    </components.DropdownIndicator>
  )
}

export function ClearIndicator(props: any) {
  return (
    <components.ClearIndicator {...props}>
      <ClearIcon />
    </components.ClearIndicator>
  )
}
