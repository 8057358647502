import { Button } from '@mozaic-ds/react'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import checkboxChecked from '../../../../assets/icons/checkbox-checked-white.svg'
import checkboxDisabled from '../../../../assets/icons/checkbox-unchecked-white.svg'
import MozaicIcon from '../../../../components/MozaicIcon'
import MozaicText from '../../../../components/MozaicText'
import Col from '../../../../components/utils/Col'
import CopyToClipboardIcon from '../../../../components/utils/CopyToClipboardIcon'
import Field from '../../../../components/utils/Field'
import Grid from '../../../../components/utils/Grid'
import Row from '../../../../components/utils/Row'
import Select from '../../../../components/utils/Select'
import { FORMAT_DATE, ORDER_STATUS_TYPE } from '../../../../constants/config'
import { useAdvancedSearchContext } from '../../../../contexts/advancedSearch'
import { useServiceOrderContext } from '../../../../contexts/serviceOrder'
import ServcOrdCategoryType from '../../../../enums/ServiceOrder/category/serviceCategoryType'
import ServcOrdEvntStusType from '../../../../enums/ServiceOrder/event/ServcOrdEvntStusType'
import ServiceOrderStatusType from '../../../../enums/ServiceOrder/status/ServiceOrderStatusType'
import ServiceOrderTagType from '../../../../enums/ServiceOrder/tags/ServiceOrderTagType'
import { formatNationalOrInternationalPhoneNumber } from '../../../../helpers/InternationalPhoneNumberUtils'
import { renderToastError, renderToastSuccessMsg } from '../../../../helpers/ToastUtils'
import { formatCEP, removeMask } from '../../../../helpers/masks'
import { isHogamiOrder } from '../../../../helpers/serviceOrderUtilis'
import { getOsRoutePrefix } from '../../../../helpers/serviceOrderUtils'
import * as serviceOrderActions from '../../../../redux/actions/serviceOrder'
import * as worksiteActions from '../../../../services/Worksite'
import ServiceOrderModal from '../ServiceOrderModal'

function HeaderInfo(props) {
  const {
    handleChange,
    renderStatusOption,
    removeFromCurrentWorksite,
    moveToExistingWorksite,
    serviceOrderStatuses,
    fields,
  } = props

  const {
    t,
    serviceOrder,
    disabled,
    funcaoAcesso,
    setFormValue,
    register,
    values,
    onSubmit,
    handleObsChange,
    onSubmitHeaderObs,
    onSubmitContactInformation,
    auth,
    getServiceOrder,
    serviceConditionAcceptance,
    handleChangeStatusOrder,
    mergeWithServiceOrder,
    serviceOrder: { serviceOrderOccurrence, servcOrdStusCd },
  } = useServiceOrderContext()

  const { handleSubmitDefault } = useAdvancedSearchContext()

  const [modalOpen, setModalOpen] = useState(false)
  const [serviceOrderModal, setServiceOrderModal] = useState(false)
  const [returnToSchedulingModal, setReturnToSchedulingModal] = useState(false)
  const [returnToPreSchedledModal, setReturnToPreSchedledModal] = useState(false)
  const [openWorksiteModal, setOpenWorksiteModal] = useState(false)
  const [worksiteMode, setWorksiteMode] = useState('move')
  const [newWorksiteNumber, setNewWorksiteNumber] = useState(serviceOrder.servcOrdWorksiteNumber)
  const [currentWorksiteNumber, setCurrentWorksiteNumber] = useState(
    serviceOrder?.servcOrdWorksiteNumber,
  )
  const [currentWorksiteQuantity, setCurrentWorksiteQuantity] = useState(
    serviceOrder?.sameWorksiteServiceCount,
  )

  const hasDistributedManualtag = serviceOrderOccurrence?.some(
    (stusInd) =>
      stusInd.servcOrdEvntStusInd === ServcOrdEvntStusType.REFUSAL_PROCESS.id ||
      stusInd.servcOrdEvntStusInd === ServcOrdEvntStusType.MANUAL_DISTRIBUTION.id,
  )

  function toggleServiceOrderModal() {
    setServiceOrderModal(!serviceOrderModal)
  }

  function onSubmitObs(event) {
    toggleServiceOrderModal()
    onSubmitHeaderObs(event)
  }

  function renderServiceOrderModal() {
    const checkServcOrdRmrkTxt =
      typeof serviceOrder.servcOrdRmrkTxt === 'undefined' || serviceOrder.servcOrdRmrkTxt === null

    if (!serviceOrderModal) return

    if (checkServcOrdRmrkTxt) {
      serviceOrder.servcOrdRmrkTxt = ''
    }

    const initialValues = {
      servcOrdRmrkTxt: serviceOrder.servcOrdRmrkTxt,
    }

    const props = {
      t,
      modalType: 'VIEW_OBSERVATION',
      closeModal: toggleServiceOrderModal,
      values: serviceOrder,
      onChange: handleObsChange,
      initialValues,
      disabled,
      onSubmit: onSubmitObs,
      auth,
    }

    return <ServiceOrderModal {...props} />
  }

  function isDisabled() {
    let disabled = false
    const hasTagServcPrvdrManualDstrbt = serviceOrder.serviceOrderTags.some(
      (e) => e.tagType === ServiceOrderTagType.MANUAL_DISTRIBUTED.tagName,
    )

    if (!auth.user.accessFunctionList?.includes(116)) {
      return true
    }

    if (serviceOrder.isConformity && auth.setupParameters.conformity) {
      return true
    }

    if (
      auth.setupParameters.servcPrvdrManualDistributionIsActive &&
      fields.servcOrdStusCd.input.value === ORDER_STATUS_TYPE.DISTRIBUTED &&
      !hasTagServcPrvdrManualDstrbt
    ) {
      return true
    }

    if (
      auth.setupParameters.servcPrvdrManualDistributionIsActive &&
      fields.servcOrdStusCd.input.value === ORDER_STATUS_TYPE.DISTRIBUTED &&
      !hasTagServcPrvdrManualDstrbt
    ) {
      return true
    }

    switch (fields.servcOrdStusCd.input.value) {
      case ORDER_STATUS_TYPE.PRE_SCHEDULE:
      case ORDER_STATUS_TYPE.SCHEDULED:
      case ORDER_STATUS_TYPE.PARTIALLY_DONE:
        disabled = true
        break
    }

    if (
      fields.servcOrdStusCd.input.value === ORDER_STATUS_TYPE.CONFIRMED &&
      funcaoAcesso.inFuncao04 !== 1
    ) {
      return true
    }

    return disabled
  }

  function openAddrsAndFoneModal() {
    setModalOpen(true)
  }

  function closeAddrsAndFoneModal() {
    setModalOpen(false)
  }

  function handleChangeHeader(event) {
    const { name } = event.target

    fields[name].input.onChange(event)
  }

  function handleCheckHasAddress() {
    const { servcAddrStrNm, servcAddrNr, servcAddrCityNm, servcAddrStCd, cntryNm } = serviceOrder

    return (
      servcAddrStrNm !== undefined &&
      servcAddrStrNm !== null &&
      servcAddrNr !== undefined &&
      servcAddrNr !== null &&
      servcAddrCityNm !== undefined &&
      servcAddrCityNm !== null &&
      servcAddrStCd !== undefined &&
      servcAddrStCd !== null &&
      cntryNm !== undefined &&
      cntryNm !== null
    )
  }

  function getFormattedUrlMaps(str) {
    if (str && str.indexOf) {
      let pos = str.indexOf(' ')

      while (pos > -1) {
        // eslint-disable-next-line no-param-reassign
        str = str.replace(' ', '+')
        pos = str.indexOf(' ')
      }

      return str
    }
  }

  function urlMaps(serviceOrder) {
    if (handleCheckHasAddress()) {
      const baseUrl = 'https://www.google.com.br/maps/place/'
      const url = baseUrl.concat(
        getFormattedUrlMaps(serviceOrder.servcAddrStrNm),
        ',+',
        getFormattedUrlMaps(serviceOrder.servcAddrNr),
        '+-+',
        getFormattedUrlMaps(serviceOrder.servcAddrCityNm),
        ',',
        getFormattedUrlMaps(serviceOrder.servcAddrStCd),
        '+-+',
        getFormattedUrlMaps(serviceOrder.cntryNm),
      )

      return (
        <a className='color-neutral' href={url} target='_blank' rel='noreferrer'>
          <p className='no-margin'>
            {serviceOrder.servcAddrStrNm ? `${serviceOrder.servcAddrStrNm}, ` : '-, '}
            {serviceOrder.servcAddrNr ? `${serviceOrder.servcAddrNr} - ` : '- '}
            {serviceOrder.servcAddrCmplmtryTxt ? `${serviceOrder.servcAddrCmplmtryTxt}` : '-'}
          </p>
        </a>
      )
    }
    return (
      <a className='color-neutral' target='_blank'>
        <p className='no-margin'>
          {serviceOrder.servcAddrStrNm ? `${serviceOrder.servcAddrStrNm}, ` : '-, '}
          {serviceOrder.servcAddrNr ? `${serviceOrder.servcAddrNr} - ` : '- '}
          {serviceOrder.servcAddrCmplmtryTxt ? `${serviceOrder.servcAddrCmplmtryTxt}` : '-'}
        </p>
      </a>
    )
  }

  function serviceOrderAlt(event) {
    event.preventDefault()

    onSubmit(auth.user.dsToken, values)
    closeAddrsAndFoneModal()
  }

  async function submitContactInformation(values) {
    await onSubmitContactInformation(auth.user.dsToken, serviceOrder.servcOrdSeq, values)
  }

  function renderAddrsAndFoneModal() {
    if (!modalOpen) {
      return
    }

    const formProps = {
      setFormValue,
      values,
      fields,
      register,
    }

    const props = {
      t,
      modalType: 'ALTER_INFO',
      closeModal: closeAddrsAndFoneModal,
      disabled: false,
      showCloseButton: false,
      handleChange: handleChangeHeader,
      sendOrder: serviceOrderAlt,
      onSubmit: (values) => submitContactInformation(values),
      serviceOrder,
      auth,
    }

    return <ServiceOrderModal {...props} {...formProps} />
  }

  function renderTermsClient() {
    return (
      <div className='margin-top'>
        <div className='rgpd-image'>
          <img
            src={
              serviceOrder && serviceOrder.servcDataCnsntCustInd === '1'
                ? checkboxChecked
                : checkboxDisabled
            }
          />
        </div>
        <div className='margin-bottom-small'>
          <span>{t('service.order.rgpd.service')}</span>
        </div>
        <div className='rgpd-image'>
          <img
            src={
              serviceOrder && serviceOrder.mrktgDataCnsntCustInd === '1'
                ? checkboxChecked
                : checkboxDisabled
            }
          />
        </div>
        <span>{t('service.order.rgpd.marketing')}</span>
      </div>
    )
  }

  function toggleReturnToReschedulingModal() {
    setReturnToSchedulingModal(!returnToSchedulingModal)
  }

  function renderReschedulingLink() {
    const {
      setupParameters: { statusConfiguredDisplayScheduleLink },
    } = auth
    const { accessFunctionList } = auth.user

    let enableLink = false

    const isRescheduling = servcOrdStusCd === ServiceOrderStatusType.STATUS_AGENDADA.id

    const hasOSStatusOnParameter = statusConfiguredDisplayScheduleLink?.includes(servcOrdStusCd)

    const hasAccessFunction = accessFunctionList && accessFunctionList?.includes(110)

    const hasPermission = hasOSStatusOnParameter && hasAccessFunction

    if (hasPermission) {
      enableLink = true
    }

    return (
      <>
        {!isRescheduling && enableLink ? (
          <button
            className='back-order-status-link fifty-percent-width'
            onClick={() => {
              toggleReturnToReschedulingModal()
            }}
          >
            {t('serviceOrders.change.os.to.reschedule')}
          </button>
        ) : null}
      </>
    )
  }

  function onSubmitReschedulingOS(value) {
    serviceOrder.servcOrdStusCd = ServiceOrderStatusType.STATUS_AGENDADA.id
    if (value && typeof value === 'string') serviceOrder.justificationBackToScheduled = value

    onSubmit(serviceOrder)
    toggleReturnToReschedulingModal()
  }

  function renderReturnToSchedulingModal() {
    if (!returnToSchedulingModal) {
      return
    }

    const props = {
      t,
      modalType: 'RESCHEDULING',
      auth,
      disabled: false,
      showCloseButton: false,
      closeModal: toggleReturnToReschedulingModal,
      onSubmit: onSubmitReschedulingOS,
      isManualDistribution: hasDistributedManualtag,
    }

    return <ServiceOrderModal {...props} />
  }

  function toggleReturnToPreScheduledModal() {
    setReturnToPreSchedledModal(!returnToPreSchedledModal)
  }

  function renderPreRescheduledLink() {
    const {
      setupParameters: { statusConfiguredDisplayPreScheduleLink },
    } = auth
    const { accessFunctionList } = auth.user

    const hasAccessFunction = accessFunctionList && accessFunctionList.includes(121)

    const hasOSStatusOnParameter = statusConfiguredDisplayPreScheduleLink?.includes(servcOrdStusCd)

    const showLink = hasOSStatusOnParameter && hasAccessFunction && !isHogamiOrder(serviceOrder)

    return (
      <>
        {showLink ? (
          <button
            className='back-order-status-link fifty-percent-width'
            onClick={() => toggleReturnToPreScheduledModal()}
          >
            {t('serviceOrders.change.os.to.pre.scheduled')}
          </button>
        ) : null}
      </>
    )
  }

  function onSubmitPreScheduledOS(value) {
    const justificationText = value && typeof value === 'string' ? value : null

    const params = {
      servcOrdStusCd: '00',
      servcOrdSeq: serviceOrder.servcOrdSeq,
      justificationBackToPreScheduled: justificationText,
    }

    handleChangeStatusOrder(params)
    mergeWithServiceOrder(params)

    toggleReturnToPreScheduledModal()
  }

  function renderPreScheduledModal() {
    if (!returnToPreSchedledModal) {
      return
    }

    const props = {
      t,
      auth,
      modalType: 'PRE_RESCHEDULED',
      disabled: false,
      showCloseButton: false,
      closeModal: toggleReturnToPreScheduledModal,
      onSubmit: onSubmitPreScheduledOS,
      isManualDistribution: hasDistributedManualtag,
    }

    return <ServiceOrderModal {...props} />
  }

  function handleCounterWorksite(total) {
    const service = 'service.order.details.service'
    const services = 'homepage.tab.services'
    const selectedLabel = total === 1 ? service : services
    return `(${total || 0} ${t(selectedLabel)})`
  }

  function handleToggleWorkSiteModal() {
    setOpenWorksiteModal(!openWorksiteModal)
  }

  function updateWorksiteValues(updatedServiceOrder) {
    setCurrentWorksiteNumber(updatedServiceOrder?.servcOrdWorksiteNumber)
    setNewWorksiteNumber(updatedServiceOrder?.servcOrdWorksiteNumber)
    setCurrentWorksiteQuantity(updatedServiceOrder?.sameWorksiteServiceCount)
    setWorksiteMode('move')
  }

  async function handleUpdateWorksite() {
    const { servcOrdSeq, servcOrdCd } = serviceOrder
    let updatedServiceOrder = null

    try {
      if (worksiteMode === 'remove') {
        await removeFromCurrentWorksite(servcOrdSeq)
        updatedServiceOrder = await getServiceOrder(auth.user.dsToken, {
          servcOrdCd,
        })
        updateWorksiteValues(updatedServiceOrder[0])
        renderToastSuccessMsg(t('serviceOrder.worksite.modified'))
      }
      if (worksiteMode === 'move') {
        await moveToExistingWorksite(servcOrdSeq, newWorksiteNumber)
        updatedServiceOrder = await getServiceOrder(auth.user.dsToken, {
          servcOrdCd,
        })
        updateWorksiteValues(updatedServiceOrder[0])
        renderToastSuccessMsg(t('serviceOrder.worksite.modified'))
      }
    } catch (error) {
      renderToastError(error.message.dsMessage || t('dao.save.error'))
    } finally {
      handleToggleWorkSiteModal()
    }
  }

  function handleChangeWorksiteMode(worksiteMode) {
    setWorksiteMode(worksiteMode)
  }

  function handleChangeWorksiteValue(worksiteNumber) {
    setNewWorksiteNumber(worksiteNumber)
  }

  function resetWorksiteValues() {
    const { servcOrdWorksiteNumber } = serviceOrder

    setNewWorksiteNumber(servcOrdWorksiteNumber)
    setWorksiteMode('move')
  }

  function renderWorksiteModal() {
    if (!openWorksiteModal) {
      return
    }

    const props = {
      t,
      modalType: 'WORKSITE',
      auth,
      disabled: false,
      showCloseButton: false,
      mode: worksiteMode,
      currentWorksiteNumber,
      newWorksiteNumber,
      currentWorksiteQuantity,
      closeModal: handleToggleWorkSiteModal,
      onSubmit: handleUpdateWorksite,
      onChangeWorksiteMode: handleChangeWorksiteMode,
      handleChangeWorksiteValue,
      resetWorksiteValues,
    }

    return <ServiceOrderModal {...props} />
  }

  function handleFilterByWorksite() {
    const { servcOrdWorksiteNumber } = serviceOrder

    handleSubmitDefault({
      currentSearchField: 'servcOrdWorksiteNumber',
      currentSearchFieldValue: servcOrdWorksiteNumber.toString(),
    })
  }

  const { custMobilePhonNr, custBusnsPhonNr, custHmPhonNr } = serviceOrder

  const formattedBusinessPhone = formatNationalOrInternationalPhoneNumber(t, custBusnsPhonNr)
  const formattedMobilePhone = formatNationalOrInternationalPhoneNumber(t, custMobilePhonNr)
  const formattedHomePhone = formatNationalOrInternationalPhoneNumber(t, custHmPhonNr)

  const permissionToClipboard =
    auth.user?.accessFunctionList && auth.user?.accessFunctionList?.includes(98)

  const showGoToNewLayoutButton = auth.user?.accessFunctionList?.includes(129)

  return (
    <section id='header-info-container'>
      <Grid fluid>
        <Row className='content-header color-neutral padding-vertical-double elevation'>
          <Col lg={3} xs={3} className='order-info'>
            <h4 className='no-margin strong'>
              <span className='noselect'>{`${t('lmi.serviceOrder')} `}</span>
              {serviceOrder.servcOrdCd}
            </h4>

            <h5 className='no-margin strong'>
              <span className='noselect'>{t('serviceOrders.category')}</span>
              {t(ServcOrdCategoryType.getCatgryTypDesc(serviceOrder.servcCatgryTypCd))}
            </h5>

            <span className='display-block noselect'>
              {`${t('serviceOrders.creationDateCapitalized')} ${moment(serviceOrder.creatTs).format(
                FORMAT_DATE,
              )}`}
            </span>

            <span className='display-block noselect'>
              {`${t('serviceOrders.store')}: ${serviceOrder.plntNm || ''}`}
            </span>

            <span className='display-block'>
              <span className='noselect'>{`${t('serviceOrders.order')}: `}</span>
              {serviceOrder.vaSlsOrdNr || ''}
            </span>

            <span className='display-block padding-bottom'>
              <span className='noselect'>{`${t('serviceOrder.fields.zone')}: `}</span>
              {serviceOrder.servcRgnZn || '-'}
            </span>

            <div className='worksite-container'>
              <span>{t('serviceOrder.worksite.title')}:</span>
              <a
                role='button'
                className='color-neutral link'
                onClick={() => handleFilterByWorksite()}
              >
                <u>{serviceOrder.servcOrdWorksiteNumber}</u>
              </a>
              <span className='count-worksites'>
                {handleCounterWorksite(serviceOrder.sameWorksiteServiceCount)}
              </span>

              {!isHogamiOrder(serviceOrder) && (
                <span className='mt--3'>
                  <MozaicIcon icon='PublishEdit24' color='white' />
                  <a
                    role='button'
                    className='color-neutral link'
                    onClick={handleToggleWorkSiteModal}
                  >
                    {t('serviceOrder.worksite.link.change')}
                  </a>
                </span>
              )}
            </div>

            <Field className='no-margin noselect' {...fields.servcOrdStusCd} validate>
              <Select
                {...fields.servcOrdStusCd}
                id='servcOrdStusCd'
                name='servcOrdStusCd'
                className='first-opt-hidden noselect'
                onChange={handleChange}
                value={fields.servcOrdStusCd.input.value || serviceOrder?.servcOrdStusCd}
                disabled={disabled || isDisabled()}
              >
                <option />
                {serviceOrderStatuses.map(renderStatusOption)}
              </Select>
            </Field>
            <br />

            <section className='flex-container'>
              {renderPreRescheduledLink()}
              {renderReschedulingLink()}
            </section>
          </Col>

          <Col lgOffset={1} xs={4} className='noselect'>
            <h4 className='no-margin strong'>{serviceOrder.custFullNm}</h4>

            <a className='link-call'>
              <span>{`${t(
                'serviceOrders.phone1',
              )}: ${formattedMobilePhone || '-'}`}</span>

              {permissionToClipboard && (
                <CopyToClipboardIcon color='neutral' text={formattedMobilePhone || ''} t={t} />
              )}
            </a>

            <a className='link-call'>
              <span>{`${t(
                'serviceOrders.phone2',
              )}: ${formattedBusinessPhone || '-'}`}</span>

              {permissionToClipboard && (
                <CopyToClipboardIcon color='neutral' text={formattedBusinessPhone || ''} t={t} />
              )}
            </a>

            <a className='link-call'>
              <span>{`${t(
                'serviceOrders.phone3',
              )}: ${formattedHomePhone || '-'}`}</span>

              {permissionToClipboard && (
                <CopyToClipboardIcon color='neutral' text={formattedHomePhone || ''} t={t} />
              )}
            </a>

            {!serviceConditionAcceptance || renderTermsClient()}
          </Col>

          <Col xs={3} className='noselect'>
            {urlMaps(serviceOrder)}
            <MozaicText className='no-margin'>
              {serviceOrder.servcAddrDstrctNm
                ? `${t('service.order.client.detatails.district')} ${
                    serviceOrder.servcAddrDstrctNm
                  }`
                : '-'}
            </MozaicText>
            <MozaicText className='no-margin'>
              {serviceOrder.servcAddrCityNm ? `${serviceOrder.servcAddrCityNm} - ` : '- '}
              {serviceOrder.servcAddrStCd ? `${serviceOrder.servcAddrStCd}` : '-'}
            </MozaicText>

            <MozaicText className='no-margin'>
              {formatCEP(serviceOrder.servcAddrPstlCd, t)}
            </MozaicText>

            {showGoToNewLayoutButton && (
              <Link
                role='button'
                className='link-go-to-new-layout'
                to={`${getOsRoutePrefix()}/${serviceOrder.servcOrdCd}`}
              >
                <Button className='link-go-to-new-layout-button' variant='bordered'>
                  {t('serviceOrder.goToNewLayout')}
                </Button>
              </Link>
            )}
          </Col>

          <Col xs={1} className='align-right noselect'>
            <InfoIcon clickIcon={toggleServiceOrderModal} />
          </Col>

          {funcaoAcesso.inFuncao84 && !isHogamiOrder(serviceOrder) && (
            <EditIcon clickIcon={openAddrsAndFoneModal} />
          )}
        </Row>
        {renderAddrsAndFoneModal()}
        {renderServiceOrderModal()}
        {renderReturnToSchedulingModal()}
        {renderWorksiteModal()}
        {renderPreScheduledModal()}
      </Grid>
    </section>
  )
}

HeaderInfo.propTypes = {
  handleChange: PropTypes.func.isRequired,
  renderStatusOption: PropTypes.func.isRequired,
  removeFromCurrentWorksite: PropTypes.func.isRequired,
  moveToExistingWorksite: PropTypes.func.isRequired,
  serviceOrderStatuses: PropTypes.array.isRequired,
  fields: PropTypes.object.isRequired,
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...worksiteActions,
      ...serviceOrderActions,
    },
    dispatch,
  )

const HeaderInfoConnected = connect(null, mapDispatchToProps)(HeaderInfo)

export default HeaderInfoConnected

function InfoIcon(props) {
  const { clickIcon } = props
  return (
    <a role='button' onClick={clickIcon}>
      <MozaicIcon icon='NotificationInformation24' className='info-icon' />
    </a>
  )
}

InfoIcon.propTypes = {
  clickIcon: PropTypes.func.isRequired,
}

function EditIcon(props) {
  const { clickIcon } = props

  return (
    <Col xs={1} className='color-neutral'>
      <a role='button' className='color-neutral' onClick={clickIcon}>
        <MozaicIcon icon='PublishEdit24' className='edit-icon' />
      </a>
    </Col>
  )
}

EditIcon.propTypes = {
  clickIcon: PropTypes.func.isRequired,
}
