import SummaryMain from '../../SummaryMain'

import Observations from './components/Observations'
import PyxisObservations from './components/PyxisObservations'
import ClientInfo from './components/ClientInfo'
import OrderDetails from './components/OrderDetails'
import Warranty from './components/Warranty'
import ReworkWarranty from './components/ReworkWarranty'
import BudgetDetails from './components/BudgetDetails'

function Service() {
  return (
    <div id='service-container'>
      <SummaryMain />
      <Observations />
      <PyxisObservations />
      <ClientInfo />
      <OrderDetails />
      <BudgetDetails />
      <ReworkWarranty />
      <Warranty />
    </div>
  )
}

export default Service
