class ServiceOrderTagType {
  static MANUAL_DISTRIBUTED = new ServiceOrderTagType(
    ['00', '01', '02'],
    'MANUAL_DISTRIBUTED',
    'serviceOrdersFragment.badge.manualDistributed',
    'badge badge-dark',
  )

  static ATTENTION = new ServiceOrderTagType(
    [],
    'ATTENTION',
    'serviceOrders.attentionOccurrence',
    'badge badge-danger noselect',
  )

  static REJECTED = new ServiceOrderTagType(
    [],
    'REJECTED',
    'serviceOrders.rejected',
    'badge badge-danger noselect',
  )

  static ACCEPTANCE_SALES_CONDITIONS = new ServiceOrderTagType(
    [],
    'ACCEPTANCE_SALES_CONDITIONS',
    'serviceOrders.acceptanceSalesConditions',
    'badge badge-purple-ligth noselect',
  )

  static ID_CONFORMITY = new ServiceOrderTagType(
    [],
    'ID_CONFORMITY',
    'serviceOrders.idConformity',
    'badge badge-purple-ligth noselect',
  )

  static EXPIRED = new ServiceOrderTagType(
    [],
    'EXPIRED',
    'serviceOrders.expired',
    'badge badge-danger noselect',
  )

  static WAITING_CONFIRMATION = new ServiceOrderTagType(
    [],
    'WAITING_CONFIRMATION',
    'serviceOrders.waitingConfirmation',
    'badge badge-danger noselect',
  )

  static LATE_ORDER = new ServiceOrderTagType(
    [],
    'LATE_ORDER',
    'serviceOrders.lateOrder',
    'badge badge-lateOrder noselect',
  )

  static REWORK = new ServiceOrderTagType(
    [],
    'REWORK',
    'serviceOrdersFragment.badge.rework',
    'badge badge-expired-so noselect',
  )

  static WARNING_OUT_OF_FLOW_PROCESS = new ServiceOrderTagType(
    [],
    'WARNING_OUT_OF_FLOW_PROCESS',
    'outOfFlowRequest.warnig',
    'badge badge-warning-out-of-flow-request noselect',
  )

  static APPROVED_OUT_OF_FLOW_PROCESS = new ServiceOrderTagType(
    [],
    'APPROVED_OUT_OF_FLOW_PROCESS',
    'approvedOutOfFlowRequest.badge',
    'badge badge-approved-out-of-flow-request noselect',
  )

  static REFUSED_OUT_OF_FLOW_PROCESS = new ServiceOrderTagType(
    [],
    'REFUSED_OUT_OF_FLOW_PROCESS',
    'refusedOutOfFlowRequest.badge',
    'badge badge-danger noselect',
  )

  static QUOTATION_APPROVED = new ServiceOrderTagType(
    [],
    'QUOTATION_APPROVED',
    'serviceOrder.budget.approved',
    'badge badge-quotation-approved noselect',
  )

  static QUOTATION_REFUSED = new ServiceOrderTagType(
    [],
    'QUOTATION_REFUSED',
    'serviceOrder.budget.refused',
    'badge badge-quotation-refused noselect',
  )

  static NFE_SENT = new ServiceOrderTagType(
    [],
    'NFE_SENT',
    'serviceOrder.badge.nfe.sent',
    'badge badge-nf-sent noselect',
  )

  static NFE_AWAITING_SHIPMENT = new ServiceOrderTagType(
    [],
    'NFE_AWAITING_SHIPMENT',
    'serviceOrder.badge.nfe.awaiting.shipment',
    'badge badge-nf-awaiting-shipment noselect',
  )

  static NFE_LATE = new ServiceOrderTagType(
    [],
    'NFE_LATE',
    'serviceOrder.badge.nfe.late',
    'badge badge-danger noselect',
  )

  static AWAITING_MEASUREMENT_SHEET = new ServiceOrderTagType(
    [],
    'AWAITING_MEASUREMENT_SHEET',
    'serviceOrder.badge.measurement.awaiting',
    'badge badge-awaiting-measurement-sheet noselect',
  )

  static VALIDATE_MEASUREMENT_SHEET = new ServiceOrderTagType(
    [],
    'VALIDATE_MEASUREMENT_SHEET',
    'serviceOrder.badge.measurement.validate',
    'badge badge-validate-measurement-sheet noselect',
  )

  static EMERGENCY_SERVICE = new ServiceOrderTagType(
    [],
    'EMERGENCY_SERVICE',
    'serviceOrder.badge.emergency.service',
    'badge badge-emergency-service noselect',
  )

  static PARTIALLY_DONE = new ServiceOrderTagType(
    [],
    'PARTIALLY_DONE',
    'partially.performed',
    'badge badge-partially-performed-service noselect',
  )

  static RESCHEDULED_BY_CLIENT = new ServiceOrderTagType(
    [],
    'RESCHEDULED_BY_CLIENT',
    'partially.performed',
    'badge badge-partially-performed-service noselect',
  )

  static TECHNICAL_REPORT = new ServiceOrderTagType(
    [],
    'TECHNICAL_REPORT',
    'serviceOrder.badge.technicalReport',
    'badge badge-warning noselect',
  )

  static TECHNICAL_REPORT_SENT = new ServiceOrderTagType(
    [],
    'TECHNICAL_REPORT_SENT',
    'serviceOrders.occurrences.servcOrdEvntStusInd.97',
    'badge badge-secondary noselect',
  )

  static WAITING_RESPONSIBLE = new ServiceOrderTagType(
    [],
    'WAITING_RESPONSIBLE',
    'serviceOrder.badge.waitingResponsible',
    'badge badge-waiting-responsible noselect',
  )

  static WAITING_BUDGET = new ServiceOrderTagType(
    [],
    'WAITING_BUDGET',
    'serviceOrder.badge.waitingBudget',
    'badge badge-waiting-budget noselect',
  )

  static RECOMMEND_INSPECTION = new ServiceOrderTagType(
    [],
    'RECOMMEND_INSPECTION',
    'serviceOrder.badge.recommendInspection',
    'badge badge-dark',
  )

  static ATTETION_TO_DELIVERY = new ServiceOrderTagType(
    [],
    'ATTETION_TO_DELIVERY',
    'serviceOrder.badge.attentionToDelivery',
    'badge badge-danger noselect',
  )

  static NEGATIVE_OV = new ServiceOrderTagType(
    [],
    'NEGATIVE_OV',
    'serviceOrder.badge.negativeOV',
    'badge badge-danger noselect',
  )

  static PROVIDER_RESCHEDULE = new ServiceOrderTagType(
    [],
    'PROVIDER_RESCHEDULE',
    'serviceOrder.badge.providerReschedule',
    'badge badge-warning noselect',
  )

  static SALES_ERROR = new ServiceOrderTagType(
    [],
    'SALES_ERROR',
    'access.function141',
    'badge badge-danger noselect',
  )

  static WCF_SIGNED_WITH_RESERVATIONS = new ServiceOrderTagType(
    [],
    'WCF_SIGNED_WITH_RESERVATIONS',
    'serviceOrder.badge.wcfSignedWithReservations',
    'badge badge-warning noselect',
  )

  static WCF_EXPIRED = new ServiceOrderTagType(
    [],
    'WCF_EXPIRED',
    'serviceOrder.badge.wcfExpired',
    'badge badge-warning noselect',
  )

  static EXPIRED_CUSTOMER_CONTRACT = new ServiceOrderTagType(
      [],
      'EXPIRED_CUSTOMER_CONTRACT',
      'serviceOrder.badge.expiredCustomerContract',
      'badge badge-danger noselect',
  )

  serviceOrderStatusId = []

  tagName = ''

  translation = ''

  className = ''

  constructor(serviceOrderStatusId, tagName, translation, className) {
    this.serviceOrderStatusId = serviceOrderStatusId || []
    this.tagName = tagName || ''
    this.translation = translation || ''
    this.className = className || ''
  }

  static getStatusByTagName(name) {
    switch (name) {
      case ServiceOrderTagType.MANUAL_DISTRIBUTED.tagName:
        return ServiceOrderTagType.MANUAL_DISTRIBUTED

      case ServiceOrderTagType.ATTENTION.tagName:
        return ServiceOrderTagType.ATTENTION

      case ServiceOrderTagType.REJECTED.tagName:
        return ServiceOrderTagType.REJECTED

      case ServiceOrderTagType.ACCEPTANCE_SALES_CONDITIONS.tagName:
        return ServiceOrderTagType.ACCEPTANCE_SALES_CONDITIONS

      case ServiceOrderTagType.ID_CONFORMITY.tagName:
        return ServiceOrderTagType.ID_CONFORMITY

      case ServiceOrderTagType.EXPIRED.tagName:
        return ServiceOrderTagType.EXPIRED

      case ServiceOrderTagType.WAITING_CONFIRMATION.tagName:
        return ServiceOrderTagType.WAITING_CONFIRMATION

      case ServiceOrderTagType.LATE_ORDER.tagName:
        return ServiceOrderTagType.LATE_ORDER

      case ServiceOrderTagType.REWORK.tagName:
        return ServiceOrderTagType.REWORK

      case ServiceOrderTagType.WARNING_OUT_OF_FLOW_PROCESS.tagName:
        return ServiceOrderTagType.WARNING_OUT_OF_FLOW_PROCESS

      case ServiceOrderTagType.APPROVED_OUT_OF_FLOW_PROCESS.tagName:
        return ServiceOrderTagType.APPROVED_OUT_OF_FLOW_PROCESS

      case ServiceOrderTagType.REFUSED_OUT_OF_FLOW_PROCESS.tagName:
        return ServiceOrderTagType.REFUSED_OUT_OF_FLOW_PROCESS

      case ServiceOrderTagType.QUOTATION_APPROVED.tagName:
        return ServiceOrderTagType.QUOTATION_APPROVED

      case ServiceOrderTagType.QUOTATION_REFUSED.tagName:
        return ServiceOrderTagType.QUOTATION_REFUSED

      case ServiceOrderTagType.NFE_SENT.tagName:
        return ServiceOrderTagType.NFE_SENT

      case ServiceOrderTagType.NFE_AWAITING_SHIPMENT.tagName:
        return ServiceOrderTagType.NFE_AWAITING_SHIPMENT

      case ServiceOrderTagType.NFE_LATE.tagName:
        return ServiceOrderTagType.NFE_LATE

      case ServiceOrderTagType.AWAITING_MEASUREMENT_SHEET.tagName:
        return ServiceOrderTagType.AWAITING_MEASUREMENT_SHEET

      case ServiceOrderTagType.VALIDATE_MEASUREMENT_SHEET.tagName:
        return ServiceOrderTagType.VALIDATE_MEASUREMENT_SHEET

      case ServiceOrderTagType.EMERGENCY_SERVICE.tagName:
        return ServiceOrderTagType.EMERGENCY_SERVICE

      case ServiceOrderTagType.PARTIALLY_DONE.tagName:
        return ServiceOrderTagType.PARTIALLY_DONE

      case ServiceOrderTagType.RESCHEDULED_BY_CLIENT.tagName:
        return ServiceOrderTagType.RESCHEDULED_BY_CLIENT

      case ServiceOrderTagType.TECHNICAL_REPORT.tagName:
        return ServiceOrderTagType.TECHNICAL_REPORT

      case ServiceOrderTagType.TECHNICAL_REPORT_SENT.tagName:
        return ServiceOrderTagType.TECHNICAL_REPORT_SENT

      case ServiceOrderTagType.WAITING_RESPONSIBLE.tagName:
        return ServiceOrderTagType.WAITING_RESPONSIBLE

      case ServiceOrderTagType.WAITING_BUDGET.tagName:
        return ServiceOrderTagType.WAITING_BUDGET

      case ServiceOrderTagType.RECOMMEND_INSPECTION.tagName:
        return ServiceOrderTagType.RECOMMEND_INSPECTION

      case ServiceOrderTagType.ATTETION_TO_DELIVERY.tagName:
        return ServiceOrderTagType.ATTETION_TO_DELIVERY

      case ServiceOrderTagType.NEGATIVE_OV.tagName:
        return ServiceOrderTagType.NEGATIVE_OV

      case ServiceOrderTagType.PROVIDER_RESCHEDULE.tagName:
        return ServiceOrderTagType.PROVIDER_RESCHEDULE

      case ServiceOrderTagType.SALES_ERROR.tagName:
        return ServiceOrderTagType.SALES_ERROR

      case ServiceOrderTagType.WCF_SIGNED_WITH_RESERVATIONS.tagName:
        return ServiceOrderTagType.WCF_SIGNED_WITH_RESERVATIONS

      case ServiceOrderTagType.WCF_EXPIRED.tagName:
        return ServiceOrderTagType.WCF_EXPIRED

      case ServiceOrderTagType.EXPIRED_CUSTOMER_CONTRACT.tagName:
        return ServiceOrderTagType.EXPIRED_CUSTOMER_CONTRACT

      default:
        return new ServiceOrderTagType()
    }
  }
}

export default ServiceOrderTagType
