import i18next from 'i18next'
import API_URL from '../core/Envs/endpoints'
import API from '../core/Http/FetchAdapter'

import User from '../core/User'
import store from '../redux/store'

import { getPages } from '../constants/pagination'
import { renderToastError, renderToastSuccessMsg } from '../helpers/ToastUtils'
import { hideSpinner, showSpinner } from '../redux/actions/spinner'

const user = new User().currentUser

export const fetchProviderRecords = async (token, params, page = 1, size = 10) => {
  store.dispatch(showSpinner())

  try {
    const { data, metadata } = await new API(token, store.dispatch, { page, size }).get(
      API_URL.FETCH_SERVC_PRVDR_TMLINE_EVNT,
      params,
    )
    store.dispatch(getPages(metadata))

    return data
  } catch (error) {
    Promise.reject(error)
  } finally {
    store.dispatch(hideSpinner())
  }
}

export const saveNewProviderFiles = async (i, token, values, providerID) => {
  const files = {
    ...values.filesToAdd.files[i],
  }

  const fields = {
    servcPrvdrId: providerID,
    atchmtDocmntTyp: files.type,
    descType: '',
    files: [
      {
        name: files.descAnexo,
        file: files.src && files.src.substr(files.src.indexOf(',') + 1),
      },
    ],
    requestOriginPortal: 'SERVICES',
  }

  try {
    const { data } = await new API(token, store.dispatch).post(
      API_URL.SERVICE_PROVIDER_FILES,
      fields,
    )

    const nextFile = i + i
    if (nextFile < values.filesToAdd.files.length)
      return saveNewProviderFiles(nextFile, token, values, providerID)

    return data
  } catch (error) {
    return Promise.reject(error)
  } finally {
    store.dispatch(hideSpinner())
  }
}

export const updateProvider = async (
  token,
  values,
  { redirect = '/providers', toast = true, spinner = true },
) => {
  if (spinner) store.dispatch(showSpinner())

  const fields = {
    ...values,
    requestOriginPortal: 'SERVICES',
  }

  try {
    const { data, message } = await new API(token, store.dispatch).post(
      API_URL.SERVICE_PROVIDER_SAVE,
      fields,
    )

    if (fields.filesToAdd && fields.filesToAdd.files && fields.filesToAdd.files.length > 0) {
      saveNewProviderFiles(0, token, fields, data)
    }

    if (redirect) history.push(redirect)
    if (toast) renderToastSuccessMsg(message?.dsMessage || i18next.t('company.info.changes.done'))

    return data
  } catch (error) {
    renderToastError(error)

    return Promise.reject(error)
  } finally {
    if (spinner) store.dispatch(hideSpinner())
  }
}

export const saveServiceProviderFiles = async (token, values) => {
  store.dispatch(showSpinner())

  const fields = {
    ...values,
    requestOriginPortal: 'SERVICES',
  }

  try {
    const { data } = await new API(token, store.dispatch).post(
      API_URL.SERVICE_PROVIDER_FILES,
      fields,
    )

    return data
  } catch (error) {
    return Promise.reject(error)
  } finally {
    store.dispatch(hideSpinner())
  }
}

export const removeProviderFile = async (token, servcPrvdrId, servcPrvdrAtchmtId) => {
  store.dispatch(showSpinner())

  const query = {
    servcPrvdrId,
    servcPrvdrAtchmtId,
    requestOriginPortal: 'SERVICES',
  }

  try {
    const { data } = await new API(token, store.dispatch).put(API_URL.SERVICE_PROVIDER_FILES, query)

    return data
  } catch (error) {
    return Promise.reject(error)
  } finally {
    store.dispatch(hideSpinner())
  }
}
