import localforage from 'localforage'
import API_URL from '../../../core/Envs/endpoints'
import User from '../../../core/User'

import { LOGOUT_COMPLETE } from '../../actionTypes/auth'

const user = new User()

const redirectAfter = (pingoneEnable) => {
  const pathname = '/login'

  if (pingoneEnable) {
    window.location.href = `${API_URL.LOGOUT_PINGONE}?returnTo=${window.location.origin + pathname}`
  } else {
    window.location.href = pathname
  }

  setTimeout(() => {
    user.loggingOut = false
  }, 100)
}

export const logoutUser = async (pingoneEnable, reset = false) => {
  localStorage.setItem('last_user_logged', user.currentUser?.username)

  user.clearCache()

  if (reset) {
    localStorage.removeItem('lastRouteAccessed')
    localforage.removeItem('advanced_search_filters')
  }

  document.cookie.split(';').forEach((c) => {
    document.cookie = c
      .replace(/^ +/, '')
      .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`)
  })

  redirectAfter(pingoneEnable)

  return { type: LOGOUT_COMPLETE }
}
