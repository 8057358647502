var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import React from 'react';
import { Radio } from '../Radio';
import { useId } from '../../hooks/use-id';
/**
 * A group of Input Radio.
 */
var RadioGroup = React.forwardRef(function (props, ref) {
    var customName = props.name, currentValue = props.value, label = props.label, options = props.options, onChange = props.onChange, others = __rest(props, ["name", "value", "label", "options", "onChange"]);
    var id = useId(customName, 'radio-group');
    var hasOptions = options && options.length > 0;
    return (_jsx("div", __assign({ role: "group", "aria-label": label, ref: ref }, others, { children: hasOptions &&
            options.map(function (_a, index) {
                var value = _a.value, children = _a.children, rest = __rest(_a, ["value", "children"]);
                return (_jsx(Radio, __assign({ name: id, value: value, onChange: onChange, isChecked: value === currentValue }, rest, { children: children }), index));
            }) }), void 0));
});
RadioGroup.displayName = 'RadioGroup';
/**
 * @component
 */
export default RadioGroup;
