import { useState } from 'react'
import {
  deleteServiceSpecialist,
  fetchInstallersAutoComplete,
  fetchInstallersListPage,
  fetchInstallersList,
  sendSmsAppSpecialist,
} from '../../../services/InstallerList'

import { InstallerListFilter } from '../models'

const DEFAULT_FILTER = {
  orderBy: 'se.servcPrvdrAgntId',
  descAsc: 'asc',
  idPrestadorServico: 0,
  dynamicSearch: '',
  nomeEspecialistaServico: '',
  indEspecialistaServicoAtivo: '1',
  receivingServiceOrder: '1',
  lsPlntCd: [],
  hasFilters: true,
  servcPrvdrAgntId: '0',
  actvServcPrvdrAgntInd: '1',
  servcPrvdrAgntNm: '',
} as InstallerListFilter

function InstallerListHandlers() {
  const [installersAutoComplete, setInstallersAutoComplete] = useState([])
  const [installersListPage, setInstallersListPage] = useState([])
  const [installersList, setInstallersList] = useState([])
  const [filter, setFilter] = useState<InstallerListFilter | null>(DEFAULT_FILTER)
  const [currentPage, setCurrentPage] = useState(1)
  const [mail, setMail] = useState({
    record: {},
    error: null,
  })
  const [sms, setSms] = useState({
    record: {},
    error: null,
  })

  const fetchServiceSpecialistPageList = async (
    token = null,
    filter: InstallerListFilter,
    page = 1,
  ) => {
    const data = await fetchInstallersListPage(token, filter, page)

    setFilter(filter)
    setCurrentPage(page)
    setInstallersListPage(data || [])
  }

  const fetchServiceSpecialistList = async (token = null, filter: InstallerListFilter) => {
    const data = await fetchInstallersList(token, filter)

    setInstallersList(data)
  }

  const fetchServiceSpecialistAutocomplete = async (token = null, filter: InstallerListFilter) => {
    const data = await fetchInstallersAutoComplete(token, filter)

    setInstallersAutoComplete(data)
  }

  const handleSendSms = async (token: any, values: any) => {
    const newSms = await sendSmsAppSpecialist(token, values)

    setSms({ ...sms, record: newSms })
  }

  const handleClearInstallersList = () => {
    setInstallersAutoComplete([])
    setInstallersListPage([])
    setInstallersList([])
    setFilter(DEFAULT_FILTER)
    setCurrentPage(1)
    setMail({
      record: {},
      error: null,
    })
    setSms({
      record: {},
      error: null,
    })
  }

  return {
    sms,
    mail,
    filter,
    currentPage,
    installersList,
    installersListPage,
    installersAutoComplete,
    sendSmsAppSpecialist: handleSendSms,
    deleteServiceSpecialist,
    fetchServiceSpecialistList,
    fetchServiceSpecialistAutocomplete,
    fetchServiceSpecialistPageList,
    handleClearInstallersList,
  }
}

export default InstallerListHandlers
