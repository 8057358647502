import { TextField } from '../../../../../../../../../../../../leroy-merlin-br/backyard-react'
import classNames from 'classnames'
import i18next from 'i18next'
import { FormEvent, useEffect, useState } from 'react'
import { Address } from '../..'
import Modal from '../../../../../../../../../../../components/Modal'
import MozaicButton from '../../../../../../../../../../../components/MozaicButton'
import store from '../../../../../../../../../../../redux/store'
import {
  PostalCodeInfo,
  getPostalCodeInfo,
} from '../../../../../../../../../../../services/pages/ServiceOrderDetail/GetPostalCodeInfo'
import {
  UpdateAddressParams,
  updateAddress,
} from '../../../../../../../../../../../services/pages/ServiceOrderDetail/UpdateAdrress'
import { useServiceOrderDetailContext } from '../../../../../../../../../context'

interface AddressBoxModalProps {
  address: Address
  isOpen: boolean
  onClose: (newAddress?: Address) => void
}

const defaultCEPInfo = {
  IBGEcode: '-',
  city: '-',
  neighborhood: '-',
  postalCode: '-',
  state: '-',
  streetName: '-',
  supplement: '-',
}

function AddressBoxModal({ address, isOpen, onClose }: AddressBoxModalProps) {
  const { servcOrdCd, fetchClient } = useServiceOrderDetailContext()
  const setupParameters = store.getState().auth.setupParameters
  const enableSearchCEPApi =
    setupParameters.enableSearchCEPApi ||
    setupParameters.enableSearchCEPApi === null ||
    setupParameters.enableSearchCEPApi === undefined
  const initialPostalCode = address?.postalCode?.replace(/^(\d{5})(\d{3})+$/, '$1-$2') || ''

  const [postalCode, setPostalCode] = useState(initialPostalCode)
  const [streetName, setStreetName] = useState(address.streetName || '')
  const [houseNumber, setHouseNumber] = useState(address.houseNumber || '')
  const [complement, setComplement] = useState(address.complementText || '')
  const [referencePoint, setReferencePoint] = useState(address.referencePoint || '')
  const [districtName, setDistrictName] = useState(address.districtName || '')
  const [cityName, setCityName] = useState(address.cityName || '')
  const [stateCode, setStateCode] = useState(address.stateCode || '')
  const [isSubmiting, setIsSubmiting] = useState<boolean>(false)
  const [postalCodeInfo, setPostalCodeInfo] = useState<PostalCodeInfo | null>(defaultCEPInfo)
  const [validCEP, setvalidCEP] = useState<boolean>(true)

  const verifyFields = () => {
    const addressInfo = {
      IBGEcode: '-',
      city: address.cityName || '',
      neighborhood: address.districtName || '',
      postalCode: address?.postalCode || '',
      state: address.stateCode || '',
      streetName: address.streetName || '',
      supplement: address.referencePoint || '',
    }
    setPostalCodeInfo(addressInfo)
  }

  const verifyPostalCode = async (postalCode: string) => {
    const response = await getPostalCodeInfo(postalCode)

    if (!response) setvalidCEP(false)
    if (response) setvalidCEP(true)

    return response
  }

  useEffect(() => {
    if (enableSearchCEPApi) {
      verifyPostalCode(postalCode)
      verifyFields()
    }
  }, [])

  const canSubmit = () => {
    if (enableSearchCEPApi) {
      const hasChanges =
        houseNumber !== address.houseNumber ||
        stateCode !== address.stateCode ||
        cityName !== address.cityName ||
        districtName !== address.districtName ||
        referencePoint !== address.referencePoint ||
        complement !== address.complementText ||
        streetName !== address.streetName

      const hasValues =
        houseNumber?.replace(/\s/g, '') &&
        stateCode?.replace(/\s/g, '') &&
        cityName?.replace(/\s/g, '') &&
        districtName?.replace(/\s/g, '') &&
        streetName?.replace(/\s/g, '')

      return validCEP && hasChanges && hasValues
    }

    const hasChanges =
      postalCode !== address.postalCode ||
      streetName !== address.streetName ||
      cityName !== address.cityName

    const hasValues =
      postalCode?.replace(/\s/g, '') &&
      streetName?.replace(/\s/g, '') &&
      cityName?.replace(/\s/g, '')

    return hasChanges && hasValues
  }

  const clearFields = () => {
    setPostalCodeInfo(defaultCEPInfo)
    setStreetName('')
    setDistrictName('')
    setCityName('')
    setStateCode('')
  }

  const getPostalCodeData = async (postalCode: string) => {
    const response = await verifyPostalCode(postalCode)

    if (!response) clearFields()
    if (response?.dne && response.dne[0]) {
      const CEPData = response.dne[0]

      setPostalCodeInfo(CEPData)
      setStreetName(CEPData.streetName || '')
      setDistrictName(CEPData.neighborhood || '')
      setCityName(CEPData.city || '')
      setStateCode(CEPData.state || '')
    }
  }

  const handleChangePostalCode = (event: FormEvent<HTMLInputElement>) => {
    const newRawValue = (event.target as HTMLInputElement).value?.replace(/\D/g, '')
    if (enableSearchCEPApi) {
      const newValue = newRawValue.replace(/^(\d{5})(\d{3})+$/, '$1-$2')

      setvalidCEP(false)
      setPostalCode(newValue)

      if (newRawValue.length >= 8) getPostalCodeData(newRawValue)
      if (newRawValue.length === 0) clearFields()
    } else {
      setPostalCode(newRawValue)
    }
  }

  const handleSubmit = async () => {
    setIsSubmiting(true)

    const params: UpdateAddressParams = {
      servcAddrPstlCd: postalCode,
      servcAddrStrNm: streetName,
      servcAddrNr: houseNumber,
      servcAddrCmplmtryTxt: complement,
      servcAddrRefPointDesc: referencePoint,
      servcAddrDstrctNm: districtName,
      servcAddrCityNm: cityName,
      servcAddrStCd: stateCode,
    }

    await updateAddress(params, servcOrdCd)

    onClose({
      postalCode: params.servcAddrPstlCd,
      streetName: params.servcAddrStrNm,
      houseNumber: params.servcAddrNr,
      complementText: params.servcAddrCmplmtryTxt,
      referencePoint: params.servcAddrRefPointDesc,
      districtName: params.servcAddrDstrctNm,
      cityName: params.servcAddrCityNm,
      stateCode: params.servcAddrStCd,
    })

    setIsSubmiting(false)

    await fetchClient()
  }

  return (
    <Modal
      size='lg'
      isOpen={isOpen}
      onClose={onClose}
      contentLabel='serviceOrder.address.editModal.serviceAddress'
      closeInOverlay={false}
      className='address-modal-over'
    >
      <div id='address-box-modal'>
        <div className='row'>
          <div className='col-12 fill-cep'>
            <span>{i18next.t('serviceOrder.address.editModal.enterCep')}</span>
          </div>
        </div>

        <div className='row'>
          <div className='col-12'>
            <TextField
              className={classNames('address-modal-text-field', {
                'has-value': !!postalCode,
                'is-invalid': !validCEP,
              })}
              value={postalCode}
              hint={!enableSearchCEPApi || validCEP ? undefined : 'CEP inválido'}
              label={i18next.t('serviceOrder.address.editModal.postalCode')}
              maxLength={9}
              onChange={(event) => handleChangePostalCode(event)}
            />
          </div>
        </div>

        <div className='row'>
          <div className='col-12'>
            <TextField
              className={classNames('address-modal-text-field', { 'has-value': !!streetName })}
              value={streetName}
              maxLength={80}
              label={i18next.t('serviceOrder.address.editModal.streetNumber')}
              onChange={(event) => setStreetName((event.target as HTMLInputElement).value)}
              disabled={enableSearchCEPApi && !!postalCodeInfo?.streetName}
            />
          </div>
        </div>

        <div className='row'>
          <div className='col-4'>
            <TextField
              className={classNames('address-modal-text-field', { 'has-value': !!houseNumber })}
              value={houseNumber}
              maxLength={10}
              label={i18next.t('serviceOrder.address.editModal.houseNumber')}
              onChange={(event) => setHouseNumber((event.target as HTMLInputElement).value)}
            />
          </div>
          <div className='col-8'>
            <TextField
              className={classNames('address-modal-text-field', { 'has-value': !!complement })}
              value={complement}
              maxLength={80}
              label={i18next.t('serviceOrder.address.editModal.complement')}
              onChange={(event) => setComplement((event.target as HTMLInputElement).value)}
            />
          </div>
        </div>

        <div className='row'>
          <div className='col-12'>
            <TextField
              className={classNames('address-modal-text-field', { 'has-value': !!referencePoint })}
              value={referencePoint}
              maxLength={80}
              label={i18next.t('serviceOrder.address.editModal.referencePoint')}
              onChange={(event) => setReferencePoint((event.target as HTMLInputElement).value)}
            />
          </div>
        </div>

        <div className='row'>
          <div className='col-12'>
            <TextField
              className={classNames('address-modal-text-field', { 'has-value': !!districtName })}
              value={districtName}
              maxLength={80}
              label={i18next.t('serviceOrder.address.editModal.district')}
              onChange={(event) => setDistrictName((event.target as HTMLInputElement).value)}
              disabled={enableSearchCEPApi && !!postalCodeInfo?.neighborhood}
            />
          </div>
        </div>

        <div className='row'>
          <div className='col-6'>
            <TextField
              className={classNames('address-modal-text-field', { 'has-value': !!cityName })}
              value={cityName}
              label={i18next.t('serviceOrder.address.editModal.city')}
              onChange={(event) => setCityName((event.target as HTMLInputElement).value)}
              disabled={enableSearchCEPApi && !!postalCodeInfo?.city}
            />
          </div>
          <div className='col-6'>
            <TextField
              className={classNames('address-modal-text-field', { 'has-value': !!stateCode })}
              value={stateCode}
              label={i18next.t('serviceOrder.address.editModal.state')}
              onChange={(event) => setStateCode((event.target as HTMLInputElement).value)}
              disabled={enableSearchCEPApi && !!postalCodeInfo?.state}
            />
          </div>
        </div>

        <div className='footer-buttons'>
          <MozaicButton variant='ghost' theme='primary-02' onClick={() => onClose()}>
            {i18next.t('serviceOrder.address.editModal.cancel')}
          </MozaicButton>
          <MozaicButton onClick={() => handleSubmit()} isDisabled={!canSubmit() || isSubmiting}>
            {i18next.t('serviceOrder.address.editModal.save')}
          </MozaicButton>
        </div>
      </div>
    </Modal>
  )
}

export default AddressBoxModal
