import store from '../redux/store'
import User from './User'

import { Provider } from 'react-redux'
import { ThemeProvider } from '@emotion/react'
import { light } from '../../leroy-merlin-br/backyard-theme'

import { ToastContainer } from 'react-toastify'
import { ModalsProvider, NotificationsProvider, NotificationsRenderer } from '@mozaic-ds/react'
import { ToastContainer as BackyardToastContainer } from '../../leroy-merlin-br/backyard-react'
import { ClearBrowserCacheBoundary } from 'react-clear-browser-cache'
import { setupParameters } from '../redux/actions/auth/fetchSetupParameters'
import { LOGIN_SUCCESS, USER_MENU_SUCCESS } from '../redux/actionTypes/auth'

import I18nWrapper from '../configs/i18nWrapper'
import Application from './App'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const user = new User()
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: 1000 * 60 * 60,
      cacheTime: 1000 * 60 * 60,
    },
  },
})

setupParameters(store.dispatch).then(() => {
  if (user.menu) store.dispatch({ type: USER_MENU_SUCCESS, payload: user.menu })
})

if (user.currentUser) {
  store.dispatch({ type: LOGIN_SUCCESS, payload: user.currentUser })
}

export default function Main() {
  return (
    <ClearBrowserCacheBoundary auto fallback='' duration={60000}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={light}>
          <Provider store={store}>
            <I18nWrapper>
              <ModalsProvider>
                <NotificationsProvider>
                  <NotificationsRenderer position='top' />
                  <Application />
                </NotificationsProvider>
              </ModalsProvider>
              <ToastContainer newestOnTop style={{ zIndex: 99999999999 }} />
              <BackyardToastContainer zIndex={99999} />
            </I18nWrapper>
          </Provider>
        </ThemeProvider>
      </QueryClientProvider>
    </ClearBrowserCacheBoundary>
  )
}
