import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { SingleDatePicker } from 'react-dates'

import Col from '../../../components/utils/Col'
import FeedbackError from '../../../components/utils/FeedbackError'
import Field from '../../../components/utils/Field'
import Grid from '../../../components/utils/Grid'
import Row from '../../../components/utils/Row'
import Spinner from '../../../components/utils/Spinner'
import TitleSection from '../../../components/utils/TitleSection'

import API_URL from '../../../core/Envs/endpoints'
import Api from '../../../core/Http/FetchAdapter'
import BranchSelect from './BranchSelectMultiple'
import DistributedService from './DistributedService'
import RescheduleService from './RescheduleService'
import ServiceProviderAdvancedSearch from './ServiceProviderAdvancedSearch'
import ServiceProviderSelect from './ServiceProviderSelect'
import ServiceSpecialistSelect from './ServiceSpecialistSelect'
import SlotSelect from './SlotSelect'

import { FORMAT_DATE_TRUNC } from '../../../constants/config'
import ServiceOrderStatusType from '../../../enums/ServiceOrder/status/ServiceOrderStatusType'

class EditForm extends Component {
  initialFields = () => ({
    servcPrvdrNm: '',
    servcPrvdrTrdNm: '',
    servcPrvdrFedrlTaxIdNr: '',
    slsChnlTyp: '',
  })

  state = {
    dateSelected: {},
    slots: [],
    // eslint-disable-next-line react/destructuring-assignment
    dateControl: this.props.initialValues.date
      ? moment(
          // eslint-disable-next-line react/destructuring-assignment
          `${this.props.initialValues.date.format('YYYY')}-${this.props.initialValues.date.format(
            'MM',
          )}-01`,
        )
      : moment(new Date()),
    // eslint-disable-next-line react/destructuring-assignment
    plntServcCalndrCd: this.props.initialValues.plntServcCalndrCd || null,
    // eslint-disable-next-line react/destructuring-assignment
    idSlot: this.props.initialValues.idSlot || null,
    // eslint-disable-next-line react/destructuring-assignment
    idSlotApplication: this.props.initialValues.idSlotApplication || null,
    // eslint-disable-next-line react/destructuring-assignment
    dsSlot: this.props.initialValues.dsSlot || null,
    allowedDates: [],
    calendarDates: [],
    isFetchingDates: false,
    visibleSpinnerDate: false,
    filters: this.initialFields(),
    advancedSearch: false,
  }

  componentDidMount() {
    const { handleChangeStatus, serviceOrder, setShift } = this.props

    this.handleSetInitialFields()

    handleChangeStatus(serviceOrder.servcOrdStusCd)
    setShift(serviceOrder.idSlotApplication)
    this.loadDates()
  }

  handleSetInitialFields() {
    const { setFormValue, getServiceSpecialists, serviceOrder, initialValues, t } = this.props
    const {
      servcPrvdrNm,
      servcPrvdrTrdNm,
      servcPrvdrCd,
      servcOrdSchdlgTs,
      plntCd,
      servcPrvdrAgntCd,
      servcPrvdrAgntNm,
    } = serviceOrder

    const payload = {}

    if (servcPrvdrCd) {
      this.handleServiceProviderAutocomplete(servcPrvdrTrdNm)
      payload.servcPrvdrTrdNm = servcPrvdrTrdNm
      payload.servcPrvdrNm = servcPrvdrNm
      payload.servcPrvdrCd = servcPrvdrCd

      getServiceSpecialists(servcPrvdrCd, servcOrdSchdlgTs)
    }

    if (servcPrvdrAgntCd) {
      payload.servcPrvdrAgntCd = servcPrvdrAgntCd
      payload.servcPrvdrAgntNm = servcPrvdrAgntNm
    }

    payload.idSlotApplication = initialValues.idSlotApplication
    payload.servcPrvdrPlntCd = {
      value: plntCd,
      label: t('serviceOrder.currentServcPrvdrPlntCd'),
    }

    setFormValue(payload)
  }

  componentDidUpdate(prevProps) {
    const { serviceOrder, values, getServiceSpecialists } = this.props

    if (serviceOrder.servcPrvdrAgntCd !== prevProps.serviceOrder.servcPrvdrAgntCd) {
      this.handleClearServiceSpecialists()

      if (serviceOrder.servcPrvdrCd) {
        getServiceSpecialists(values.servcPrvdrCd, values.servcOrdSchdlgTs)
      }
    }

    this.handleForceUpdateStatusCombo()
  }

  handleForceUpdateStatusCombo() {
    const { currentStatus } = this.props
    // RESPOSTAS --> força a atualização do combo de status
    this.updateStatusCombo('02', '01', '06')
    this.updateStatusCombo('10', '10', '06')
    this.updateStatusCombo('08', '08', '06')
    if (currentStatus !== '01') {
      this.updateStatusCombo('02', '02', '01')
    }
  }

  possibleStatus = (serviceOrderStatuses = []) =>
    serviceOrderStatuses.map((serviceOrderStatus) => serviceOrderStatus.id)

  /**
   * Força a atualização do combo de status
   * @param currentStatus -- depois do submit
   * @param updateStatus -- a ser atualizado conforme regras
   * @param confirmateStatus -- ultimo status antes do submit
   */
  updateStatusCombo = (currentStatus, updateStatus, confirmateStatus) => {
    const { serviceOrder, setFormValue, handleChangeStatus, fields, serviceOrderStatuses } =
      this.props
    const { servcOrdStusCd } = serviceOrder

    const statuses = this.possibleStatus(serviceOrderStatuses)

    // RESPOSTAS --> força a atualização do combo de status
    if (
      statuses.includes(servcOrdStusCd) &&
      servcOrdStusCd === currentStatus &&
      fields.servcOrdStusCd.input.value === confirmateStatus &&
      fields.servcOrdStusCd.input.value !== servcOrdStusCd
    ) {
      setFormValue({ servcOrdStusCd: updateStatus })
      handleChangeStatus(updateStatus)
    }
  }

  handleOpenServiceProviderAdvancedSearch = () => {
    this.setState({ advancedSearch: true })
  }

  handleCloseServiceProviderAdvancedSearch = () => {
    this.setState({ advancedSearch: false })
  }

  handleInputChange = (e, field) => {
    const filter = field || e.target.name
    const value = field ? e.value : e.target.value

    this.setState((state) => ({
      filters: {
        ...state.filters,
        [filter]: value,
      },
    }))
  }

  handleSubmit = (e) => {
    e.preventDefault()

    const { getServiceProviders } = this.props

    const { filters } = this.state

    getServiceProviders(filters)
  }

  clearFields = () => {
    this.setState({ filters: this.initialFields() })
  }

  handleChangeDate = (event, onStart = false, arrayDates = []) => {
    const {
      fields,
      values,
      setFormValue,
      getServiceSpecialists,
      setDate,
      getServiceProviders,
      initialValues,
      setShift,
    } = this.props
    const { calendarDates, filters } = this.state

    const dateFormated = event.format('YYYY-MM-DD')
    const arrDates = arrayDates.length > 0 ? arrayDates : calendarDates
    const dateSelected =
      (arrDates || []).find((item) => {
        if (item.date === dateFormated) {
          fields.date.input.value = item.date
          fields.date.error = null
          return item
        }
      }) || {}

    if (!onStart) {
      fields.servcPrvdrCd.input.value = null
      values.servcPrvdrCd = null

      fields.servcPrvdrAgntCd.input.value = null
      values.servcPrvdrAgntCd = null
    }

    if (arrDates.length === 0 && initialValues.date) {
      fields.date.input.value = initialValues.date

      fields.idSlot.input.value = initialValues.idSlot
      fields.idSlotApplication.input.value = initialValues.idSlotApplication
      fields.dsSlot.input.value = initialValues.dsSlot
      setDate(event)
      setShift(initialValues.idSlotApplication)
      this.onFetchSlot([
        {
          idSlotApplication: initialValues.idSlotApplication,
          idSlot: initialValues.idSlot,
          dsSlot: initialValues.dsSlot,
        },
      ])
      values.idSlot = initialValues.idSlot
      values.idSlotApplication = initialValues.idSlotApplication
      values.dsSlot = initialValues.dsSLot
      values.data = event.format('DD-MM-YYYY')
    } else {
      this.onFetchSlot(dateSelected.slots)
      setDate(event)
      setShift(initialValues.idSlotApplication)

      fields.date.input.value = dateFormated
      values.date = event.format('DD-MM-YYYY')

      if (initialValues.date && initialValues.date.format('YYYY-MM-DD') === dateSelected.date) {
        let indPossuiSlot = 0
        dateSelected.slots.map((slot) => {
          if (initialValues.idSlotApplication === slot.idSlotApplication) {
            indPossuiSlot = 1
          }
        })
        if (indPossuiSlot === 0) {
          dateSelected.slots.push({
            idSlotApplication: initialValues.idSlotApplication,
            idSlot: initialValues.idSlot,
            dsSlot: initialValues.dsSlot,
          })
        }
      }

      getServiceProviders(filters)
    }

    const payload = {}
    const selectedDate = event.format('YYYY-MM-DDThh:mm:ss')
    const initialDate = initialValues.date ? initialValues.date.format('YYYY-MM-DDThh:mm:ss') : null

    payload.date = event
    payload.servcOrdSchdlgTs = selectedDate
    payload.idSlotApplication = selectedDate !== initialDate ? '' : initialValues.idSlotApplication

    setFormValue(payload)
    getServiceSpecialists(values.servcPrvdrCd, selectedDate)
  }

  handleChangeShift = (e) => {
    const { getServiceProviders, setFormValue, fields, values, setShift } = this.props
    const { filters } = this.state

    setFormValue({ idSlotApplication: e.target.value })

    fields.servcPrvdrCd.input.value = null
    values.servcPrvdrCd = null

    fields.servcPrvdrAgntCd.input.value = null
    values.servcPrvdrAgntCd = null

    setShift(e.target.value)
    getServiceProviders(filters)
  }

  onFetchSlot = async (options) => {
    await this.setState({
      slots: options,
    })
  }

  loadCalendarDates = async (query) => {
    try {
      const response = await new Api().get(API_URL.GET_SCHEDULE_DATES, query)
      this.fetchDatesSuccess(response.data)
    } catch (error) {
      console.error(error)
      this.fetchDatesError()
    }
  }

  isDayBlocked = (day) => {
    const { allowedDates } = this.state
    const { initialValues } = this.props
    if (
      initialValues.date &&
      day.format('YYYY-MM-DD') === initialValues.date.format('YYYY-MM-DD')
    ) {
      return false
    }

    return !allowedDates.includes(day.format('YYYY-MM-DD'))
  }

  loadDates = async () => {
    const { dateControl, plntServcCalndrCd } = this.state
    const { setDate } = this.props
    const day = dateControl.format('DD')
    const month = dateControl.format('MM')
    const year = dateControl.format('YYYY')

    const query = {
      dtStart: `${day}/${month}/${year}`,
      idCalendarApplication: plntServcCalndrCd,
    }

    setDate(null)
    this.setState({ slots: [] })
    await this.loadCalendarDates(query)
    this.handleClearDateFields()
  }

  handleClearDateFields() {
    const { values, fields } = this.props

    fields.idSlot.input.value = null
    fields.idSlotApplication.input.value = null
    fields.dsSlot.input.value = null
    fields.date.input.value = null
    values.idSlotApplication = null
    values.idSlot = null
    values.dsSlot = null
    values.date = null
  }

  fetchDatesSuccess = async (data) => {
    const { initialValues } = this.props
    const allowedDates = data.map((date) => date.date)

    const { fields } = this.props
    let dateFormated = null

    if (initialValues.date) {
      dateFormated = initialValues.date.format('YYYY-MM-DD')
    }
    const dateSelected = allowedDates.find((item) => {
      if (item.date === dateFormated) {
        fields.date.input.value = item.date
        fields.date.error = null
        return item
      }
    })
    if (!dateSelected) {
      allowedDates.push(dateFormated)
      data.push({
        date: dateFormated,
        slots: [
          {
            idSlotApplication: initialValues.idSlotApplication,
            idSlot: initialValues.idSlot,
            dsSlot: initialValues.dsSlot,
          },
        ],
      })
    }
    this.setState({
      allowedDates,
      calendarDates: data,
      isFetchingDates: false,
      visibleSpinnerDate: false,
    })

    if (initialValues.date) {
      const auxDate = moment(dateFormated)
      this.handleChangeDate(auxDate, true, data)
    }
  }

  fetchDatesError = () => {
    this.setState({
      allowedDates: [],
      isFetchingDates: false,
      visibleSpinnerDate: false,
    })
  }

  handleServiceProviderChange = async (option) => {
    // eslint-disable-next-line no-param-reassign
    option = option || { value: null, lable: '', servcPrvdrTrdNm: '' }

    const { value, label, servcPrvdrTrdNm } = option
    const { setFormValue, getServiceSpecialists, values } = this.props

    if (typeof value === 'undefined' || value === null) {
      this.handleClearServiceProviders()
      this.handleServiceProviderAutocomplete('')
    }

    setFormValue({
      servcPrvdrCd: value,
      servcPrvdrNm: label,
      servcPrvdrTrdNm,
    })

    this.handleClearServiceSpecialists()

    await getServiceSpecialists(value, values.servcOrdSchdlgTs)
  }

  handleServiceSpecialistChange = (option) => {
    // eslint-disable-next-line no-param-reassign
    option = option || { value: null, label: '' }
    const { value, label } = option
    const { setFormValue } = this.props

    setFormValue({
      servcPrvdrAgntCd: value,
      servcPrvdrAgntNm: label,
    })
  }

  handleServiceProviderAutocomplete = (value) => {
    const { getServiceProviders } = this.props
    const { filters } = this.state

    const newFilters = filters
    newFilters.servcPrvdrTrdNm = value

    this.setState({ filters: newFilters })

    getServiceProviders(newFilters)
  }

  handleClearServiceProviders = () => {
    const { setFormValue } = this.props

    setFormValue({ servcPrvdrCd: '', servcPrvdrNm: '' })

    this.handleClearServiceSpecialists()
  }

  handleClearServiceSpecialists = () => {
    const { setFormValue } = this.props

    setFormValue({ servcPrvdrAgntCd: '', servcPrvdrAgntNm: '' })

    this.handleServiceSpecialistChange()
  }

  renderFieldsErrors = (fields, index) => (
    <li className='margin-left' key={index}>
      {fields.naField ? `${fields.naField}:` : ''} {fields.dsDetail}
    </li>
  )

  renderErrorOrUpdateNotification = () => {
    // show notification after a update
    const { t, serviceOrderEdit } = this.props

    let cls = 'warning'
    let content = null

    if (serviceOrderEdit.error) {
      if (serviceOrderEdit.error.fields) {
        content = serviceOrderEdit.error.fields.map(this.renderFieldsErrors)
      } else {
        content = serviceOrderEdit.error.message.dsMessage
      }
    } else if (Object.keys(serviceOrderEdit.record).length) {
      cls = 'primary'
      content = <span className='margin-left'>{t('serviceOrders.updated')}</span>
    }

    if (content) {
      return (
        <Col xs={12} className='margin-bottom-double'>
          <div className={`notification notification-${cls}`}>{content}</div>
        </Col>
      )
    }
  }

  isDeliveryDate = (day) => {
    const { serviceOrder } = this.props

    if (serviceOrder?.servcOrdPrmsdDlvryDt) {
      const deliveryDate = moment(serviceOrder.servcOrdPrmsdDlvryDt).startOf('day')
      const currentDate = day.startOf('day')
      const isDayHighlighted = currentDate.diff(deliveryDate, 'days') === 0

      return isDayHighlighted
    }
    return false
  }

  handleChangeBranchs(option) {
    const { setFormValue, serviceOrder, setServiceOrder } = this.props

    setFormValue({ servcPrvdrPlntCd: option })
    setServiceOrder({ ...serviceOrder, servcPrvdrPlntCd: option.value })

    this.handleClearServiceProviders()
  }

  render() {
    const {
      t,
      fields,
      serviceProviders,
      serviceSpecialists,
      showDistributeButton,
      showRescheduleButton,
      realStatus,
      getDate,
      auth: {
        user,
        setupParameters: { enableSelectBranchsOnServcOrder },
      },
      dispatch,
      serviceOrder,
      initialValues,
    } = this.props

    const { visibleSpinnerDate, advancedSearch, filters, focused, isFetchingDates, slots } =
      this.state

    const verifyStatus =
      serviceOrder.servcOrdStusCd === ServiceOrderStatusType.STATUS_PRE_AGENDADA.id ||
      serviceOrder.servcOrdStusCd === ServiceOrderStatusType.STATUS_AGENDADA.id
    const showBranchSelect = enableSelectBranchsOnServcOrder && verifyStatus

    const focusedDatePicker = focused && !isFetchingDates

    return (
      <Grid fluid>
        <TitleSection>
          <h1>{t('serviceOrders.services')}</h1>
        </TitleSection>

        {showBranchSelect && (
          <Row className='padding'>
            <Col lg={2.4} md={6} xs={6}>
              <Field className='no-margin' {...fields.servcPrvdrPlntCd} validate>
                <BranchSelect
                  {...fields.servcPrvdrPlntCd.input}
                  name='servcPrvdrPlntCd'
                  id='servcPrvdrPlntCd'
                  className='filter-select'
                  onChange={(option) => this.handleChangeBranchs(option)}
                  label={t('serviceOrder.servcPrvdrPlntCd.label')}
                  placeholder={t('serviceOrder.servcPrvdrPlntCd.label')}
                  isClearable={false}
                  user={user}
                  t={t}
                  dispatch={dispatch}
                  notMultiple
                  showAllOption
                  onlyBranchs
                  optionsData={{
                    value: serviceOrder.plntCd,
                    label: t('serviceOrder.currentServcPrvdrPlntCd'),
                  }}
                  closeMenuOnSelect
                />
              </Field>
            </Col>
          </Row>
        )}

        <Row className='padding'>
          {this.renderErrorOrUpdateNotification()}

          <Col lg={2.4} md={6} xs={6}>
            <Field
              className='no-margin'
              style={{ paddingTop: '20px' }}
              {...fields.servcPrvdrNm}
              validate
            >
              <ServiceProviderSelect
                t={t}
                value={initialValues.servcPrvdrCd}
                placeholder={t('serviceOrders.provider.select.placeholder')}
                id='servcPrvdrNm'
                onChange={this.handleServiceProviderChange}
                label={t('serviceOrders.servcPrvdrNm')}
                serviceProviders={serviceProviders}
                onInputChange={this.handleServiceProviderAutocomplete}
                handleOpenServiceProviderAdvancedSearch={
                  this.handleOpenServiceProviderAdvancedSearch
                }
                disabled
              />

              {advancedSearch && (
                <ServiceProviderAdvancedSearch
                  t={t}
                  id='nomePrestadorServicoAvancado'
                  placeholder={t('serviceOrders.selectPrestadorServico')}
                  value={initialValues.servcPrvdrCd}
                  label={t('serviceOrders.selectPrestadorServico')}
                  filters={filters}
                  handleInputChange={this.handleInputChange}
                  handleCloseServiceProviderAdvancedSearch={
                    this.handleCloseServiceProviderAdvancedSearch
                  }
                  handleSubmit={this.handleSubmit}
                  clearFields={this.clearFields}
                  serviceProviders={serviceProviders}
                  onChange={this.handleServiceProviderChange}
                />
              )}
            </Field>
          </Col>

          <Col lg={2.4} md={6} xs={6}>
            <Field className='no-margin' {...fields.servcPrvdrAgntNm} validate>
              <ServiceSpecialistSelect
                t={t}
                id='servcPrvdrAgntCd'
                value={initialValues.servcPrvdrAgntCd}
                placeholder={t('serviceOrders.specialist.select.placeholder')}
                label={t('serviceOrders.nomeEspecialistaServico')}
                onChange={this.handleServiceSpecialistChange}
                serviceSpecialists={serviceSpecialists}
                disabled
              />

              {/* Serve para um ajuste de responsividade, relacionado a float. */}
              <a className='small'>{'\u00A0'}</a>
            </Field>
          </Col>

          <Col lg={2.4} md={4} xs={12}>
            <Field
              className='no-margin'
              {...fields.date}
              error={t(fields.date.error)}
              glyph='glyph glyph-calendar'
              validate
            >
              <label className='label up-label'>{t('serviceOrders.date')}</label>

              <SingleDatePicker
                id='single-date'
                date={getDate()}
                isDayHighlighted={this.isDeliveryDate}
                placeholder={
                  !isFetchingDates ? t('serviceOrders.date') : t('serviceOrders.loading')
                }
                onDateChange={(event) => this.handleChangeDate(event)}
                focused={focusedDatePicker}
                isDayBlocked={this.isDayBlocked}
                onFocusChange={({ focused }) => this.setState({ focused })}
                numberOfMonths={1}
                displayFormat={FORMAT_DATE_TRUNC}
                disabled
              />
            </Field>
          </Col>

          <Col lg={2.4} md={4} xs={6}>
            <Field
              className='no-margin'
              input={{ value: initialValues.idSlotApplication }}
              error={t(fields.idSlotApplication.error)}
              validate
            >
              <SlotSelect
                value={initialValues.idSlotApplication}
                slots={slots}
                onChange={this.handleChangeShift}
                disabled
              />
            </Field>
          </Col>

          <Col lg={2.4} md={4} xs={6}>
            <Field className='no-margin' {...fields.justificativa} validate>
              <div className='d-flex'>
                {showDistributeButton && <DistributedService statusCd={realStatus} />}
                {showRescheduleButton && <RescheduleService statusCd={realStatus} />}
              </div>
            </Field>
          </Col>
        </Row>

        {visibleSpinnerDate && <Spinner visible={visibleSpinnerDate} />}
      </Grid>
    )
  }
}

EditForm.propTypes = {
  auth: PropTypes.object,
  t: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  serviceOrder: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  setFormValue: PropTypes.func.isRequired,
  getServiceProviders: PropTypes.func.isRequired,
  getServiceSpecialists: PropTypes.func.isRequired,
  serviceOrderStatuses: PropTypes.array.isRequired,
  serviceProviders: PropTypes.array,
  serviceSpecialists: PropTypes.array,
  handleChangeStatus: PropTypes.func.isRequired,
  serviceOrderEdit: PropTypes.object.isRequired,
  serviceOrderDuplicate: PropTypes.object.isRequired,
  funcaoAcesso: PropTypes.object,
  clearServiceSpecialist: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  indStatusOrdemServicoOrigem: PropTypes.string,
  currentStatus: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  getDate: PropTypes.func.isRequired,
  setDate: PropTypes.func.isRequired,
  getShift: PropTypes.func.isRequired,
  setShift: PropTypes.func.isRequired,
  disabledSpecialist: PropTypes.bool.isRequired,
  oldStatus: PropTypes.string,
  changed: PropTypes.bool,
  setServiceOrder: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  showDistributeButton: PropTypes.bool,
  showRescheduleButton: PropTypes.bool,
  realStatus: PropTypes.string,
}

export default EditForm
