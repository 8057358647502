export default [
  {
    path: '/cockpit',
    accessFunctions: [130],
  },
  {
    path: '/serviceOrder',
    accessFunctions: [26],
  },
  {
    path: '/serviceOrder/detail',
    accessFunctions: [129],
    dynamic: true,
  },
  {
    path: '/projects',
    accessFunctions: [140],
    dynamic: true,
  },
  {
    path: '/budgets',
    accessFunctions: [85],
  },
  {
    path: '/payments',
    accessFunctions: [31],
  },
  {
    path: '/providers/edit',
    accessFunctions: [19],
    dynamic: true,
  },
  {
    path: '/providers/new',
    accessFunctions: [19],
  },
  {
    path: '/providers/view',
    accessFunctions: [18],
    dynamic: true,
  },
  {
    path: '/providers',
    accessFunctions: [18, 19],
    dynamic: true,
    unique: true,
  },
  {
    path: '/installers/view',
    accessFunctions: [20],
    dynamic: true,
  },
  {
    path: '/installers/edit',
    accessFunctions: [21],
    dynamic: true,
  },
  {
    path: '/installers/new',
    accessFunctions: [21],
  },
  {
    path: '/installers',
    accessFunctions: [20, 21],
    dynamic: true,
    unique: true,
  },
  {
    path: '/servcPrvdrOccrrncRgstrtn',
    accessFunctions: [111, 50],
  },
  {
    path: '/itemLoc',
    accessFunctions: [68],
  },
  {
    path: '/plntLoc',
    accessFunctions: [68, 70],
  },
  {
    path: '/manualDstrReport',
    accessFunctions: [50, 101],
  },
  {
    path: '/priceReport',
    accessFunctions: [50, 95],
  },
  {
    path: '/ordersDoneWithSatsftn',
    accessFunctions: [50],
  },
  {
    path: '/lenderNetworkAssetValueReport',
    accessFunctions: [50],
  },
  {
    path: '/averageRatingPerStoreAndMonthReport',
    accessFunctions: [50],
  },
  {
    path: '/banks',
    accessFunctions: [124],
  },
  {
    path: '/provider/analysis',
    accessFunctions: [127],
  },
]
