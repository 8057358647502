import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useLocation } from 'react-router'

import { UPDATE_CURRENT_ROUTE } from '../../../redux/actionTypes/menu'
import { closeMenu, toggleMenu } from '../../../redux/actions/menu'
import resetPage from '../../../redux/actions/pagination'
import { fetchServiceOrderFromMenu } from '../../../redux/actions/serviceOrder'

import MozaicIcon from '../../../components/MozaicIcon'
import store from '../../../redux/store'
import MenuHeader from './MenuHeader'
import MenuItem from './MenuItem'

const shortcuts = ['/serviceOrder/detail/118225']

const buildUrl = (fullPath) => `/${fullPath?.split('/')[1]}`

function Menu({ isOpen, items, setItems }) {
  const [currentMenuLabel, setCurrentMenuLabel] = useState('')

  const dispatch = store.dispatch
  const location = useLocation()

  const setMenuHighlight = (label) => {
    dispatch({ type: UPDATE_CURRENT_ROUTE, payload: location.pathname })

    setCurrentMenuLabel(label)

    if (label !== currentMenuLabel) window.scrollTo({ top: 0, behavior: 'smooth' })

    return true
  }

  const highlightSubMenu = (submenus) =>
    submenus?.some((submenu) => {
      const routeMatch = buildUrl(location.pathname) === buildUrl(submenu.url)

      return routeMatch ? setMenuHighlight(submenu.label) : false
    })

  const highlightMenu = () => {
    const shortcut = shortcuts.includes(location.pathname)
    const pathname = shortcut ? location.pathname : buildUrl(location.pathname)

    items.some((menu) => {
      const menuUrl = shortcut ? menu.url : buildUrl(menu.url)
      const routeMatch = pathname === menuUrl

      return routeMatch ? setMenuHighlight(menu.label) : highlightSubMenu(menu.submenus)
    })
  }

  const handleCloseMenu = (shouldResetPage) => {
    dispatch(fetchServiceOrderFromMenu())
    if (shouldResetPage === true) {
      dispatch(resetPage())
    }
  }

  useEffect(() => {
    highlightMenu()
  }, [location.pathname])

  function setSubmenuOpenState(menuLabel, newState) {
    setItems((prevState) => {
      const menu = prevState.find((item) => item.label === menuLabel)

      if (!isOpen && !newState) return [...prevState]

      menu.isSubMenuOpen = newState

      return [...prevState]
    })
  }

  async function onClickMenuItem(menuItem) {
    if (menuItem.newTab) return

    const hasSubmenu = !!menuItem.submenus?.length
    const isMobile = window.innerWidth <= 959

    if (menuItem.url && !menuItem.newTab) {
      const routeMatch = window.location.href.search(menuItem.url) === -1
      handleCloseMenu(routeMatch)
    }

    if (isMobile && !hasSubmenu) dispatch(toggleMenu())

    if (hasSubmenu && !isOpen) {
      dispatch(toggleMenu())
      return setSubmenuOpenState(menuItem.label, true)
    }

    return setSubmenuOpenState(menuItem.label, !menuItem.isSubMenuOpen)
  }

  function onClickSubmenuItem(submenuItem) {
    if (submenuItem.url && !submenuItem.newTab) {
      const routeMatch = window.location.href.search(submenuItem.url) === -1
      handleCloseMenu(routeMatch)
    }

    const hasSubmenu = !!submenuItem.submenus?.length
    const isMobile = window.innerWidth <= 959

    if (isMobile && !hasSubmenu && submenuItem.url) dispatch(toggleMenu())

    setCurrentMenuLabel(submenuItem.label)
  }

  return (
    <>
      <div className={classnames('left-green-bar', { isOpen })} />
      <aside id='global-menu' className={classnames({ isOpen })}>
        <MenuHeader isMenuOpen={isOpen} toggleMenu={() => dispatch(toggleMenu())} />

        <nav>
          <div className='close-menu-mobile'>
            <MozaicIcon icon='ControlCross32' onClick={() => dispatch(toggleMenu())} />
          </div>

          <ul className='menu-items'>
            {items.map((item) => {
              if (item.render === false) return null

              return (
                <MenuItem
                  key={item.label}
                  item={item}
                  onClickMenuItem={onClickMenuItem}
                  onClickSubmenuItem={onClickSubmenuItem}
                  currentMenuLabel={currentMenuLabel}
                  isOpen={isOpen}
                />
              )
            })}
          </ul>
        </nav>

        <div onClick={() => dispatch(closeMenu())} id='close-menu-out-side-click' />
      </aside>
    </>
  )
}

Menu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  items: PropTypes.array,
  setItems: PropTypes.func,
}

const mapStateToProps = ({ auth, menuReducer }) => ({
  parameters: auth.setupParameters,
  isOpen: menuReducer.isOpen,
})

const MenuTranslated = withTranslation()(Menu)

export default connect(mapStateToProps)(MenuTranslated)
