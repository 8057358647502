var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { ToastContainer as ToastContainerBase } from 'react-toastify';
import { injectStyle } from 'react-toastify/dist/inject-style';
import { baseStyles, progressBarStyles } from './styles';
if (typeof window !== 'undefined') {
    injectStyle();
}
var ToastElement = styled('div')(baseStyles, progressBarStyles);
var ToastContainer = forwardRef(function (_a, ref) {
    var props = __rest(_a, []);
    return (_jsx(ToastElement, __assign({ ref: ref }, props, { children: _jsx(ToastContainerBase, __assign({}, props), void 0) }), void 0));
});
ToastContainer.displayName = 'ToastContainer';
ToastContainer.propTypes = {
    /**
     * Determine if toast has autoClose
     * or a timer (in ms) to close.
     */
    autoClose: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    /**
     * Pause toast container when the window
     * loses focus.
     * eg: When switch navigator tabs.
     */
    pauseOnFocusLoss: PropTypes.bool,
    /**
     * Toast container position.
     */
    position: PropTypes.oneOf([
        'top-left',
        'top-right',
        'top-center',
        'bottom-left',
        'bottom-right',
        'bottom-center',
    ]),
    /**
     * Triggers toast type styles.
     */
    type: PropTypes.oneOf(['success', 'error', 'warn', 'info']),
};
ToastContainer.defaultProps = {
    position: 'top-right',
    autoClose: 5000,
    pauseOnFocusLoss: true,
};
/**
 * @component
 */
export default ToastContainer;
