import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import BreadcrumbBar from '../../components/BreadcrumbBar'
import { usableArea } from '../../constants/breakpoints'
import { useInstallerContext } from '../../contexts/installer'
import { useProviderContext } from '../../contexts/provider'
import useElementSize from '../../hooks/useElementSize2'
import AlertsList from './components/AlertsList'
import ServiceOnTime from './components/ServiceOnTime'
import StatusBarContainer from './components/StatusBar'

function CockpitContainer({ t, isOpen }) {
  const [ref, widthContainer, handleSize] = useElementSize(isOpen)

  useEffect(() => handleSize(), [])

  const containerClass =
    widthContainer <= usableArea.medium
      ? 'small'
      : widthContainer <= usableArea.large - 1
      ? 'medium'
      : ''

  const directionWrapper = widthContainer >= usableArea.large ? 'horizontal' : 'vertical'

  return (
    <>
      <BreadcrumbBar title={t('menu.cockpit')} />

      <div id='cockpit-container' className={`${containerClass} ${directionWrapper}`} ref={ref}>
        <div className='top-status-bar'>
          <StatusBarContainer />
        </div>

        <div className='cockpit-content'>
          <section className='alerts'>
            <div className='lateral-status-bar'>
              <StatusBarContainer />
            </div>

            <ServiceOnTime />
            <AlertsList />
          </section>

        </div>
      </div>
    </>
  )
}

CockpitContainer.propTypes = {
  t: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
}

function WithContextCockpit(props) {
  const { handleClearInstallersList, handleClearInstaller } = useInstallerContext()

  const { handleClearProviderList, handleClearProvider } = useProviderContext()

  useEffect(() => {
    handleClearProviderList()
    handleClearProvider()
    handleClearInstallersList()
    handleClearInstaller()
  }, [])

  return <CockpitContainer {...props} />
}

const CockpitContainerTranslated = withTranslation()(WithContextCockpit)

const mapStateToProps = ({ auth, menuReducer }) => ({
  auth,
  isOpen: menuReducer.isOpen,
})

export default connect(mapStateToProps)(CockpitContainerTranslated)
