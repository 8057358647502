var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { AlertTriangleSolid, CheckmarkCircleSolid, } from '../../../../../../../leroy-merlin-br/backyard-icons';
import { textKilo } from '../../styles/style-helpers';
var baseStyles = function (_a) {
    var theme = _a.theme;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  label: validation-hint;\n\n  display: flex;\n  align-items: center;\n\n  margin-top: ", ";\n\n  ", ";\n  transition: color ", ";\n"], ["\n  label: validation-hint;\n\n  display: flex;\n  align-items: center;\n\n  margin-top: ", ";\n\n  ", ";\n  transition: color ", ";\n"])), theme.spacings.bit, textKilo({ theme: theme }), theme.transition.enter);
};
var disabledStyles = function (_a) {
    var theme = _a.theme, isDisabled = _a.isDisabled;
    return isDisabled && css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    label: validation-hint--disabled;\n    color: ", ";\n  "], ["\n    label: validation-hint--disabled;\n    color: ", ";\n  "])), theme.colors.info);
};
var invalidStyles = function (_a) {
    var theme = _a.theme, state = _a.state, isDisabled = _a.isDisabled;
    return !isDisabled &&
        state === 'invalid' && css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    label: validation-hint--invalid;\n    color: ", ";\n  "], ["\n    label: validation-hint--invalid;\n    color: ", ";\n  "])), theme.colors.danger);
};
var validStyles = function (_a) {
    var theme = _a.theme, state = _a.state, isDisabled = _a.isDisabled;
    return !isDisabled &&
        state === 'valid' && css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    label: validation-hint--valid;\n    color: ", ";\n  "], ["\n    label: validation-hint--valid;\n    color: ", ";\n  "])), theme.colors.success);
};
var warningStyles = function (_a) {
    var theme = _a.theme, state = _a.state, isDisabled = _a.isDisabled;
    return !isDisabled &&
        state === 'warning' && css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    label: validation-hint--warning;\n    color: ", ";\n  "], ["\n    label: validation-hint--warning;\n    color: ", ";\n  "])), theme.colors.warning);
};
var iconStyles = function (color) { return function (theme) {
    return css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n      label: ", ";\n\n      width: ", ";\n      height: ", ";\n\n      box-sizing: border-box;\n      margin-right: ", ";\n\n      color: ", ";\n      pointer-events: none;\n    "], ["\n      label: ", ";\n\n      width: ", ";\n      height: ", ";\n\n      box-sizing: border-box;\n      margin-right: ", ";\n\n      color: ", ";\n      pointer-events: none;\n    "])), "validation-hint__icon--" + color, theme.spacings.kilo, theme.spacings.kilo, theme.spacings.bit, theme.colors[color]);
}; };
var getIcon = function (state) {
    switch (true) {
        case state.isDisabled: {
            return null;
        }
        case state.state === 'invalid': {
            return (_jsx(AlertTriangleSolid, { name: "checkmark-circle", role: "presentation", css: iconStyles('danger') }, void 0));
        }
        case state.state === 'valid': {
            return (_jsx(CheckmarkCircleSolid, { name: "alert-triangle", role: "presentation", css: iconStyles('success') }, void 0));
        }
        case state.state === 'warning': {
            return (_jsx(AlertTriangleSolid, { name: "alert-triangle", role: "presentation", css: iconStyles('warning') }, void 0));
        }
        default: {
            return null;
        }
    }
};
var Wrapper = styled('span')(baseStyles, disabledStyles, invalidStyles, validStyles, warningStyles);
var ValidationHint = React.forwardRef(function (props, ref) {
    var hint = props.hint;
    if (!hint) {
        return null;
    }
    var icon = React.createElement(getIcon, props);
    return (_jsxs(Wrapper, __assign({ ref: ref }, props, { children: [icon, hint] }), void 0));
});
export default ValidationHint;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
