import store from '../../../../../../redux/store'
import i18next from 'i18next'

import { useServiceOrderDetailContext } from '../../../../context'
import { useAdvancedSearchContext } from '../../../../../../contexts/advancedSearch'

function PdvInfo() {
  const { serviceOrderBody, loadingBody } = useServiceOrderDetailContext()

  const { handleSubmitDefault } = useAdvancedSearchContext()

  const handleClickPDV = () => {
    handleSubmitDefault({
      currentSearchField: 'orderNumer',
      currentSearchFieldValue: serviceOrderBody?.vaSlsOrdNr.toString(),
    })
  }

  if (loadingBody) return <div id='pdv-info' className='skeleton' />

  const vaSlsOrdNr = serviceOrderBody?.vaSlsOrdNr
    ? serviceOrderBody?.vaSlsOrdNr.toString()
    : '-'

  return (
    <div id='pdv-info'>
      <label htmlFor=''>{i18next.t('pdv.text')}</label>
      <section>
        <span className='sales-order-text' onClick={handleClickPDV}>
          {vaSlsOrdNr}
        </span>
      </section>
    </div>
  )
}

export default PdvInfo
