import PropTypes from 'prop-types'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import BreadcrumbBar from '../../components/BreadcrumbBar'
import InactiveProviderModal from '../../components/utils/InactiveProviderModal'
import JustificationModal from '../../components/utils/JustificationModal'
import { renderToastWarn } from '../../helpers/ToastUtils'
import { formatCPF } from '../../helpers/masks'
import { hideSpinner, showSpinner } from '../../redux/actions/spinner'
import store from '../../redux/store'
import { downloadFile } from '../../services/amazonAws/DownloadFile'
import Form from './components/EditForm'
import ServicePersonModal, { SPECIALIST_DOCUMENTS } from './components/ServicePersonModal'
import { WithContext } from './context'

class ServiceSpecialistEditContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      serviceSpecialistModal: false,
      filesToAdd: [],
      action: null,
      message: '',
      params: [],
      properties: {},
      inactiveProviderModal: {},
      updateSysAcsUser: false,
      justificationModal: { open: false },
      validation: true,
      certificates: [],
      error: { fields: [] },
    }
  }

  getPhone = (phone) => {
    let newPhone = {
      valMeioComunicacao: '',
    }

    if (phone) {
      newPhone = {
        idEspecialistaServico: phone.idEspecialistaServico,
        idTipoMeioComunicacao: phone.idTipoMeioComunicacao,
        idSeqMeioComunicacao: phone.idSeqMeioComunicacao,
        valMeioComunicacao: phone.valMeioComunicacao,
      }
    }

    return newPhone
  }

  async componentDidMount() {
    const {
      getServiceSpecialistFromId,
      auth: { user },
      match: {
        params: { id },
        path,
      },
      handleClearInstaller,
    } = this.props

    handleClearInstaller()

    if (id) {
      if (path.includes('edit') || path.includes('view')) {
        store.dispatch(showSpinner())
        const editOrView = path.includes('edit') ? 'edit' : 'view'
        const serviceSpecialist = await getServiceSpecialistFromId(
          user.dsToken,
          id,
          editOrView,
          true,
        )
        this.handleFetchServiceSpecialistFiles(id)
        this.handleFetchServcPrvdrAgntAbsncPerd({ servcPrvdrAgntCd: id })
        this.handleFetchSysAcsUser(serviceSpecialist.sysAcsUserId)
      }
    } else {
      this.getServiceProviders()
      this.setState({
        updateSysAcsUser: true,
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { installerEdit } = this.props

    if (prevProps.installerEdit !== installerEdit) {
      this.forceUpdate()
    }
  }

  getServiceProviders = (filters = {}) => {
    const {
      auth: { user },
      fetchServiceProviderAutocomplete,
      installerEdit,
    } = this.props

    const defaultValues = []

    if (installerEdit && installerEdit?.idPrestadorServico) {
      defaultValues.push({
        servcPrvdrId: installerEdit.idPrestadorServico,
        servcPrvdrNm: installerEdit.nomePrestadorServico,
        servcPrvdrTrdNm: installerEdit.nomeFantasiaPrestadorServico,
      })
    }

    const parsedFilters = {
      ...filters,
      servcPrvdrTrdNm: filters.nomePrestadorServico,
      actvServcPrvdrInd: '1',
    }

    fetchServiceProviderAutocomplete(
      user.dsToken,
      parsedFilters,
      null,
      null,
      null,
      null,
      defaultValues,
    )
  }

  toggleServiceSpecialistModal = () => {
    const { installerDocs, t, installerEdit } = this.props
    const { serviceSpecialistModal } = this.state

    if (installerEdit?.disabled) {
      if (!installerDocs.length) {
        return alert(t('serviceSpecialists.noDocuments'))
      }
    }

    this.setState({
      serviceSpecialistModal: !serviceSpecialistModal,
    })
  }

  renderServiceSpecialistModal = () => {
    const { serviceSpecialistModal, filesToAdd } = this.state
    const {
      t,
      installerEdit,
      installerDocs,
      auth,
      match: {
        params: { id },
      },
    } = this.props

    const { certificates } = this.state

    if (!serviceSpecialistModal) return

    const attachments = installerEdit?.idEspecialistaServico ? installerDocs : filesToAdd
    const props = {
      t,
      modalType: SPECIALIST_DOCUMENTS,
      closeModal: this.toggleServiceSpecialistModal,
      initialValues: {
        idEspecialistaServico: installerEdit.idEspecialistaServico
          ? installerEdit.idEspecialistaServico
          : id,
      },
      auth,
      attachments,
      error: false,
      onSubmit: this.handleSaveServiceSpecialistFiles,
      onDelete: this.handleRemoveServiceSpecialistFiles,
      downloadFile: this.handleDownloadFile,
      disabled: installerEdit?.disabled,
      updateLocalFiles: this.updateLocalFiles,
      dispatch: store.dispatch,
      certificates,
    }

    return <ServicePersonModal {...props} />
  }

  handleDownloadFile = (fileName, attachmentDescription) => {
    if (!fileName || fileName.length === 0 || fileName === '' || fileName == null) {
      return null
    }

    downloadFile(fileName, attachmentDescription)
  }

  handleFetchServiceSpecialistFiles = (idEspecialistaServico) => {
    const {
      fetchServiceSpecialistFiles,
      auth: { user },
    } = this.props

    fetchServiceSpecialistFiles(user.dsToken, idEspecialistaServico)
  }

  handleSaveServiceSpecialistFiles = (values) => {
    const {
      saveServiceSpecialistFiles,
      auth: { user },
      t,
    } = this.props

    return saveServiceSpecialistFiles(user.dsToken, values, t)
  }

  handleRemoveServiceSpecialistFiles = (servcPrvdrAgntId, servcPrvdrAgntAtchmtId) => {
    const {
      removeSpecialistFile,
      auth: { user },
      t,
    } = this.props

    return removeSpecialistFile(user.dsToken, servcPrvdrAgntId, servcPrvdrAgntAtchmtId, t)
  }

  updateLocalFiles = (filesToAdd) => {
    this.setState({ filesToAdd })
  }

  handleFetchServcPrvdrAgntAbsncPerd = (values) => {
    const {
      fetchServcPrvdrAgntAbsncPerd,
      auth: { user },
    } = this.props

    fetchServcPrvdrAgntAbsncPerd(user, values)
  }

  handleFetchSysAcsUser = (args) => {
    const {
      fetchSysAcsUser,
      auth: { user },
    } = this.props
    fetchSysAcsUser(user.dsToken, args).then(() => {
      this.setState({ updateSysAcsUser: true })
      store.dispatch(hideSpinner())
    })
  }

  openModal = (field, callback, index) => {
    const { installerEdit } = this.props
    const { servcPrvdrAgntId, servcPrvdrCd } = installerEdit

    if (installerEdit.servcProviderIsActive === '1') {
      this.setState({
        justificationModal: {
          open: true,
          servcPrvdrAgntId,
          field,
          callback,
          index,
          servcPrvdrCd,
        },
      })
      this.renderJustificationModal()
    } else {
      this.setState({
        inactiveProviderModal: {
          open: true,
          servcPrvdrAgntId,
          field,
          callback,
          index,
          servcPrvdrCd,
        },
      })
      this.renderInactiveProviderModal()
    }
  }

  closeJustificationModal = () => {
    this.setState({ justificationModal: { open: false } })
  }

  closeInactiveProviderModal = () => {
    this.setState({ inactiveProviderModal: { open: false } })
  }

  renderJustificationModal = () => {
    const { t } = this.props

    const { justificationModal, validation } = this.state

    if (!justificationModal.open) {
      return
    }

    return (
      <JustificationModal
        closeModal={this.closeJustificationModal}
        initialValues={this.getJustificationModalInitialValues()}
        fieldKey={justificationModal.field.key}
        fieldValue={justificationModal.field.value}
        onSubmit={this.handleSubmitJustificationModal}
        t={t}
        validation={validation}
      />
    )
  }

  renderInactiveProviderModal = () => {
    const { t } = this.props
    const { inactiveProviderModal, validation } = this.state

    if (!inactiveProviderModal.open) {
      return
    }

    return (
      <InactiveProviderModal
        closeModal={this.closeInactiveProviderModal}
        initialValues={this.getInactiveProviderModalInitialValues()}
        fieldKey={inactiveProviderModal.field.key}
        onSubmit={this.handleSubmitJustificationModal}
        servcPrvdrCd={inactiveProviderModal.servcPrvdrCd}
        validation={validation}
        t={t}
      />
    )
  }

  getJustificationModalInitialValues = () => {
    const { justificationModal } = this.state

    return {
      field: justificationModal.field.value,
      description: '',
    }
  }

  getInactiveProviderModalInitialValues = () => {
    const { inactiveProviderModal } = this.state

    return {
      field: inactiveProviderModal.field.value,
      description: '',
    }
  }

  handleSubmitJustificationModal = async (values) => {
    const { auth, installerEdit } = this.props

    const { justificationModal } = this.state

    const params = {
      ...values,
      servcAgntId: justificationModal.servcPrvdrAgntId,
    }

    this.closeJustificationModal()

    await justificationModal.callback(
      auth.user.dsToken,
      params,
      (actvServcPrvdrAgntInd) => {
        installerEdit.indEspecialistaServicoAtivo = actvServcPrvdrAgntInd
      },
      (error) => {
        renderToastWarn(error)
      },
    )
  }

  handleUpdateInstaller = async (token, values) => {
    const { updateServiceSpecialist } = this.props

    return updateServiceSpecialist(token, values, {
      redirectTo: null,
      showToastSuccess: false,
    }).catch((error) => this.setState({ error }))
  }

  resetError = (fields) => {
    const { error } = this.state
    const errorFields = error?.fields

    // eslint-disable-next-line no-param-reassign
    if (typeof fields === 'string') fields = [fields]
    if (!error) return false
    if (!errorFields?.length) return false

    const newFields = errorFields.filter((err) => !fields.includes(err.naField))

    this.setState({ error: { ...error, fields: newFields } })
  }

  render() {
    const {
      t,
      installerEdit,
      serviceProviders,
      auth,
      auth: {
        user: { accessFunctionList },
      },
      servcPrvdrAgntAbsncPerds,
      installerAccess,
      inactiveSpecialist,
      match: {
        params: { id },
      },
      history,
    } = this.props

    const disabled = installerEdit?.disabled
    const { filesToAdd, error } = this.state

    if (!installerEdit.hasOwnProperty('lsPhones')) {
      installerEdit.lsPhones = []
    }

    const lsPhones = [
      this.getPhone(installerEdit.lsPhones[0]),
      this.getPhone(installerEdit.lsPhones[1]),
      this.getPhone(installerEdit.lsPhones[2]),
    ]

    let lsBranches = []
    if (installerEdit.lsServcPrvdrAgntPlnt) {
      lsBranches = installerEdit.lsServcPrvdrAgntPlnt.map((branch) => ({
        value: branch.id,
        label: `${branch.id} - ${branch.name}`,
      }))
    }

    let initialValues = {
      idPrestadorServico: '',
      nomePrestadorServico: '',
      filesToAdd,
    }

    if (id && installerEdit) {
      initialValues = {
        idEspecialistaServico: installerEdit.idEspecialistaServico,
        servcPrvdrAgntId: installerEdit.servcPrvdrAgntId,
        idPrestadorServico: installerEdit.idPrestadorServico,
        nomePrestadorServico: installerEdit.nomePrestadorServico,
        numCpfEspecialistaServico: formatCPF(installerEdit.numCpfEspecialistaServico, t),
        numRgEspecialistaServico: installerEdit.numRgEspecialistaServico,
        natrlPrsnAddlTaxIdIssurCd: installerEdit.natrlPrsnAddlTaxIdIssurCd,
        natrlPrsnAddlTaxIdIssurStCd: installerEdit.natrlPrsnAddlTaxIdIssurStCd,
        natrlPrsnAddlTaxIdDigtNr: installerEdit.natrlPrsnAddlTaxIdDigtNr,
        nomeEspecialistaServico: installerEdit.nomeEspecialistaServico,
        natrlPrsnAddlTaxIdIssurDt: installerEdit.natrlPrsnAddlTaxIdIssurDt,
        servcPrvdrAgntEmailTxt: installerEdit.servcPrvdrAgntEmailTxt,
        codLocalCadastroEspecialistaServico: installerEdit.codLocalCadastroEspecialistaServico,
        datNascimento: installerEdit.datNascimento,
        indEstadoCivil: installerEdit.indEstadoCivil,
        servcPrvdrAgntMthrNm: installerEdit.servcPrvdrAgntMthrNm,
        servcPrvdrAgntFthrNm: installerEdit.servcPrvdrAgntFthrNm,
        descNaturalidade: installerEdit.descNaturalidade,
        indTipoRegistroEspecialistaServico: installerEdit.indTipoRegistroEspecialistaServico,
        numRegistroEspecialistaServico: installerEdit.numRegistroEspecialistaServico,
        codUserEspecialistaServico: installerAccess.sysAcsUserCd,
        descSenhaEspecialistaServico: installerAccess.sysAcsUserPswdTxt,
        descSenhaEspecialistaServicoConfirm: installerAccess.sysAcsUserPswdTxt,
        indEspecialistaServicoAtivo: installerEdit.indEspecialistaServicoAtivo,
        idPerfilAcesso: installerAccess.sysAcsPrflId,
        datUltimaSincronizacao: installerAccess.sysAcsUserLastLognTs,
        lsPhones,
        phone1: lsPhones[0].valMeioComunicacao,
        phone2: lsPhones[1].valMeioComunicacao,
        phone3: lsPhones[2].valMeioComunicacao,
        servcPrvdrAgntStrtAbsncDt: installerEdit.servcPrvdrAgntStrtAbsncDt,
        servcPrvdrAgntEndAbsncDt: installerEdit.servcPrvdrAgntEndAbsncDt,
        lastLognSysVerCd: installerAccess.lastLognSysVerCd,
        sysAcsUserAvgGrade: installerEdit.sysAcsUserAvgGrade,
        lsBranches,
        filesToAdd,
        datInclusaoReg: installerEdit.datInclusaoReg,
        sysAcsUserId: installerEdit.sysAcsUserId,
        identifyRegistrationOrigin: installerEdit.identifyRegistrationOrigin,
      }
    }

    const blockPassword = !(
      auth.user &&
      auth.user.funcaoAcesso &&
      auth.user?.funcaoAcesso?.inFuncao90
    )

    return (
      <section>
        <BreadcrumbBar title={t('lmi.serviceSpecialists')} />

        <Form
          t={t}
          initialValues={initialValues}
          getServiceProviders={this.getServiceProviders}
          serviceProviders={serviceProviders}
          toggleServiceSpecialistModal={this.toggleServiceSpecialistModal}
          disabled={
            disabled || !accessFunctionList?.some((accessFunction) => accessFunction === 21)
          }
          auth={auth}
          dispatch={store.dispatch}
          servcPrvdrAgntAbsncPerds={servcPrvdrAgntAbsncPerds}
          error={error}
          resetError={this.resetError}
          index={id}
          inactiveSpecialist={inactiveSpecialist}
          openJustificationModal={this.openModal}
          updateServiceSpecialist={this.handleUpdateInstaller}
          blockPassword={blockPassword}
          history={history}
        />

        {this.renderServiceSpecialistModal()}
        {this.renderJustificationModal()}
        {this.renderInactiveProviderModal()}
      </section>
    )
  }
}

ServiceSpecialistEditContainer.propTypes = {
  t: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  updateServiceSpecialist: PropTypes.func.isRequired,
  installerEdit: PropTypes.object.isRequired,
  installerDocs: PropTypes.array.isRequired,
  fetchServiceProviderAutocomplete: PropTypes.func.isRequired,
  serviceProviders: PropTypes.array,
  fetchServiceSpecialistFiles: PropTypes.func.isRequired,
  saveServiceSpecialistFiles: PropTypes.func.isRequired,
  removeSpecialistFile: PropTypes.func.isRequired,
  downloadFile: PropTypes.func.isRequired,
  servcPrvdrAgntAbsncPerds: PropTypes.array.isRequired,
  fetchServcPrvdrAgntAbsncPerd: PropTypes.func.isRequired,
  fetchSysAcsUser: PropTypes.func.isRequired,
  installerAccess: PropTypes.object.isRequired,
  inactiveSpecialist: PropTypes.func.isRequired,
  getServiceSpecialistFromId: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object,
  handleClearInstaller: PropTypes.func,
}

const mapStateToProps = ({ auth }) => ({
  auth,
})

const ServiceSpecialistEdit = withTranslation()(ServiceSpecialistEditContainer)

export default withRouter(connect(mapStateToProps, null)(WithContext(ServiceSpecialistEdit)))
