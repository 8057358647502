import i18next from 'i18next'
import { toast } from '../../../../leroy-merlin-br/backyard-react'

import API_REQUEST from '../../../core/Http/FetchAdapter'
import API_URL from '../../../core/Envs/endpoints'

import User from '../../../core/User'

import store from '../../../redux/store'

const user = new User().currentUser

export interface AddAttachmentParams {
  servcOrdSeq: number
  files: { name: string; file: string; fileIndicador: number }[]
  atchmtDocmntTyp: string
  atchmtDocmntTypDesc: string
  isPortalService: boolean
  type: 'img' | 'pdf'
}

export const addAttachment = async (params: AddAttachmentParams) => {
  try {
    const { cdStatus } = await new API_REQUEST(user.dsToken, store.dispatch).post(
      API_URL.ORDER_SERVC_ATTACHMENT,
      params,
    )

    toast.success(
      i18next.t('success.text'),
      i18next.t('serviceOrder.attachments.photos.successfullyRegistered'),
      '',
    )

    return cdStatus
  } catch (error: any) {
    toast.error('', error?.message?.dsMessage || i18next.t('dao.save.error'), '')
    return error
  }
}

export const fileToBase64 = async (f: File): Promise<string> => {
  const reader = new FileReader()

  return new Promise((resolve) => {
    reader.onloadend = () => resolve(reader.result as string)
    reader.readAsDataURL(f)
  })
}
