import API_REQUEST from '../../../core/Http/FetchAdapter'
import API_URL from '../../../core/Envs/endpoints'
import i18next from 'i18next'
import store from '../../../redux/store'

import { toast } from '../../../../leroy-merlin-br/backyard-react'

import { showSpinner, hideSpinner } from '../../../redux/actions/spinner'

export type ParamsType = {
  servcOrdStusCd: string
  manualOccurrence: boolean
  justificativa: string
}

export const cancelOS = async (payload: ParamsType) => {
  store.dispatch(showSpinner())

  try {
    const response = await new API_REQUEST(null, store.dispatch).post(
      API_URL.SERVICE_ORDER_CANCEL,
      payload,
    )

    toast.success(i18next.t('success.text'), i18next.t('serviceOrder.status.canceled'), '')

    return response.data
  } catch (error: any) {
    toast.error('', error?.message?.dsMessage || i18next.t('dao.save.error'), '')

    return console.error(error)
  } finally {
    store.dispatch(hideSpinner())
  }
}
