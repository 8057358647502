import { useState } from 'react'
import i18next from 'i18next'
import store from '../../../../../../redux/store'
import User from '../../../../../../core/User'

import { useServiceOrderDetailContext } from '../../../../context'
import { hideSpinner, showSpinner } from '../../../../../../redux/actions/spinner'
import { backOrderToPreSchedule } from '../../../../../../services/pages/ServiceOrderDetail/BackToPreSchedule'
import { toast } from '../../../../../../../leroy-merlin-br/backyard-react'
import MozaicText from '../../../../../../components/MozaicText'

import OutlineButton from '../../../../../../components/OutlineButton'
import FooterButtons from './components/FooterButtons'
import JustifyInput from './components/JustifyInput'
import Modal from '../../../../../../components/Modal'

const MANUAL_DISTRIBUTED = '48'

function BackToPreSchedule() {
  const user = new User().currentUser
  const setupParameters = store.getState().auth.setupParameters
  const accessFunctionList = user?.accessFunctionList
  const { statusConfiguredDisplayPreScheduleLink } = setupParameters
  const { serviceOrderBody, occurrences, reloadServiceOrder } = useServiceOrderDetailContext()

  const [show, setShow] = useState<boolean>(false)
  const [justify, setJustify] = useState<string>('')

  const isAutoDistributed =
    occurrences.findIndex((ocurrence) => ocurrence.servcOrdEvntStusInd === MANUAL_DISTRIBUTED) ===
    -1

  const allowedStatus = statusConfiguredDisplayPreScheduleLink.split(',')
  const canSubmit = isAutoDistributed ? justify.length >= 10 : true
  const haveToShow =
    allowedStatus.includes(serviceOrderBody?.servcOrdStusCd) && accessFunctionList?.includes(121)

  const handleChangeJustify = (e: any) => {
    setJustify(e.target.value)
  }

  const handleSubmit = async () => {
    if (!serviceOrderBody) return false
    store.dispatch(showSpinner())

    try {
      const payload = {
        servcOrdStusCd: serviceOrderBody.servcOrdStusCd,
        servcOrdSeq: serviceOrderBody.servcOrdSeq,
        justificationBackToPreScheduled: justify,
      }

      await backOrderToPreSchedule(payload)
      await reloadServiceOrder()

      setShow(false)
      toast.success('', i18next.t('serviceOrders.saved'), '')
    } catch (error: any) {
      toast.error('', error?.dsMessage || i18next.t('dao.save.error'), '')
    }

    store.dispatch(hideSpinner())
  }

  const labelText = i18next
    .t('serviceOrders.change.os.to.text')
    .replace('{status}', i18next.t('serviceOrders.provider.preScheduled').toLowerCase())

  return (
    <>
      {haveToShow && (
        <div id='back-to-pre-schedule'>
          <OutlineButton
            label={i18next.t('serviceOrders.change.os.to.pre.scheduled')}
            onClick={() => setShow(true)}
          />

          <Modal
            size='lg'
            isOpen={show}
            onClose={() => setShow(false)}
            className='no-margin-header'
            closeInOverlay={false}
            contentLabel={i18next.t('serviceOrders.change.os.to.pre.scheduled')}
          >
            <MozaicText> {labelText} </MozaicText>

            {isAutoDistributed && <JustifyInput value={justify} onChange={handleChangeJustify} />}

            <FooterButtons
              disabled={!canSubmit}
              handleSubmit={handleSubmit}
              handleClose={() => setShow(false)}
            />
          </Modal>
        </div>
      )}
    </>
  )
}

export default BackToPreSchedule
