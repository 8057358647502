/* eslint-disable import/no-cycle */
import { combineReducers } from 'redux'

import auth from './auth'
import i18nReducer from './I18n'
import spinnerReducer from './spinner'
import menuReducer from './menu'
import newCockpitReducer from './newCockpit'
import serviceOrderReducer from './serviceOrder'
import paginateReducer from './pagination'
import serviceOccurrenceReducer from './serviceOccurrence'

const rootReducer = combineReducers({
  auth,
  i18nReducer,
  menuReducer,
  newCockpitReducer,
  spinnerReducer,
  paginateReducer,
  serviceOrderReducer,
  serviceOccurrenceReducer,
})

export default rootReducer
