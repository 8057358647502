import API_REQUEST from '../../../core/Http/FetchAdapter'
import API_URL from '../../../core/Envs/endpoints'
import i18next from 'i18next'
import { toast } from '../../../../leroy-merlin-br/backyard-react'

import store from '../../../redux/store'

export const confirmOS = async (servcOrdSeq: number) => {
  try {
    const response = await new API_REQUEST(null, store.dispatch).post(
      `${API_URL.CONFIRM_SERVICE_ORDER}/${servcOrdSeq}`,
      {},
    )

    toast.success(
      i18next.t('success.text'),
      i18next.t('order.detail.confirm.os.modal.sucess.toast'),
      '',
    )
    return response.data
  } catch (error: any) {
    toast.error('', error?.message?.dsMessage || i18next.t('dao.save.error'), '')
    return console.error(error)
  }
}
