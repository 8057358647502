import i18next from 'i18next'
import { FormEvent, useEffect, useMemo, useState } from 'react'

import { RadioGroup, TextField } from '../../../../../../../../../leroy-merlin-br/backyard-react'
import Modal from '../../../../../../../../components/Modal'
import MozaicButton from '../../../../../../../../components/MozaicButton'

import { useServiceOrderDetailContext } from '../../../../../../context'

import { checkWorksiteQuantity } from '../../../../../../../../services/pages/ServiceOrderDetail/CheckWorksiteQuantity'
import { updateWorksite } from '../../../../../../../../services/pages/ServiceOrderDetail/UpdateWorksite'

interface WorksiteEditProps {
  title: string
  isOpen: boolean
  servcOrdWorksiteNumber: number
  onClose: () => void
}

let changeWorksiteDelay: any = null

function WorksiteEdit(props: WorksiteEditProps) {
  const { servcOrdCd, fetchServiceOrderBody } = useServiceOrderDetailContext()
  const { title, onClose, isOpen, servcOrdWorksiteNumber } = props

  const [worksiteNumber, setWorksiteNumber] = useState(servcOrdWorksiteNumber)
  const [auxWorksiteNumber, setAuxWorksiteNumber] = useState(0)
  const [worksiteMode, setWorksiteMode] = useState('move')
  const [worksiteExists, setWorksiteExists] = useState(true)
  const [canSubmit, setCanSubmit] = useState(true)

  async function handleCheckWorksiteExists() {
    const value = await checkWorksiteQuantity(worksiteNumber)

    setWorksiteExists(() => value > 0)
  }

  function handleResolveWorksiteNumber() {
    if (worksiteMode === 'move' && auxWorksiteNumber !== 0) setWorksiteNumber(auxWorksiteNumber)
    setWorksiteNumber(servcOrdWorksiteNumber)
  }

  function handleChangeWorksiteMode(event: FormEvent<HTMLInputElement>) {
    setWorksiteMode((event.target as HTMLInputElement).value)
  }

  function handleChangeWorksiteNumber(event: FormEvent<HTMLInputElement>) {
    const newValue = parseInt(
      (event.target as HTMLInputElement).value?.replace(/[^0-9]/g, '') || '0',
    )
    setWorksiteNumber(newValue)
    setAuxWorksiteNumber(newValue)
  }

  useEffect(() => {
    handleResolveWorksiteNumber()
  }, [worksiteMode])

  useEffect(() => {
    setCanSubmit(false)

    if (changeWorksiteDelay) clearTimeout(changeWorksiteDelay)
    changeWorksiteDelay = setTimeout(async () => {
      await handleCheckWorksiteExists()

      setCanSubmit(true)
    }, 750)
  }, [worksiteNumber])

  function getWorksiteTextFieldState() {
    if (worksiteMode === 'move' && !worksiteExists) return 'invalid'
    return ''
  }

  function getWorksiteTextFieldHint() {
    if (worksiteMode === 'move' && worksiteExists)
      return i18next.t('serviceOrder.worksite.type.existing')

    if (worksiteMode === 'move' && !worksiteExists)
      return i18next.t('serviceOrder.worksite.invalid')
  }

  const handleShouldDisableRemove = () => !servcOrdWorksiteNumber || +servcOrdWorksiteNumber <= 1

  const handleDisabledSend = () => {
    const disabledRemove = handleShouldDisableRemove()

    if (worksiteNumber === 0) return false
    if (worksiteMode === 'remove' && !disabledRemove) return true
    if (+worksiteNumber === +servcOrdWorksiteNumber) return false
    if (worksiteMode === 'move' && worksiteExists) return true

    return false
  }

  async function handleUpdateWorksite() {
    await updateWorksite(servcOrdCd, worksiteMode, worksiteNumber)
    await fetchServiceOrderBody()
    onClose()
  }

  const activeSubmitButton = useMemo(
    () => handleDisabledSend(),
    [worksiteMode, worksiteExists, worksiteNumber],
  )

  const textFieldHint = useMemo(() => getWorksiteTextFieldHint(), [worksiteMode, worksiteExists])

  const textFieldState = useMemo(() => getWorksiteTextFieldState(), [worksiteMode, worksiteExists])

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      contentLabel={title}
      className='worksite-modal-os'
      size='lg'
    >
      <div id='worksite-edit-container'>
        <section className='content'>
          <div className='descriptions'>
            <span>{i18next.t('serviceOrder.worksite.desc1')}</span>
            <span>{i18next.t('serviceOrder.worksite.desc2')}</span>
          </div>

          <div className='radios-content'>
            <label htmlFor='worksite-operation' className='title-radios'>
              {i18next.t('serviceOrder.worksite.change.options')}
            </label>
            <RadioGroup
              name='worksite-operation'
              label={i18next.t('serviceOrder.worksite.change.options')}
              value={worksiteMode}
              options={[
                {
                  children: i18next.t('serviceOrder.worksite.chage.option.move.existing'),
                  value: 'move',
                },
                {
                  children: i18next.t('serviceOrder.worksite.chage.option.remove'),
                  value: 'remove',
                  isDisabled: servcOrdWorksiteNumber === 0,
                },
              ]}
              onChange={(event) => handleChangeWorksiteMode(event)}
            />
          </div>

          <div className='text-field'>
            <div className='text-field-pos'>
              <TextField
                value={worksiteNumber}
                state={textFieldState || undefined}
                hint={textFieldHint as string}
                label={i18next.t('serviceOrder.worksite.number')}
                placeholder={i18next.t('serviceOrder.worksite.number')}
                maxLength={18}
                onChange={(event) => handleChangeWorksiteNumber(event)}
                isDisabled={worksiteMode === 'remove'}
              />
            </div>
          </div>

          <div className='footer-buttons'>
            <MozaicButton variant='ghost' theme='primary-02' onClick={onClose}>
              {i18next.t('provider.portal.cancel')}
            </MozaicButton>
            <MozaicButton
              onClick={() => handleUpdateWorksite()}
              isDisabled={!activeSubmitButton || !canSubmit}
            >
              {i18next.t('provider.portal.confirm')}
            </MozaicButton>
          </div>
        </section>
      </div>
    </Modal>
  )
}

export default WorksiteEdit
