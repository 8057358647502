import moment from 'moment-timezone'
import i18next from 'i18next'

import User from '../core/User'

import IndicatorType from './indicators'
import ServcOrdAllStates from '../enums/ServiceOrder/flux/ServcOrdAllStates'
import ServiceOrderStatusType from '../enums/ServiceOrder/status/ServiceOrderStatusType'
import ServcOrdCategoryType from '../enums/ServiceOrder/category/serviceCategoryType'

import {
  COCKPIT_ALERTS_FOR_SERVICE_COORDINATOR,
  COCKIPT_PREVIOUSLY_LATE,
  WAITING_FOR_EVALUATION,
  COCKIPT_TODAY_LATE,
  BUDGET_PEDDING,
  COCKPIT_WAITING_FOR_QUOTATION,
  COCKIPT_ACCEPTANCE_SERVICE_CONDITIONS,
  COCKPIT_WAITING_MEASUREMENT,
  COOKPIT_VALIDATE_MEASUREMENT,
  COOKPIT_EMERGENCY_EXPIRED,
  ALERTS_IDS,
} from './config'

import { isTechVisitAllowed } from '../helpers/isTechVisit'

const user = new User().currentUser
const today = new Date()
const yesterday = new Date().setDate(today.getDate() - 1)
const tomorrow = new Date().setDate(today.getDate() + 1)

export const indicatorsData = () => [
  {
    id: ALERTS_IDS.COORDINATOR_ATTENTION,
    type: IndicatorType.COORDINATOR_ATTENTION,
    value: 0,
    title: 'services.alert_17',
    subtitle: 'services.alert_17.subtitle.text',
    render: true,
    filters: {
      lsStatus: [
        {
          value: ServcOrdAllStates.FLUX_CLIENT_PRE_SCHEDULE.id,
          label: i18next.t(ServcOrdAllStates.FLUX_CLIENT_PRE_SCHEDULE.value),
        },
        {
          value: ServcOrdAllStates.FLUX_CLIENT_SCHEDULE.id,
          label: i18next.t(ServcOrdAllStates.FLUX_CLIENT_SCHEDULE.value),
        },
        {
          value: ServcOrdAllStates.FLUX_CLIENT_DISTRIBUTED.id,
          label: i18next.t(ServcOrdAllStates.FLUX_CLIENT_DISTRIBUTED.value),
        },
        {
          value: ServcOrdAllStates.FLUX_CLIENT_ASSOCIATED.id,
          label: i18next.t(ServcOrdAllStates.FLUX_CLIENT_ASSOCIATED.value),
        },
        {
          value: ServcOrdAllStates.FLUX_CLIENT_CONFIRMED.id,
          label: i18next.t(ServcOrdAllStates.FLUX_CLIENT_CONFIRMED.value),
        },
        {
          value: ServcOrdAllStates.FLUX_CLIENT_DOING.id,
          label: i18next.t(ServcOrdAllStates.FLUX_CLIENT_DOING.value),
        },
        {
          value: ServcOrdAllStates.FLUX_CLIENT_DONE.id,
          label: i18next.t(ServcOrdAllStates.FLUX_CLIENT_DONE.value),
        },
        {
          value: ServcOrdAllStates.FLUX_CLIENT_PARTIALLY_DONE.id,
          label: i18next.t(ServcOrdAllStates.FLUX_CLIENT_PARTIALLY_DONE.value),
        },
        {
          value: ServcOrdAllStates.FLUX_STORE_WAITING.id,
          label: i18next.t(ServcOrdAllStates.FLUX_STORE_WAITING.value),
        },
      ],
      lsServcOrdStusCd: [
        {
          value: ServcOrdAllStates.FLUX_CLIENT_PRE_SCHEDULE.id,
          label: i18next.t(ServcOrdAllStates.FLUX_CLIENT_PRE_SCHEDULE.value),
        },
        {
          value: ServcOrdAllStates.FLUX_CLIENT_SCHEDULE.id,
          label: i18next.t(ServcOrdAllStates.FLUX_CLIENT_SCHEDULE.value),
        },
        {
          value: ServcOrdAllStates.FLUX_CLIENT_DISTRIBUTED.id,
          label: i18next.t(ServcOrdAllStates.FLUX_CLIENT_DISTRIBUTED.value),
        },
        {
          value: ServcOrdAllStates.FLUX_CLIENT_ASSOCIATED.id,
          label: i18next.t(ServcOrdAllStates.FLUX_CLIENT_ASSOCIATED.value),
        },
        {
          value: ServcOrdAllStates.FLUX_CLIENT_CONFIRMED.id,
          label: i18next.t(ServcOrdAllStates.FLUX_CLIENT_CONFIRMED.value),
        },
        {
          value: ServcOrdAllStates.FLUX_CLIENT_DOING.id,
          label: i18next.t(ServcOrdAllStates.FLUX_CLIENT_DOING.value),
        },
        {
          value: ServcOrdAllStates.FLUX_CLIENT_DONE.id,
          label: i18next.t(ServcOrdAllStates.FLUX_CLIENT_DONE.value),
        },
        {
          value: ServcOrdAllStates.FLUX_CLIENT_PARTIALLY_DONE.id,
          label: i18next.t(ServcOrdAllStates.FLUX_CLIENT_PARTIALLY_DONE.value),
        },
        {
          value: ServcOrdAllStates.FLUX_STORE_WAITING.id,
          label: i18next.t(ServcOrdAllStates.FLUX_STORE_WAITING.value),
        },
      ].map((status) => status.value),
      indAlertServices: true,
      servcOrdStatusTypeHouse: true,
      servcOrdStatusTypeStore: true,
      servcOrdStatusTypeProvider: true,
    },
  },
  {
    id: ALERTS_IDS.TODAY_LATE,
    type: IndicatorType.TODAY_LATE,
    value: 0,
    title: 'services.alert_18',
    subtitle: 'services.alert_18.subtitle.text',
    render: true,
    filters: {
      lsStatus: [
        {
          value: ServiceOrderStatusType.STATUS_AGENDADA.id,
          label: i18next.t('serviceOrders.scheduled'),
        },
        {
          value: ServiceOrderStatusType.STATUS_DISTRIBUIDA.id,
          label: i18next.t('serviceOrders.distributed'),
        },
        {
          value: ServiceOrderStatusType.STATUS_ASSOCIADA.id,
          label: i18next.t('serviceOrders.associated'),
        },
      ],
      lsServcOrdStusCd: [
        {
          value: ServiceOrderStatusType.STATUS_AGENDADA.id,
          label: i18next.t('serviceOrders.scheduled'),
        },
        {
          value: ServiceOrderStatusType.STATUS_DISTRIBUIDA.id,
          label: i18next.t('serviceOrders.distributed'),
        },
        {
          value: ServiceOrderStatusType.STATUS_ASSOCIADA.id,
          label: i18next.t('serviceOrders.associated'),
        },
      ].map((status) => status.value),
      servcOrdSchdlgTsEnd: moment(yesterday).format(),
    },
  },
  {
    id: ALERTS_IDS.ALMOST_LATE_SERVICES,
    type: IndicatorType.ALMOST_LATE_SERVICES,
    value: 0,
    title: 'services.alert_19',
    subtitle: 'services.alert_19.subtitle.text',
    render: true,
    filters: {
      lsStatus: [
        {
          value: ServiceOrderStatusType.STATUS_AGENDADA.id,
          label: i18next.t('serviceOrders.scheduled'),
        },
        {
          value: ServiceOrderStatusType.STATUS_DISTRIBUIDA.id,
          label: i18next.t('serviceOrders.distributed'),
        },
        {
          value: ServiceOrderStatusType.STATUS_ASSOCIADA.id,
          label: i18next.t('serviceOrders.associated'),
        },
      ],
      lsServcOrdStusCd: [
        {
          value: ServiceOrderStatusType.STATUS_AGENDADA.id,
          label: i18next.t('serviceOrders.scheduled'),
        },
        {
          value: ServiceOrderStatusType.STATUS_DISTRIBUIDA.id,
          label: i18next.t('serviceOrders.distributed'),
        },
        {
          value: ServiceOrderStatusType.STATUS_ASSOCIADA.id,
          label: i18next.t('serviceOrders.associated'),
        },
      ].map((status) => status.value),
      servcOrdSchdlgTs: moment(today).format(),
      servcOrdSchdlgTsEnd: moment(tomorrow).format(),
      servcOrdStatusTypeHouse: true,
      servcOrdStatusTypeStore: true,
    },
  },
  {
    id: ALERTS_IDS.ORDERS_WAITING_FOR_MEASUREMENT_SHEET,
    type: IndicatorType.ORDERS_WAITING_FOR_MEASUREMENT_SHEET,
    value: 0,
    title: 'services.alert_20',
    subtitle: 'services.alert_20.subtitle.text',
    render:
      user?.allowedCategories.includes(ServcOrdCategoryType.TECHNICAL_VISIT_PLANNED_FURNITURE.id) ||
      user?.allowedCategories.length === 0,
    filters: {
      indWaitingMeasurementSheet: true,
    },
  },
  {
    id: ALERTS_IDS.ORDERS_WAITING_FOR_MEASUREMENT_SHEET_VALIDATION,
    type: IndicatorType.ORDERS_WAITING_FOR_MEASUREMENT_SHEET_VALIDATION,
    value: 0,
    title: 'services.alert_21',
    subtitle: 'services.alert_21.subtitle.text',
    render:
      user?.allowedCategories.includes(ServcOrdCategoryType.TECHNICAL_VISIT_PLANNED_FURNITURE.id) ||
      user?.allowedCategories.length === 0,
    filters: {
      indValidateMeasurementSheet: true,
    },
  },
  {
    id: ALERTS_IDS.EMERGENCY_EXPIRED,
    type: IndicatorType.EMERGENCY_EXPIRED,
    value: 0,
    title: 'services.alert_15',
    subtitle: 'services.alert_15.subtitle.text',
    render:
      user?.allowedCategories.includes(ServcOrdCategoryType.EMERGENCY_SERVICE.id) ||
      user?.allowedCategories.length === 0,
    filters: {
      lsStatus: [
        {
          value: ServiceOrderStatusType.STATUS_AGENDADA.id,
          label: i18next.t('serviceOrders.preScheduled'),
        },
        {
          value: ServiceOrderStatusType.STATUS_DISTRIBUIDA.id,
          label: i18next.t('serviceOrders.distributed'),
        },
      ],
      lsServcOrdStusCd: [
        {
          value: ServiceOrderStatusType.STATUS_AGENDADA.id,
          label: i18next.t('serviceOrders.preScheduled'),
        },
        {
          value: ServiceOrderStatusType.STATUS_DISTRIBUIDA.id,
          label: i18next.t('serviceOrders.distributed'),
        },
      ].map((status) => status.value),
      lsServcOrdCategory: [
        {
          value: ServcOrdCategoryType.EMERGENCY_SERVICE.id,
          label: i18next.t('serviceOrders.category.type.emergency.service'),
        },
      ],
      lsServcOrdCategoryCd: [
        {
          value: ServcOrdCategoryType.EMERGENCY_SERVICE.id,
          label: i18next.t('serviceOrders.category.type.emergency.service'),
        },
      ].map((category) => category.value),
      emergencyExpired: true,
    },
  },
  {
    id: ALERTS_IDS.BUDGET_PENDING,
    type: IndicatorType.BUDGET_PENDING,
    value: 0,
    title: 'cockpit.service.orders.pending.budget',
    subtitle: 'cockpit.service.orders.pending.budget.subtitle.text',
    render:
      user?.allowedCategories.includes(ServcOrdCategoryType.BUDGET.id) ||
      user?.allowedCategories.length === 0,
    filters: {
      lsStatus: [
        {
          value: ServiceOrderStatusType.STATUS_REALIZADO_COM_SUCESSO.id,
          label: i18next.t('serviceOrders.done'),
        },
        {
          value: ServiceOrderStatusType.LOJA_REALIZADO.id,
          label: i18next.t('serviceOrders.branch.done'),
        },
      ],
      lsServcOrdStusCd: [
        {
          value: ServiceOrderStatusType.STATUS_REALIZADO_COM_SUCESSO.id,
          label: i18next.t('serviceOrders.done'),
        },
        {
          value: ServiceOrderStatusType.LOJA_REALIZADO.id,
          label: i18next.t('serviceOrders.branch.done'),
        },
      ].map((status) => status.value),
      sosPendingBudget: true,
    },
  },
  {
    id: ALERTS_IDS.WAITING_FOR_QUOTATION,
    type: IndicatorType.WAITING_FOR_QUOTATION,
    value: 0,
    title: 'cockpit.alerts.waiting.quotation',
    subtitle: 'cockpit.alerts.waiting.quotation.subtitle.text',
    render:
      user?.allowedCategories.includes(ServcOrdCategoryType.BUDGET.id) ||
      user?.allowedCategories.length === 0,
    filters: {
      lsStatus: [
        {
          value: ServiceOrderStatusType.STATUS_REALIZADO_COM_SUCESSO.id,
          label: i18next.t('serviceOrders.done'),
        },
        {
          value: ServiceOrderStatusType.LOJA_REALIZADO.id,
          label: i18next.t('serviceOrders.branch.done'),
        },
      ],
      lsServcOrdStusCd: [
        {
          value: ServiceOrderStatusType.STATUS_REALIZADO_COM_SUCESSO.id,
          label: i18next.t('serviceOrders.done'),
        },
        {
          value: ServiceOrderStatusType.LOJA_REALIZADO.id,
          label: i18next.t('serviceOrders.branch.done'),
        },
      ].map((status) => status.value),
      sosWaitingQuotation: true,
    },
  },
  {
    id: ALERTS_IDS.ATTENTION_TO_DELIVERY,
    type: IndicatorType.ATTENTION_TO_DELIVERY,
    value: 0,
    title: 'cockpit.alert.attentionToDelivery.subtitle',
    subtitle: 'cockpit.alert.attentionToDelivery.title',
    render: true,
    filters: {
      attentionToDelivery: true,
    },
  },
  {
    id: ALERTS_IDS.WAITING_BUDGET,
    type: IndicatorType.WAITING_BUDGET,
    value: 0,
    title: 'cockpit.service.orders.waiting.budget.title',
    subtitle: 'cockpit.service.orders.waiting.budget.subtitle',
    render:
      user?.accessFunctionList?.includes(135) &&
      (user?.allowedCategories.includes(
        Number(ServcOrdCategoryType.TECHNICAL_VISIT_HIGH_POTENTIAL.id),
      ) ||
        user?.allowedCategories.length === 0),
    filters: {
      waitingBudget: true,
    },
  },
  {
    id: ALERTS_IDS.RECOMMEND_INSPECTION,
    type: IndicatorType.RECOMMEND_INSPECTION,
    value: 0,
    title: 'cockpit.alert.recommendInspection.title',
    subtitle: 'cockpit.alert.recommendInspection.subtitle',
    render: user.accessFunctionList.includes(137),
    filters: {
      recommendInspection: true,
    },
  },
  {
    id: ALERTS_IDS.NEGATIVE_OV,
    type: IndicatorType.NEGATIVE_OV,
    value: 0,
    title: 'cockpit.alert.negativeOV.title',
    subtitle: 'cockpit.alert.negativeOV.subtitle',
    render: user.accessFunctionList.includes(139),
    filters: {
      negativeOv: true,
    },
  },
]

export const alertsDataComplete = [
  {
    id: 'indicator-17',
    type: 0,
    value: 0,
    title: 'services.alert_17',
    subtitle: 'services.alert_17.subtitle.text',
    render: true,
  },
  {
    id: 'indicator-18',
    type: 0,
    value: 0,
    title: 'services.alert_18',
    subtitle: 'services.alert_18.subtitle.text',
    render: true,
  },
  {
    id: 'indicator-19',
    type: 0,
    value: 0,
    title: 'services.alert_19',
    subtitle: 'services.alert_19.subtitle.text',
    render: true,
  },
  {
    id: 'indicator-20',
    type: 0,
    value: 0,
    title: 'services.alert_20',
    subtitle: 'services.alert_20.subtitle.text',
    render: true,
  },
  {
    id: 'indicator-21',
    type: 0,
    value: 0,
    title: 'services.alert_21',
    subtitle: 'services.alert_21.subtitle.text',
    render: true,
  },
  {
    id: 'indicator-15',
    type: ALERTS_IDS.EMERGENCY_EXPIRED,
    value: COOKPIT_EMERGENCY_EXPIRED,
    title: 'services.alert_15',
    subtitle: 'services.alert_15.subtitle.text',
    render: true,
  },
  {
    id: 'indicator-1',
    type: ALERTS_IDS.COCKIPT_TODAY_LATE,
    value: COCKIPT_TODAY_LATE,
    title: 'cockpit.today.confirmed.and.late',
    subtitle: 'cockpit.today.confirmed.and.late.subtitle.text',
    render: true,
  },
  {
    id: 'indicator-2',
    type: ALERTS_IDS.ALERTS_FOR_SERVICE_COORDINATOR,
    value: COCKPIT_ALERTS_FOR_SERVICE_COORDINATOR,
    title: 'cockpit.alerts.for.service.coordinator',
    subtitle: 'cockpit.alerts.for.service.coordinator.subtitle.text',
    render: true,
  },
  {
    id: 'indicator-3',
    type: ALERTS_IDS.PREVIOUSLY_LATE,
    value: COCKIPT_PREVIOUSLY_LATE,
    title: 'cockpit.all.delayed.services',
    subtitle: 'cockpit.all.delayed.services.subtitle.text',
    render: true,
  },
  {
    id: 'indicator-4',
    type: ALERTS_IDS.WAITING_FOR_EVALUATION,
    value: WAITING_FOR_EVALUATION,
    title: 'cockpit.delayed.satisfaction.surveys',
    subtitle: 'cockpit.delayed.satisfaction.surveys.subtitle.text',
    render: true,
  },
  {
    id: 'indicator-8',
    type: ALERTS_IDS.BUDGET_PEDDING,
    value: BUDGET_PEDDING,
    title: 'cockpit.service.orders.pending.budget',
    subtitle: 'cockpit.service.orders.pending.budget.subtitle.text',
    render: true,
  },
  {
    id: 'indicator-10',
    type: ALERTS_IDS.WAITING_FOR_QUOTATION,
    value: COCKPIT_WAITING_FOR_QUOTATION,
    title: 'cockpit.alerts.waiting.quotation',
    subtitle: 'cockpit.alerts.waiting.quotation.subtitle.text',
    render: true,
  },
  {
    id: 'indicator-11',
    type: ALERTS_IDS.ACCEPTANCE_SERVICE_CONDITIONS,
    value: COCKIPT_ACCEPTANCE_SERVICE_CONDITIONS,
    title: 'cockpit.alerts.acceptance.service.conditions',
    subtitle: 'cockpit.alerts.acceptance.service.conditions.subtitle.text',
    render: true,
  },
  {
    id: 'indicator-12',
    type: ALERTS_IDS.WAITING_MEASUREMENT,
    value: COCKPIT_WAITING_MEASUREMENT,
    title: 'advanced.search.waitingMeasurementSheet',
    subtitle: 'advanced.search.waitingMeasurementSheet.subtitle.text',
    render: true,
  },
  {
    id: 'indicator-13',
    type: ALERTS_IDS.VALIDATE_MEASUREMENT,
    value: COOKPIT_VALIDATE_MEASUREMENT,
    title: 'advanced.search.validateMeasurementSheet',
    subtitle: 'advanced.search.validateMeasurementSheet.subtitle.text',
    render: true,
  },
]

export const serviceOnTimeData = (parameters) => [
  {
    id: ALERTS_IDS.SERVICES_ON_TIME,
    type: IndicatorType.SERVICES_ONTIME,
    value: 0,
    title: 'cockpit.services.on.time',
    subtitle: 'cockpit.services.on.time.subtitle.text',
    render: true,
    filters: {
      servcOnTime: true,
    },
    indicators: [
      {
        type: IndicatorType.SERVICES_ONTIME_CONFIRMED,
        value: 0,
        title: 'cockpit.confirmed.services.title',
        render: true,
        filters: {
          lsStatus: [
            {
              value: ServiceOrderStatusType.STATUS_CONFIRMADA.id,
              label: i18next.t('serviceOrders.confirmed'),
            },
          ],
          lsServcOrdStusCd: [
            {
              value: ServiceOrderStatusType.STATUS_CONFIRMADA.id,
              label: i18next.t('serviceOrders.confirmed'),
            },
          ].map((status) => status.value),
          servcOnTime: true,
        },
      },
      {
        type: IndicatorType.SERVICES_ONTIME_IN_PROGRESS,
        value: 0,
        title: 'cockpit.services.in.progress.title',
        render: true,
        filters: {
          lsStatus: [
            {
              value: ServiceOrderStatusType.STATUS_EM_REALIZACAO.id,
              label: i18next.t('serviceOrders.done'),
            },
          ],
          lsServcOrdStusCd: [
            {
              value: ServiceOrderStatusType.STATUS_EM_REALIZACAO.id,
              label: i18next.t('serviceOrders.done'),
            },
          ].map((status) => status.value),
          servcOnTime: true,
        },
      },
      {
        type: IndicatorType.OPEN_REWORK,
        value: 0,
        title: 'cockpit.rework',
        render: !parameters.hideCockpitAlerts,
        filters: {
          lsStatus: [
            {
              value: ServiceOrderStatusType.STATUS_PRE_AGENDADA.id,
              label: i18next.t(ServiceOrderStatusType.STATUS_PRE_AGENDADA.value),
            },
            {
              value: ServiceOrderStatusType.STATUS_AGENDADA.id,
              label: i18next.t(ServiceOrderStatusType.STATUS_AGENDADA.value),
            },
            {
              value: ServiceOrderStatusType.STATUS_ASSOCIADA.id,
              label: i18next.t(ServiceOrderStatusType.STATUS_ASSOCIADA.value),
            },
            {
              value: ServiceOrderStatusType.STATUS_CONFIRMADA.id,
              label: i18next.t(ServiceOrderStatusType.STATUS_CONFIRMADA.value),
            },
            {
              value: ServiceOrderStatusType.STATUS_DISTRIBUIDA.id,
              label: i18next.t(ServiceOrderStatusType.STATUS_DISTRIBUIDA.value),
            },
            {
              value: ServiceOrderStatusType.STATUS_EM_REALIZACAO.id,
              label: i18next.t(ServiceOrderStatusType.STATUS_EM_REALIZACAO.value),
            },
            {
              value: ServiceOrderStatusType.STATUS_REALIZADO_COM_SUCESSO.id,
              label: i18next.t(ServiceOrderStatusType.STATUS_REALIZADO_COM_SUCESSO.value),
            },
            {
              value: ServiceOrderStatusType.STATUS_REALIZADO_PARCIALMENTE.id,
              label: i18next.t(ServiceOrderStatusType.STATUS_REALIZADO_PARCIALMENTE.value),
            },
          ],
          lsServcOrdStusCd: [
            {
              value: ServiceOrderStatusType.STATUS_PRE_AGENDADA.id,
              label: i18next.t(ServiceOrderStatusType.STATUS_PRE_AGENDADA.value),
            },
            {
              value: ServiceOrderStatusType.STATUS_AGENDADA.id,
              label: i18next.t(ServiceOrderStatusType.STATUS_AGENDADA.value),
            },
            {
              value: ServiceOrderStatusType.STATUS_ASSOCIADA.id,
              label: i18next.t(ServiceOrderStatusType.STATUS_ASSOCIADA.value),
            },
            {
              value: ServiceOrderStatusType.STATUS_CONFIRMADA.id,
              label: i18next.t(ServiceOrderStatusType.STATUS_CONFIRMADA.value),
            },
            {
              value: ServiceOrderStatusType.STATUS_DISTRIBUIDA.id,
              label: i18next.t(ServiceOrderStatusType.STATUS_DISTRIBUIDA.value),
            },
            {
              value: ServiceOrderStatusType.STATUS_EM_REALIZACAO.id,
              label: i18next.t(ServiceOrderStatusType.STATUS_EM_REALIZACAO.value),
            },
            {
              value: ServiceOrderStatusType.STATUS_REALIZADO_COM_SUCESSO.id,
              label: i18next.t(ServiceOrderStatusType.STATUS_REALIZADO_COM_SUCESSO.value),
            },
            {
              value: ServiceOrderStatusType.STATUS_REALIZADO_PARCIALMENTE.id,
              label: i18next.t(ServiceOrderStatusType.STATUS_REALIZADO_PARCIALMENTE.value),
            },
          ].map((status) => status.value),
          indRunningRework: true,
        },
      },
    ],
  },
]
