import { Tabs } from '../../../../../leroy-merlin-br/backyard-react'
import i18next from 'i18next'
import { ReactNode, memo, useMemo } from 'react'
import { BaseModal } from 'react-spring-modal'
import { useSideNavContext } from '../../../../contexts/sidenav'
import { RIGHT_MODAL_NAVBAR } from '../ProfileModal'
import ClustersList from './components/ClustersList'
import StoresList from './components/StoresList'

const tabItems = () =>
  [
    {
      id: '0',
      label: i18next.t('branches.text'),
      panel: <StoresList />,
    },
    {
      id: '1',
      label: i18next.t('lmi.cores'),
      panel: <ClustersList />,
    },
  ] as { id: string; label: string; panel: ReactNode }[]

function StoreClustersInfo() {
  const { openedStores, handleCloseStores } = useSideNavContext() as any

  const TabsItems = useMemo(() => tabItems(), [tabItems])

  return (
    <BaseModal
      isOpen={openedStores}
      onDismiss={() => handleCloseStores()}
      contentTransition={{
        from: { transform: 'translateX(100%)' },
        enter: { transform: 'translateX(0)' },
        leave: { transform: 'translateX(100%)' },
      }}
      contentProps={{ style: RIGHT_MODAL_NAVBAR as any }}
    >
      <div className='store-clusters-info'>
        <Tabs items={TabsItems} defaultSelectedId='0' />
      </div>
    </BaseModal>
  )
}

export default memo(StoreClustersInfo)
