import i18next from 'i18next'
import { TimesCircle } from '../../../leroy-merlin-br/backyard-icons'

import { Link } from 'react-router-dom'
import MozaicText from '../../components/MozaicText'

/*
  Description component: container used when user no has permission to access current path route
*/
function NotPermission() {
  return (
    <section id='not-permission-container' className='container'>
      <div className='col-sm-10 col-sm-offset-1 text-center content'>
        <header className='title'>
          <h1 className='text-center'>{i18next.t('ops.text')}</h1>

          <TimesCircle height={150} width={150} color='#41a017' />
        </header>

        <section className='box'>
          <h3>{i18next.t('access.danied.text')}</h3>

          <MozaicText>{i18next.t('access.danied.helper.text')}</MozaicText>

          <Link to='/' className='link-go-to-home'>
            {i18next.t('go.to.home.text')}
          </Link>
        </section>
      </div>
    </section>
  )
}
export default NotPermission
