import { ComponentClass, memo, useMemo } from 'react'
import { useAdvancedSearchContext } from '../../../contexts/advancedSearch'
import { useInstallerContext } from '../../../contexts/installer'
import { useProviderContext } from '../../../contexts/provider'

export function WithContext(Component: ComponentClass) {
  const MemoComponent = memo(Component)

  return function (props: any) {
    const { filters, hasFilters, handleSubmitDefault } = useAdvancedSearchContext()

    const { fetchServiceSpecialistAutocomplete } = useInstallerContext()

    const { fetchServiceProviderNameAutocomplete } = useProviderContext()

    const newProps = useMemo(
      () => ({
        filters,
        hasFilters,
        handleSubmitDefault,
        fetchServiceSpecialistNameAutocomplete: fetchServiceSpecialistAutocomplete,
        fetchServiceProviderNameAutocomplete,
      }),
      [filters, hasFilters],
    )

    return <MemoComponent {...newProps} {...props} />
  }
}
