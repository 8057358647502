import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { Route } from 'react-router-dom'
import Spinner from '../components/utils/Spinner'
import permissions from '../constants/permissions'
import User from '../core/User'
import history from '../helpers/history'
import store from '../redux/store'

const user = new User()

function PrivateRoute({ component, location: { pathname }, ...rest }) {

  const [authorized, setAuthorized] = useState(false)
  const auth = store.getState().auth
  const { isAuthenticated, isAuthenticating } = auth

  async function handleCheckScreenPermission() {
    const accessFunctionList = user.currentUser.accessFunctionList

    const permissionItem = permissions?.find((permission) => {
      if (permission.dynamic) return pathname.includes(permission.path)
      return pathname === permission.path
    })

    const checkAll = permissionItem?.accessFunctions?.every((screenAccessFunction) =>
      accessFunctionList?.includes(screenAccessFunction),
    )

    const checkUnique = permissionItem?.accessFunctions?.some((screenAccessFunction) =>
      accessFunctionList?.includes(screenAccessFunction),
    )

    const existsPermissionToAccessScreen = permissionItem?.unique ? checkUnique : checkAll

    if (permissionItem && !existsPermissionToAccessScreen) {
      history.push('/no-permissions')
    } else {
      setAuthorized(true)
    }
  }

  useEffect(() => {
    if (!user.currentUser?.username) window.location.href = '/login'
  }, [])

  useEffect(() => {
    if (isAuthenticated) handleCheckScreenPermission()
  }, [isAuthenticated])

  useEffect(() => {
    localStorage.setItem('lastRouteAccessed', location.pathname + location.search)
  }, [location.pathname, location.search])

  if (isAuthenticating || !isAuthenticated || !authorized) return <Spinner visible />

  return <Route {...rest} auth={auth} component={component} />
}

PrivateRoute.propTypes = {
  component: PropTypes.any,
  location: PropTypes.object,
}

export default PrivateRoute
