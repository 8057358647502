import i18next from 'i18next'
import { useRef, useState } from 'react'
import User from '../../../../../../core/User'
import store from '../../../../../../redux/store'

import DotsButton from '../../../../../../components/DotsButton'
import OutlineButton from '../../../../../../components/OutlineButton'

import useOutsideClick from '../../../../../../hooks/useOutsideClick'
import { fetchReportsPost } from '../../../../../../services/Reports'
import { useServiceOrderDetailContext } from '../../../../context'

import ServiceOrderStatusType from '../../../../../../enums/ServiceOrder/status/ServiceOrderStatusType'
import ServiceOrderTagType from '../../../../../../enums/ServiceOrder/tags/ServiceOrderTagType'

import MozaicIcon from '../../../../../../components/MozaicIcon'
import ServcOrdEvntStusType from '../../../../../../enums/ServiceOrder/event/ServcOrdEvntStusType'
import ocurrencesType from '../../../../../../enums/ocurrencesType'
import { isTechVisit } from '../../../../../../helpers/isTechVisit'
import AssignToResponsibleModal from './AssignToResponsibleModal'
import AttachBudgetModal from './AttachBudgetModal'
import AttachInspectionModal from './AttachInspectionModal'
import CancelModal from './CancelModal'
import CoordinatorAttentionModal from './CoordinatorAttModal'
import ManualDistributionModal from './ManualDistributeModal'
import SalesErrorModal from './SalesErrorModal'

const user = new User().currentUser

const OUT_FLOW_AWAITING = 1

type Action = {
  label: string
  action: () => void
}

function PopupMenu() {
  const {
    serviceOrderBody: serviceOrder,
    serviceOrder: oldOS,
    occurrences,
    tags,
  } = useServiceOrderDetailContext()

  const setupParameters = store.getState().auth.setupParameters

  const [showMenu, setShowMenu] = useState<boolean>(false)
  const [showCoordinatorModal, setShowCoordinatorModal] = useState<boolean>(false)
  const [showManualDistModal, setShowManualDistModal] = useState<boolean>(false)
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false)
  const [showAssignToResponsibleModal, setShowAssignToResponsibleModal] = useState<boolean>(false)
  const [showAttachBudgetModal, setShowAttachBudgetModal] = useState<boolean>(false)
  const [showInspectionModal, setShowInspectionModal] = useState<boolean>(false)
  const [showSalesErrorModal, setShowSalesErrorModal] = useState<boolean>(false)

  const wrapperRef = useRef(null)
  useOutsideClick(wrapperRef, () => setShowMenu(false))

  const isFinished =
    serviceOrder &&
    [
      ServiceOrderStatusType.STATUS_REALIZADO_COM_SUCESSO.id,
      ServiceOrderStatusType.STATUS_ENCERRADO.id,
    ].includes(serviceOrder.servcOrdStusCd)

  const shouldBeTechVisit = isTechVisit(serviceOrder?.servcCatgryTypCd)

  const hasNoBudget = !occurrences.some(
    (ocurrence: any) => ocurrence.servcOrdEvntStusInd === ocurrencesType.BUDGET_SENDED,
  )

  const hasOccurrenceStatus = (status: string): boolean =>
    !!occurrences.find((o) => o.servcOrdEvntStusInd === status)

  const hasFunction = (functionId: number): boolean => user.accessFunctionList.includes(functionId)

  const hasTag = (tag: ServiceOrderTagType): boolean =>
    !!tags.find((t) => t.labelKey === tag.translation)

  const downloadPDF = () =>
    fetchReportsPost({
      tpReport: 1,
      filters: {
        servcOrdSeq: serviceOrder?.servcOrdSeq,
      },
    })

  const notFinishedYet =
    serviceOrder &&
    ![
      ServiceOrderStatusType.STATUS_ENCERRADO.id,
      ServiceOrderStatusType.STATUS_CANCELADA.id,
      ServiceOrderStatusType.NAO_CONFORME.id,
    ].includes(serviceOrder?.servcOrdStusCd)

  const almostFinished =
    serviceOrder &&
    ![
      ServiceOrderStatusType.STATUS_ENCERRADO.id,
      ServiceOrderStatusType.STATUS_CANCELADA.id,
      ServiceOrderStatusType.NAO_CONFORME.id,
      ServiceOrderStatusType.OUT_OF_FLOW.id,
    ].includes(serviceOrder?.servcOrdStusCd)

  const allowedStores = []
  const storeIncluded = allowedStores.includes(serviceOrder?.plntCd) || !allowedStores.length
  const highRiskOS = oldOS?.servcCategory === 'HIGH_RISK' && storeIncluded

  /** @deprecated */
  const lastAttentionOcurrence = occurrences.find((ocurrence) =>
    [ServcOrdEvntStusType.ATTENTION.id, ServcOrdEvntStusType.REMOVED_ATTENTION.id].includes(
      ocurrence.servcOrdEvntStusInd,
    ),
  )

  /** @deprecated */
  const hasAttentionTag =
    lastAttentionOcurrence &&
    lastAttentionOcurrence.servcOrdEvntStusInd === ServcOrdEvntStusType.ATTENTION.id

  const buttonsRules = {
    coordinatorAttention: () => hasFunction(55) && !hasAttentionTag && almostFinished,
    removeCoordinatorAttention: () => hasFunction(55) && hasAttentionTag,
    getPdf: () => hasFunction(25),
    manualDistribution: () =>
      hasFunction(97) &&
      !hasTag(ServiceOrderTagType.MANUAL_DISTRIBUTED) &&
      (serviceOrder?.servcOrdStusCd === ServiceOrderStatusType.STATUS_PRE_AGENDADA.id ||
        serviceOrder?.servcOrdStusCd === ServiceOrderStatusType.STATUS_AGENDADA.id),
    attachInspection: () => hasFunction(137) && highRiskOS && notFinishedYet,
    assignToResponsible: () => hasFunction(136) && hasTag(ServiceOrderTagType.WAITING_RESPONSIBLE),
    budgetModal: () => hasFunction(135) && shouldBeTechVisit && isFinished && hasNoBudget,
    salesError: () => hasFunction(141) && !hasTag(ServiceOrderTagType.SALES_ERROR),
    cancel: () => {
      const isCancelable =
        serviceOrder?.servcOrdStusCd !== ServiceOrderStatusType.STATUS_CANCELADA.id &&
        serviceOrder?.servcOrdStusCd !== ServiceOrderStatusType.STATUS_ENCERRADO.id &&
        serviceOrder?.servcOrdStusCd !== ServiceOrderStatusType.LOJA_ENCERRADO.id &&
        serviceOrder?.idOutFlowProcess !== OUT_FLOW_AWAITING

      const isInstallationPlusVisit =
        hasFunction(131) && shouldBeTechVisit && hasOccurrenceStatus('94')

      return (isCancelable && hasFunction(63)) || (isCancelable && isInstallationPlusVisit)
    },
  }

  const buttons = [
    buttonsRules.coordinatorAttention() && {
      label: i18next.t('serviceOrder.popupMenu.coordinatorAttention'),
      action: () => setShowCoordinatorModal(true),
    },
    buttonsRules.removeCoordinatorAttention() && {
      label: i18next.t('serviceOrder.popupMenu.removeCoordinatorAttention'),
      action: () => setShowCoordinatorModal(true),
    },
    buttonsRules.budgetModal() && {
      label: i18next.t('serviceOrder.context.menu.attach.budget'),
      action: () => setShowAttachBudgetModal(true),
    },
    buttonsRules.getPdf() && {
      label: i18next.t('serviceOrder.popupMenu.getPdf'),
      action: downloadPDF,
    },
    buttonsRules.attachInspection() && {
      label: i18next.t('serviceOrder.context.menu.attach.inspection'),
      action: () => setShowInspectionModal(true),
    },
    buttonsRules.manualDistribution() && {
      label: i18next.t('serviceOrder.popupMenu.manualDistribution'),
      action: () => {
        setShowManualDistModal(true)
      },
    },
    buttonsRules.assignToResponsible() && {
      label: i18next.t('serviceOrder.popupMenu.assignToResponsible'),
      action: () => setShowAssignToResponsibleModal(true),
    },
    buttonsRules.salesError() && {
      label: i18next.t('access.function141'),
      action: () => setShowSalesErrorModal(true),
    },
    buttonsRules.cancel() && {
      label: i18next.t('serviceOrder.popupMenu.cancel'),
      action: () => setShowCancelModal(true),
    },
  ].filter(Boolean) as Array<Action>

  const showMenuClass = showMenu ? 'show-menu' : 'hide-menu'

  return (
    <div id='popup-menu'>
      <DotsButton onClickDotsButton={() => setShowMenu(!showMenu)} />

      {showMenu && (
        <div className={`popup-actions ${showMenuClass}`} ref={wrapperRef}>
          <header>
            <span>Ações</span>
            <MozaicIcon
              icon='ControlCross16'
              className='close-icon'
              onClick={() => setShowMenu(false)}
            />
          </header>
          <div className='body'>
            {serviceOrder &&
              buttons.map((b) => (
                <OutlineButton classname='action-button' onClick={b.action} key={b.label}>
                  {b.label}
                </OutlineButton>
              ))}
          </div>
        </div>
      )}

      <CoordinatorAttentionModal
        hasAttentionTag={hasAttentionTag as boolean}
        show={showCoordinatorModal}
        setShow={setShowCoordinatorModal}
      />
      <ManualDistributionModal show={showManualDistModal} setShow={setShowManualDistModal} />
      <CancelModal show={showCancelModal} setShow={setShowCancelModal} />
      <AssignToResponsibleModal
        show={showAssignToResponsibleModal}
        setShow={setShowAssignToResponsibleModal}
      />
      <AttachBudgetModal show={showAttachBudgetModal} setShow={setShowAttachBudgetModal} />
      <AttachInspectionModal show={showInspectionModal} setShow={setShowInspectionModal} />
      <SalesErrorModal show={showSalesErrorModal} setShow={setShowSalesErrorModal} />
    </div>
  )
}

export default PopupMenu
