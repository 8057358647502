import i18next from 'i18next'
import ReactHtmlParser from 'react-html-parser'
import User from '../core/User'

import BudgetImg from '../assets/icons/budget.svg'
import FilledHouseImg from '../assets/icons/filled-house.svg'
import InstallersCPF from '../assets/icons/installersCPF.svg'
import OSRegistrationImg from '../assets/icons/os-registration.svg'
import PanelBudgets from '../assets/icons/panelBudgets.svg'
import ProvidersCNPJ from '../assets/icons/providersCNPJ.svg'
import QlikSense from '../assets/icons/qlik-sense.svg'
import store from '../redux/store'

const user = new User().currentUser

const generateMenuItems = () => {
  const parameters = store.getState().auth.setupParameters
  const hasAccessFunction = (accessFunction) => {
    if (user?.accessFunctionList) {
      const accessFunctionList = Array.isArray(accessFunction) ? accessFunction : [accessFunction]

      return accessFunctionList?.some((access) =>
        user?.accessFunctionList?.includes(parseInt(access, 10)),
      )
    }
    return false
  }

  const { menuCustom1, menuCustom2, menuCustom3 } = parameters

  return [
    {
      label: i18next.t('lmi.cockpit.v3'),
      url: '/cockpit',
      icon: FilledHouseImg,
      render: hasAccessFunction(130),
    },
    {
      label: i18next.t('qlik.description'),
      url: parameters.qlikSenseURL,
      icon: QlikSense,
      render: hasAccessFunction(28) && parameters.qlikSenseURL,
      newTab: true,
    },
    {
      label: i18next.t('lmi.budget'),
      url: '/budgets',
      icon: BudgetImg,
      render: hasAccessFunction(85),
    },
    {
      label: i18next.t('lmi.payments'),
      url: '/payments',
      icon: PanelBudgets,
      render: hasAccessFunction(31),
    },
    {
      label: i18next.t('lmi.serviceProviders'),
      url: '/providers',
      queryParams: '?actvServcPrvdrInd=1',
      icon: ProvidersCNPJ,
      render: hasAccessFunction([18, 19]),
    },
    {
      label: i18next.t('lmi.serviceSpecialists'),
      url: '/installers',
      queryParams: '?active=1&receivingOrder=1',
      icon: InstallersCPF,
      render: hasAccessFunction([20, 21]),
    },
    {
      label: i18next.t('menu.custom.1.title'),
      icon: i18next.t('menu.custom.1.icon') ? ReactHtmlParser(i18next.t('menu.custom.1.icon')) : '',
      url: menuCustom1,
      newTab: true,
      render: !!menuCustom1,
    },
    {
      label: i18next.t('menu.custom.2.title'),
      icon: i18next.t('menu.custom.2.icon') ? ReactHtmlParser(i18next.t('menu.custom.2.icon')) : '',
      url: menuCustom2,
      newTab: true,
      render: !!menuCustom2,
    },
    {
      label: i18next.t('menu.custom.3.title'),
      icon: i18next.t('menu.custom.3.icon') ? ReactHtmlParser(i18next.t('menu.custom.3.icon')) : '',
      url: menuCustom3,
      newTab: true,
      render: !!menuCustom3,
    },
  ]
}

export default generateMenuItems
