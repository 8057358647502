import i18next from 'i18next'

import { toast } from '../../../../leroy-merlin-br/backyard-react'
import { removeFromCurrentWorksite } from './RemoveFromCurrentWorksite'
import { moveToExistingWorksite } from './MoveToExistingWorksite'

export async function updateWorksite(
  servcOrdSeq: number,
  worksiteMode: string,
  newWorksiteNumber?: number,
) {
  try {
    if (worksiteMode === 'remove') {
      await removeFromCurrentWorksite(servcOrdSeq)

      toast.success('', i18next.t('serviceOrder.worksite.modified'), '')
    }
    if (worksiteMode === 'move') {
      await moveToExistingWorksite(servcOrdSeq, newWorksiteNumber as number)

      toast.success('', i18next.t('serviceOrder.worksite.modified'), '')
    }
  } catch (error: any) {
    toast.error('', error?.message?.dsMessage || i18next.t('dao.save.error'), '')
  }
}
