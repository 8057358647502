import PropTypes from 'prop-types'
import { Component } from 'react'
import Col from '../../../components/utils/Col'
import Field from '../../../components/utils/Field'
import Input from '../../../components/utils/Input'
import Row from '../../../components/utils/Row'
import TelephoneInput from '../../../components/utils/internationalPhoneInput/phoneInput'
import API_URL from '../../../core/Envs/endpoints'
import Api from '../../../core/Http/FetchAdapter'
import STATUS from '../../../enums/ServiceOrder/status/ServiceOrderStatusType'
import {
  getPhoneNumberCountry,
  validatePhoneNumber,
} from '../../../helpers/InternationalPhoneNumberUtils'
import { renderToastError } from '../../../helpers/ToastUtils'
import { formatCEP, getCEPMask, removeMask } from '../../../helpers/masks'
import { getNumberCEP, isEmpty } from '../../../helpers/validations'

class ServiceOrderAlterAttrAndFone extends Component {
  constructor(props) {
    super(props)
    this.state = {
      buttonDisabled: false,
      apiAddress: {},
      loading: false,
      fields: {
        phone1: undefined,
        phone2: undefined,
        phone3: undefined,
      },
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { fields } = props
    const { loading } = state

    const requiredFields = [
      'servcAddrPstlCd',
      'servcAddrStrNm',
      'servcAddrNr',
      'servcAddrCityNm',
      'servcAddrDstrctNm',
    ]

    const isInvalid = requiredFields.some((field) => isEmpty(fields[field].input.value))

    const atLeastOnePhone = ['phone1', 'phone2', 'phone3']

    const hasAnyEmptyPhone = atLeastOnePhone.some((field) => !isEmpty(state.fields[field] || ''))
    const hasAnyInvalidPhone = !atLeastOnePhone.some(
      (field) => !isEmpty(state.fields[field] || '') && !validatePhoneNumber(state.fields[field]),
    )

    const atLeastFields = [
      'phone1',
      'phone2',
      'phone3',
      'custEmailTxt',
      'servcAddrPstlCd',
      'servcAddrStrNm',
      'servcAddrNr',
      'servcAddrDstrctNm',
      'servcAddrCmplmtryTxt',
      'servcAddrCityNm',
      'servcAddrStCd',
      'cntryNm',
      'servcAddrRefPointDesc',
      'ibgeCode',
    ]

    const isInvalidField = atLeastFields.some((field) => !isEmpty(fields[field].error || ''))

    return {
      buttonDisabled:
        isInvalid || !hasAnyEmptyPhone || isInvalidField || loading || !hasAnyInvalidPhone,
    }
  }

  getFieldValueFromStateOrProps(field) {
    const { fields: propsFields } = this.props
    const { fields: stateFields } = this.state

    if (stateFields[field] !== undefined) {
      return stateFields[field]
    }
    return propsFields[field].input.value
  }

  setFieldValueToState(field, value) {
    const { fields: stateFields } = this.state
    const { setFormValue } = this.props

    this.setState({
      fields: {
        ...stateFields,
        [field]: value,
      },
    })

    setFormValue({ [field]: value })
  }

  disableWhen = () => {
    const { serviceOrder } = this.props
    return (
      serviceOrder.servcOrdStusCd === STATUS.STATUS_ENCERRADO.id ||
      serviceOrder.servcOrdStusCd === STATUS.STATUS_CANCELADA.id ||
      serviceOrder.servcOrdStusCd === STATUS.LOJA_ENCERRADO.id
    )
  }

  getPhonePrefixNumber = (input) => (input ? input.substr(0, 2) : '')

  getPhoneNumber = (input) => (input ? input.substr(2) : '')

  handleSubmit = () => {
    const { closeModal, onSubmit, fields } = this.props

    const params = {}
    params.servcAddrStrNm = fields.servcAddrStrNm.input.value
    params.servcAddrNr = fields.servcAddrNr.input.value
    params.servcAddrDstrctNm = fields.servcAddrDstrctNm.input.value
    params.servcAddrCmplmtryTxt = fields.servcAddrCmplmtryTxt.input.value
    params.servcAddrRefPointDesc = fields.servcAddrRefPointDesc.input.value
    params.servcAddrCityNm = fields.servcAddrCityNm.input.value
    params.servcAddrStCd = fields.servcAddrStCd.input.value
    params.cntryNm = fields.cntryNm.input.value
    params.custEmailTxt = fields.custEmailTxt.input.value
    params.ibgeCode = fields.ibgeCode.input.value

    if (fields.servcAddrPstlCd.input.value) {
      params.servcAddrPstlCd = removeMask(fields.servcAddrPstlCd.input.value)
    }

    const phone1Value = this.getFieldValueFromStateOrProps('phone1')
    const phone2Value = this.getFieldValueFromStateOrProps('phone2')
    const phone3Value = this.getFieldValueFromStateOrProps('phone3')

    fields.phone1.input.value = phone1Value || ''
    params.custMobilePhonNr = phone1Value

    fields.phone2.input.value = phone2Value || ''
    params.custBusnsPhonNr = phone2Value

    fields.phone3.input.value = phone3Value || ''
    params.custHmPhonNr = phone3Value

    params.updateIdb = true

    params.changeClientData = true

    onSubmit(params)
    closeModal()
  }

  componentDidMount() {
    const { setFormValue, fields, serviceOrder, t, auth } = this.props
    const { fields: stateFields } = this.state

    let phone1Value = ''
    let phone2Value = ''
    let phone3Value = ''

    if (
      fields.custMobilePhonNr != null &&
      !(fields.phone1.input.value === 0 || isEmpty(serviceOrder.custMobilePhonNr))
    ) {
      phone1Value = serviceOrder.custMobilePhonNr
    }
    if (
      fields.custBusnsPhonNr != null &&
      !(fields.phone2.input.value === 0 || isEmpty(serviceOrder.custBusnsPhonNr))
    ) {
      phone2Value = serviceOrder.custBusnsPhonNr
    }
    if (
      fields.custHmPhonNr != null &&
      !(fields.phone3.input.value === 0 || isEmpty(serviceOrder.custHmPhonNr))
    ) {
      phone3Value = serviceOrder.custHmPhonNr
    }

    this.setState({
      fields: {
        ...stateFields,
        phone1: phone1Value,
        phone2: phone2Value,
        phone3: phone3Value,
      },
    })

    setFormValue({
      phone1: phone1Value,
      phone2: phone2Value,
      phone3: phone3Value,
      custEmailTxt: isEmpty(serviceOrder.custEmailTxt) ? '' : serviceOrder.custEmailTxt,
      servcAddrPstlCd: isEmpty(serviceOrder.servcAddrPstlCd)
        ? ''
        : formatCEP(serviceOrder.servcAddrPstlCd, t),
      servcAddrStrNm: isEmpty(serviceOrder.servcAddrStrNm) ? '' : serviceOrder.servcAddrStrNm,
      servcAddrNr: isEmpty(serviceOrder.servcAddrNr) ? '' : serviceOrder.servcAddrNr,
      servcAddrDstrctNm: isEmpty(serviceOrder.servcAddrDstrctNm)
        ? ''
        : serviceOrder.servcAddrDstrctNm,
      servcAddrCmplmtryTxt: isEmpty(serviceOrder.servcAddrCmplmtryTxt)
        ? ''
        : serviceOrder.servcAddrCmplmtryTxt,
      servcAddrRefPointDesc: isEmpty(serviceOrder.servcAddrRefPointDesc)
        ? ''
        : serviceOrder.servcAddrRefPointDesc,
      servcAddrCityNm: isEmpty(serviceOrder.servcAddrCityNm) ? '' : serviceOrder.servcAddrCityNm,
      servcAddrStCd: isEmpty(serviceOrder.servcAddrStCd) ? '' : serviceOrder.servcAddrStCd,
      cntryNm: isEmpty(serviceOrder.cntryNm) ? '' : serviceOrder.cntryNm,
      ibgeCode: isEmpty(serviceOrder.ibgeCode) ? '' : serviceOrder.ibgeCode,
    })

    if (serviceOrder.servcAddrPstlCd && auth.setupParameters.enableSearchCEPApi) {
      this.searchPostalCode(serviceOrder.servcAddrPstlCd, false)
    }
  }

  checkPhoneNumberValidity = (phoneNumberField) => {
    /**
     * Checks if each phone number is a valid number and if there are no repeated phone numbers.
     *
     * @param phoneNumberField The phone number field for validation: `phone1`, `phone2` or `phone3`
     *
     * @returns a translated error message or empty string.
     */

    const { t } = this.props

    const currentName = phoneNumberField.input.name

    const currentValue = this.getFieldValueFromStateOrProps(currentName)

    const phone1Value = this.getFieldValueFromStateOrProps('phone1')
    const phone2Value = this.getFieldValueFromStateOrProps('phone2')
    const phone3Value = this.getFieldValueFromStateOrProps('phone3')

    if (
      (currentName === 'phone1' &&
        !!currentValue &&
        !(
          validatePhoneNumber(currentValue) && ![phone2Value, phone3Value].includes(currentValue)
        )) ||
      (currentName === 'phone2' &&
        !!currentValue &&
        !(
          validatePhoneNumber(currentValue) && ![phone1Value, phone3Value].includes(currentValue)
        )) ||
      (currentName === 'phone3' &&
        !!currentValue &&
        !(validatePhoneNumber(currentValue) && ![phone1Value, phone2Value].includes(currentValue)))
    ) {
      return t('error.customer.invalid.phone')
    }

    return ''
  }

  handleCheckValidPhone = (field) => {
    this.setState({ loading: true })

    const errorValue = this.checkPhoneNumberValidity(field)

    // eslint-disable-next-line no-param-reassign
    field.error = errorValue !== '' ? errorValue : null

    this.setState({ loading: false })
  }

  handleCheckValidEmail = () => {
    const { t, dispatch, fields } = this.props

    this.setState({ loading: true })
    if (fields.custEmailTxt.input.value) {
      return new Api(null, dispatch)
        .get(API_URL.PUBLIC_SERVICE_ORDER_EMAIL_CHECK, {
          email: fields.custEmailTxt.input.value,
        })
        .then(
          (response) => {
            const { status } = response.data
            if (status === 'invalid') {
              fields.custEmailTxt.error = t('error.customer.invalid.email')
            } else {
              fields.custEmailTxt.error = null
            }
            this.setState({ loading: false })
          },
          () => {
            fields.custEmailTxt.error = t('error.customer.invalid.email')
            this.setState({ loading: false })
          },
        )
    }
    this.setState({ loading: false })
  }

  searchPostalCode = (postalCode, updateValue) => {
    const { dispatch, t, fields, setFormValue } = this.props

    this.setState({ loading: true })
    return new Api(null, dispatch)
      .get(API_URL.PUBLIC_SERVICE_PROVIDER_POSTAL_CODE, {
        postalCode: postalCode.replace('-', ''),
      })
      .then(
        (response) => {
          if (response.data && response.data.dne.length > 0 && response.data.dne[0].IBGEcode) {
            const apiAddress = response.data.dne[0]

            Object.entries(apiAddress).forEach(([key, value]) => (apiAddress[key] = value.trim()))

            if (updateValue) {
              if (apiAddress.streetName) fields.servcAddrStrNm.input.value = apiAddress.streetName
              else fields.servcAddrStrNm.input.value = ''
              if (apiAddress.neighborhood)
                fields.servcAddrDstrctNm.input.value = apiAddress.neighborhood
              else fields.servcAddrDstrctNm.input.value = ''
              if (apiAddress.city) fields.servcAddrCityNm.input.value = apiAddress.city
              else fields.servcAddrCityNm.input.value = ''
              if (apiAddress.state) fields.servcAddrStCd.input.value = apiAddress.state
              else fields.servcAddrStCd.input.value = ''
              if (apiAddress.IBGEcode) fields.ibgeCode.input.value = apiAddress.IBGEcode
              else fields.ibgeCode.input.value = ''
            }

            this.setState({ apiAddress, loading: false })
          } else {
            this.setState({ loading: false })
          }
        },
        () => {
          this.setState({ apiAddress: {}, loading: false })
          fields.servcAddrPstlCd.input.value = ''
          fields.servcAddrStrNm.input.value = ''
          fields.servcAddrDstrctNm.input.value = ''
          fields.servcAddrCityNm.input.value = ''
          fields.servcAddrStCd.input.value = ''
          fields.ibgeCode.input.value = ''
          setFormValue({ servcAddrPstlCd: '' })
          renderToastError(t('customer.service.order.address.edit.invalid.postal.code'))
        },
      )
  }

  handlePostalCodeChange = (postalCode) => {
    const { t, auth } = this.props
    const country = t('country.code')

    if (
      postalCode &&
      auth.setupParameters.enableSearchCEPApi &&
      String(postalCode).length === getNumberCEP(country)
    ) {
      this.searchPostalCode(postalCode, true)
    }
  }

  render() {
    const { t, closeModal, fields, serviceOrder, auth, register } = this.props

    const { buttonDisabled, apiAddress } = this.state

    return (
      <form onSubmit={this.handleSubmit}>
        <Row className='padding lmi-modal-header'>
          <span>{t('serviceOrders.AlterInfo')}</span>
        </Row>

        <Row className='os-modal-content padding padding-bottom-small'>
          <Col lg={4} md={4} sm={8} xs={12}>
            <Field {...fields.phone1} error={this.checkPhoneNumberValidity(fields.phone1)}>
              <TelephoneInput
                {...register('phone1', { required: true })}
                id='custMobilePhonNr'
                name='custMobilePhonNr'
                label={t('serviceOrders.phone1')}
                country={getPhoneNumberCountry(this.getFieldValueFromStateOrProps('phone1'))}
                value={this.getFieldValueFromStateOrProps('phone1')}
                maxLength={18}
                disabled={this.disableWhen()}
                onChange={(value) => this.setFieldValueToState('phone1', value || '')}
                onBlur={() => {
                  this.handleCheckValidPhone(fields.phone1)
                }}
              />
            </Field>
          </Col>

          <Col lg={4} md={4} sm={8} xs={12}>
            <Field {...fields.phone2} error={this.checkPhoneNumberValidity(fields.phone2)} validate>
              <TelephoneInput
                {...register('phone2', { required: true })}
                id='custBusnsPhonNr'
                name='custBusnsPhonNr'
                label={t('serviceOrders.phone2')}
                country={getPhoneNumberCountry(this.getFieldValueFromStateOrProps('phone2'))}
                value={this.getFieldValueFromStateOrProps('phone2')}
                maxLength={18}
                disabled={this.disableWhen()}
                onChange={(value) => this.setFieldValueToState('phone2', value || '')}
                onBlur={() => {
                  this.handleCheckValidPhone(fields.phone2)
                }}
              />
            </Field>
          </Col>

          <Col lg={4} md={4} sm={8} xs={12}>
            <Field {...fields.phone3} error={this.checkPhoneNumberValidity(fields.phone3)} validate>
              <TelephoneInput
                {...register('phone3', { required: true })}
                id='custHmPhonNr'
                name='custHmPhonNr'
                maxLength={18}
                label={t('serviceOrders.phone3')}
                country={getPhoneNumberCountry(this.getFieldValueFromStateOrProps('phone3'))}
                value={this.getFieldValueFromStateOrProps('phone3')}
                disabled={this.disableWhen()}
                onChange={(value) => this.setFieldValueToState('phone3', value || '')}
                onBlur={() => {
                  this.handleCheckValidPhone(fields.phone3)
                }}
              />
            </Field>
          </Col>
        </Row>

        <Row className='os-modal-content padding padding-bottom-small'>
          <Col lg={7} md={7} sm={7} xs={7}>
            <Field {...fields.custEmailTxt}>
              <Input
                {...register('custEmailTxt', { required: true })}
                type='text'
                maxLength={200}
                label={t('serviceOrders.email')}
                onBlur={this.handleCheckValidEmail}
              />
            </Field>
          </Col>

          <Col lg={5} md={5} sm={5} xs={5}>
            <Field {...fields.servcAddrPstlCd}>
              <Input
                {...register('servcAddrPstlCd', {
                  required: true,
                  parser: (e) => {
                    this.handlePostalCodeChange(e.target.value)
                    return e.target.value
                  },
                })}
                type='text'
                maxLength={80}
                label={t('serviceOrders.numCepEndereco')}
                disabled={this.disableWhen()}
                mask={getCEPMask(t)}
              />
            </Field>
          </Col>
        </Row>

        <Row className='os-modal-content padding padding-bottom-small'>
          <Col lg={8} md={8} sm={12} xs={16}>
            <Field {...fields.servcAddrStrNm}>
              <Input
                {...register('servcAddrStrNm', { required: true })}
                type='text'
                maxLength={80}
                label={t('serviceOrders.address')}
                disabled={
                  this.disableWhen() ||
                  (!!apiAddress.streetName && !isEmpty(serviceOrder.servcAddrStrNm))
                }
              />
            </Field>
          </Col>

          <Col lg={4} md={4} sm={8} xs={12}>
            <Field {...fields.servcAddrNr}>
              <Input
                {...register('servcAddrNr', { required: true })}
                type='text'
                label={t('serviceOrders.number')}
                maxLength={10}
                disabled={this.disableWhen()}
              />
            </Field>
          </Col>
        </Row>

        <Row className='os-modal-content padding padding-bottom-small'>
          <Col lg={7} md={7} sm={10} xs={13}>
            <Field {...fields.servcAddrDstrctNm}>
              <Input
                {...register('servcAddrDstrctNm', { required: true })}
                type='text'
                maxLength={80}
                label={t('serviceOrders.descBairroEndereco')}
                disabled={
                  this.disableWhen() ||
                  (!!apiAddress.neighborhood && !isEmpty(serviceOrder.servcAddrDstrctNm))
                }
              />
            </Field>
          </Col>

          <Col lg={5} md={5} sm={7} xs={11}>
            <Field {...fields.servcAddrCmplmtryTxt}>
              <Input
                {...register('servcAddrCmplmtryTxt', { required: true })}
                type='text'
                maxLength={80}
                label={t('serviceOrders.descComplEndereco')}
                disabled={this.disableWhen()}
              />
            </Field>
          </Col>
        </Row>

        <Row className='os-modal-content padding padding-bottom-small'>
          <Col lg={7} md={7} sm={10} xs={13}>
            <Field {...fields.servcAddrCityNm}>
              <Input
                {...register('servcAddrCityNm', { required: true })}
                type='text'
                maxLength={80}
                label={t('serviceOrders.city')}
                disabled={
                  this.disableWhen() ||
                  (!!apiAddress.city && !isEmpty(serviceOrder.servcAddrCityNm))
                }
              />
            </Field>
          </Col>

          <Col lg={5} md={5} sm={7} xs={11}>
            <Field {...fields.servcAddrStCd}>
              <Input
                {...register('servcAddrStCd', { required: true })}
                type='text'
                maxLength={4}
                label={t('serviceOrders.siglEstadoEndereco')}
                disabled={
                  this.disableWhen() || (!!apiAddress.state && !isEmpty(serviceOrder.servcAddrStCd))
                }
              />
            </Field>
          </Col>
        </Row>

        <Row className='os-modal-content padding padding-bottom-small'>
          <Col lg={7} md={7} sm={10} xs={13}>
            <Field {...fields.cntryNm}>
              <Input
                {...register('cntryNm', { required: true })}
                type='text'
                maxLength={40}
                label={t('servcOrd.customer.serviceAddress.country')}
                disabled={this.disableWhen()}
              />
            </Field>
          </Col>

          <Col lg={5} md={5} sm={7} xs={11}>
            <Field {...fields.servcAddrRefPointDesc}>
              <Input
                {...register('servcAddrRefPointDesc', { required: true })}
                type='text'
                maxLength={80}
                label={t('serviceOrders.descReferEndereco')}
                disabled={this.disableWhen()}
              />
            </Field>
          </Col>
        </Row>

        <Row>
          <Col xs={6} md={6} className='button-align'>
            <button
              className='button-hollow button-hollow-danger button-full align-center button-primary'
              type='button'
              onClick={closeModal}
            >
              {t('cancel_button_label')}
            </button>
          </Col>

          <Col xs={6} md={6} className='button-align'>
            <button
              className='button button-primary button-full'
              type='button'
              onClick={this.handleSubmit}
              disabled={buttonDisabled || this.disableWhen()}
            >
              {t('option.salveAlter')}
            </button>
          </Col>
        </Row>
      </form>
    )
  }
}

ServiceOrderAlterAttrAndFone.propTypes = {
  t: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  sendOrder: PropTypes.func.isRequired,
  setFormValue: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  serviceOrder: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  auth: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
}

export default ServiceOrderAlterAttrAndFone
