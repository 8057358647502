import { useState } from 'react'
import {
  fetchServiceProviderNameAutocomplete,
  fetchServiceProviderAutocomplete,
  fetchServiceProviders,
  deleteServiceProvider,
  inactiveProvider,
} from '../../../services/ProviderList'

import { FilterType, InactiveProviderDTO } from '../models'
import ProviderEntity from '../../../models/Provider'

const DEFAULT_FILTER = {
  actvServcPrvdrInd: '1',
  branchs: [],
  receivingServiceOrder: 2,
  servcPrvdrAddRgstrnCurrntStepNr: '',
  lsServcPrvdrMainPlntCd: [],
  requestOriginPortal: 'SERVICES',
}

function ProviderListHandlers() {
  const [providersAutoCompleteList, setProvidersAutoCompleteList] = useState([])
  const [providersList, setProvidersList] = useState<ProviderEntity[]>([])
  const [filters, setFilters] = useState<FilterType | null>(DEFAULT_FILTER)
  const [currentPage, setCurrentPage] = useState(1)

  const handleFetchProviders = async (token: string, fields: any, page = 1) => {
    const { data, query } = await fetchServiceProviders(token, fields, page)

    setProvidersList(data)
    setFilters(query)
    setCurrentPage(page)
  }

  const handleFetchProviderAutocomplete = async (
    token: string | null,
    filters: FilterType,
    servcPrvdrMainPlntCd: number,
    distribution: boolean,
    servcOrdSchdlgTs: string,
    servcOrdSchdlgShiftCd: string,
    defaultValues = [],
    providersOutsideArea: boolean,
  ) => {
    const providers = await fetchServiceProviderAutocomplete(
      token,
      filters,
      servcPrvdrMainPlntCd,
      distribution,
      servcOrdSchdlgTs,
      servcOrdSchdlgShiftCd,
      defaultValues,
      providersOutsideArea,
    )

    setProvidersAutoCompleteList(providers || [])

    return providers
  }

  const handleDeleteProvider = async (token: string, value: any) => {
    await deleteServiceProvider(token, value)

    handleFetchProviders(token, filters, currentPage)
  }

  const handleInactiveProvider = async (token: string, payload: InactiveProviderDTO) => {
    await inactiveProvider(token, payload)

    const newProvidersList = providersList

    newProvidersList[payload.index].actvServcPrvdrInd = payload.actvServcPrvdrInd

    if (payload.actvServcPrvdrInd === '0')
      newProvidersList[payload.index].actvServcPrvdrOrdRecvInd = payload.actvServcPrvdrInd

    setProvidersList(newProvidersList)
  }

  const handleClearProviderList = () => {
    setProvidersAutoCompleteList([])
    setProvidersList([])
    setFilters(DEFAULT_FILTER)
    setCurrentPage(1)
  }

  return {
    filters,
    currentPage,
    providersList,
    providersAutoCompleteList,
    inactiveProvider: handleInactiveProvider,
    handleDeleteProvider,
    handleFetchProviders,
    fetchServiceProviderNameAutocomplete,
    fetchServiceProviderAutocomplete: handleFetchProviderAutocomplete,
    handleClearProviderList,
  }
}

export default ProviderListHandlers
