import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Redirect, Route, Router, Switch } from 'react-router-dom'

import * as authActions from '../redux/actions/auth'

import Home from '../pages/Home'

import Login from '../pages/Login'

import ServcPrvdrAgntTmlineEvnt from '../pages/ServcPrvdrAgntTmlineEvnt'

import ServcPrvdrTmlineEvnt from '../pages/ServcPrvdrTmlineEvnt'

import ServiceOrderList from '../pages/ServiceOrder/index'
import ServiceOrderDetail from '../pages/ServiceOrderDetail'
import ServiceOrderEdit from '../pages/ServiceOrderEdit'

import ServiceOrderDownloadFiles from '../pages/ServiceOrderDownloadFiles'

import ServiceProviderEdit from '../pages/ServiceProviderEdit'
import ServiceProvidersListV2 from '../pages/ServiceProvidersListV2'

import ServiceSpecialistEdit from '../pages/ServiceSpecialistEdit'

import DashboardBudget from '../pages/budgets'

import Cockpit from '../pages/Cockpit'

import NotFoundContainer from '../pages/NotFound'
import NotPermission from '../pages/NotPermission'

import InstallersList from '../pages/InstallersList'

import history from '../helpers/history'
import PingAuth from '../pages/PingOne/components/Authorization'

import User from '../core/User'
import PrivateLayout from '../layout'
import PrivateRoute from './Private'
import PublicRoute from './Public'

import { checkRedirectUrl } from '../redux/actions/auth'

const user = new User()

function Root() {
  return (
    <Router history={history}>
      <div>
        <Switch>
          <Route exact path='/' render={() => <Redirect to={checkRedirectUrl()} />} />
          <PublicRoute exact path='/login' component={Login} />
          <PublicRoute exact path='/no-permissions' component={NotPermission} />
          <PublicRoute exact path='/ping-auth' component={PingAuth} />
          <PublicRoute
            exact
            path='/serviceOrder/download-report-files/:servcOrdCd'
            component={ServiceOrderDownloadFiles}
          />

          {user.currentUser?.username && (
            <PrivateLayout>
              <PrivateRoute exact path='/home' component={Home} />

              {/* Service Order */}
              <PrivateRoute exact path='/serviceOrder' component={ServiceOrderList} />
              <PrivateRoute
                exact
                path='/serviceOrder/edit/:servcOrdCd'
                component={ServiceOrderEdit}
              />
              <PrivateRoute
                path='/serviceOrder/detail/:servcOrdCd'
                component={ServiceOrderDetail}
              />

              {/* Service Provider */}
              <PrivateRoute exact path='/providers' component={ServiceProvidersListV2} />
              <PrivateRoute exact path='/providers/new' component={ServiceProviderEdit} />
              <PrivateRoute exact path='/providers/edit/:id' component={ServiceProviderEdit} />
              <PrivateRoute exact path='/providers/view/:id' component={ServiceProviderEdit} />

              {/* Budget */}
              <PrivateRoute exact path='/budgets' component={DashboardBudget} />

              {/* Installers */}
              <PrivateRoute exact path='/installers' component={InstallersList} />
              <PrivateRoute exact path='/installers/new' component={ServiceSpecialistEdit} />
              <PrivateRoute exact path='/installers/view/:id' component={ServiceSpecialistEdit} />
              <PrivateRoute exact path='/installers/edit/:id' component={ServiceSpecialistEdit} />

              {/* Cockpit */}
              <PrivateRoute exact path='/cockpit' component={Cockpit} />

              {/* Provider log */}
              <PrivateRoute exact path='/servcPrvdrTmlineEvnt' component={ServcPrvdrTmlineEvnt} />

              {/* Craftsman logs */}
              <PrivateRoute
                exact
                path='/servcPrvdrAgntTmlineEvnt'
                component={ServcPrvdrAgntTmlineEvnt}
              />

            </PrivateLayout>
          )}

          {user.currentUser?.username ? (
            <Route path='*' exact component={NotFoundContainer} />
          ) : (
            !user.loggingOut && <Redirect path='*' exact to='/login' />
          )}
        </Switch>
      </div>
    </Router>
  )
}

function mapStateToProps({ auth }) {
  return { auth }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...authActions }, dispatch)
}

const RootTranslated = withTranslation()(Root)

export default connect(mapStateToProps, mapDispatchToProps)(RootTranslated)
