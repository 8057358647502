import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { toast } from 'react-toastify';
import styled from '@emotion/styled';
import { Checkmark, Cross, AlertTriangle, InfoCircle, } from '../../../../../../../leroy-merlin-br/backyard-icons';
import { messageStyles, titleStyles, textStyles } from './styles';
var Title = styled('h3')(titleStyles);
var Text = styled('p')(textStyles);
var MessageEl = styled('div')(messageStyles);
var Message = function (_a) {
    var title = _a.title, text = _a.text, type = _a.type;
    var ICONS_MAP = {
        error: _jsx(Cross, { width: 18, height: 18 }, void 0),
        info: _jsx(InfoCircle, { width: 20, height: 20 }, void 0),
        success: _jsx(Checkmark, { width: 20, height: 20 }, void 0),
        warn: _jsx(AlertTriangle, { width: 20, height: 20 }, void 0),
    };
    return (_jsxs(MessageEl, { children: [ICONS_MAP[type], _jsxs("div", { children: [_jsx(Title, { children: title }, void 0),
                    _jsx(Text, { children: text }, void 0)] }, void 0)] }, void 0));
};
var TOAST_MAP = {
    error: function (title, text, props) {
        return toast.error(_jsx(Message, { title: title, text: text, type: "error" }, void 0), props);
    },
    info: function (title, text, props) {
        return toast.info(_jsx(Message, { title: title, text: text, type: "info" }, void 0), props);
    },
    success: function (title, text, props) {
        return toast.success(_jsx(Message, { title: title, text: text, type: "success" }, void 0), props);
    },
    warn: function (title, text, props) {
        return toast.warn(_jsx(Message, { title: title, text: text, type: "warn" }, void 0), props);
    },
};
export default TOAST_MAP;
