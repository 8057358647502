import { isEmpty, isEqual, isNil } from 'lodash'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Col from '../../components/utils/Col'
import ConfirmModal from '../../components/utils/ConfirmModal'
import Grid from '../../components/utils/Grid'
import Row from '../../components/utils/Row'
import SpinnerCircle from '../../components/utils/SpinnerCircle'
import {
  ACTV_SERVC_PRVDR_IND_TYPES,
  ACTV_SERVC_PRVDR_ORD_RECV_IND_TYPES,
  ATTACHMENT_CLASSIFICATION,
  BUDGET_DISTRIBUTED,
  BUDGET_ORDERS,
  BUDGET_WITHOUT_ANSWER,
  ORDER_STATUS_TYPE,
  QUOTATION_REFUSED_BY_EXPIRATION_JOB,
} from '../../constants/config'
import { ServiceOrderProvider } from '../../contexts/serviceOrder'
import ServcOrdCategoryType from '../../enums/ServiceOrder/category/serviceCategoryType'
import ServcOrdEvntStusType from '../../enums/ServiceOrder/event/ServcOrdEvntStusType'
import ServiceOrderStatusType from '../../enums/ServiceOrder/status/ServiceOrderStatusType'
import ErrorBoundary from '../../errors/boundaries/pages/ServiceOrder'
import PageErrorFallback from '../../errors/fallbacks/pages/ServiceOrder'
import { renderToastSuccessMsg, renderToastWarn } from '../../helpers/ToastUtils'
import { isPlannedVisit, needTechReport } from '../../helpers/isTechVisit'
import { isHogamiOrder } from '../../helpers/serviceOrderUtilis'
import { acceptanceSalesConditions } from '../../helpers/serviceOrderUtils'
import { userAllowedToEnter } from '../../helpers/userAllowedToEnter'
import * as serviceOccurrenceActions from '../../redux/actions/serviceOccurrence'
import * as serviceOrderActions from '../../redux/actions/serviceOrder'
import { downloadFile } from '../../services/amazonAws/DownloadFile'
import { fetchOsStatuses } from '../../services/pages/fetchOsStatuses'
import ModalQRCode from '../ServiceOrder/components/qrcode/Modal'
import AcceptedBudgets from './components/AcceptedBudgets'
import BudgetDistributed from './components/BudgetDistributed'
import BudgetList from './components/BudgetList'
import BudgetsModal from './components/BudgetsModal'
import Footer from './components/Footer'
import FormSection from './components/FormSection'
import Notes from './components/Notes'
import Occurrences from './components/Occurrences'
import SectionQRCode from './components/SectionQRCode'
import ServiceOrderImages from './components/ServiceOrderImages'
import ServiceOrderModal from './components/ServiceOrderModal'
import ServicesList from './components/ServicesList'
import Warranty from './components/Warranty'
import { WithContext } from './context'

const LIST_DOCUMENTS = 'LIST_DOCUMENTS'
const LAUDO = 'LAUDO'
const DOCUMENTS = 'DOCUMENTS'
const OUT_FLOW_REFUSED = 3
const SERVICE_PORTAL = true
let modalType = LIST_DOCUMENTS

class ServiceOrderEdit extends Component {
  constructor(props) {
    super(props)

    this.state = {
      serviceOrderModal: false,
      modalGenerateNewOS: false,
      currentStatus: '',
      realStatus: '',
      values: {},
      canSubmit: true,
      date: null,
      validation: true,
      oldStatus: '',
      confirmModal: false,
      action: null,
      message: '',
      params: [],
      properties: {},
      modalQrTerms: false,
      modalQrConformity: false,
      listBudgets: [],
      budgetUploadModal: false,
      idFiles: null,
      idTypeFileBudget: null,
      budgetDistributedModal: false,
      servcPrvdrPlntCd: '',
      statuses: [],
      loading: false,
    }
  }

  async componentDidMount() {
    const {
      auth: { user },
      fetchReasonsList,
      fetchUnnacomplishedReasonsList,
      clearServiceOrderResponse,
    } = this.props

    this.setState({ loading: true })

    fetchReasonsList(user.dsToken, 1, {}, false)
    clearServiceOrderResponse()
    fetchUnnacomplishedReasonsList()

    await this.checkServiceOrder().then((response) => {
      const servcOrd = response[0] || []

      userAllowedToEnter(servcOrd.servcCatgryTypCd)

      this.handleFetchStatuses(servcOrd)
      this.handleFetchServiceOrderImages(servcOrd)
      this.handleFetchServiceOrderAttachment()

      if (
        servcOrd.servcOrdStusCd === ServiceOrderStatusType.STATUS_AGENDADA.id &&
        !servcOrd.servcPrvdrNm
      ) {
        this.handleFetchServiceOrderWithReport(servcOrd)
      }

      this.handleUpdateStateContainer(servcOrd)

      this.updateFields(this.getOSInitialValues(servcOrd))
      this.getServiceProviders({ nomePrestadorServico: '' })
      this.setState({ loading: false })
    })
  }

  handleUpdateStateContainer(servcOrd) {
    this.setState({
      realStatus: servcOrd.servcOrdStusCd,
      oldStatus: servcOrd.servcOrdStusCd,
    })
  }

  componentWillUnmount() {
    const { handleSelectedBudgetDistributed } = this.props
    handleSelectedBudgetDistributed(false)
  }

  componentDidUpdate(prevProps) {
    const {
      changeObsResult,
      resetChangeObs,
      serviceOrderEdit,
      setFormValue,
      t,
      isFetchingOrder,
      serviceOrder,
      getServiceOrderError,
    } = this.props

    const { statuses } = this.state

    if (!isFetchingOrder && isEmpty(serviceOrder)) return getServiceOrderError()

    // On successful update
    if (
      serviceOrderEdit.record !== prevProps.serviceOrderEdit.record &&
      !isEmpty(serviceOrderEdit.record)
    ) {
      setFormValue({ justificativa: '' })
    }

    if (changeObsResult && changeObsResult !== prevProps.changeObsResult) {
      if (changeObsResult.success) {
        renderToastSuccessMsg(changeObsResult.success.dsMessage)
        resetChangeObs()
      } else if (changeObsResult.error) {
        renderToastWarn(changeObsResult.error)
        resetChangeObs()
      }
    }

    if (!isEqual(serviceOrderEdit, prevProps.serviceOrderEdit)) {
      if (serviceOrderEdit && !serviceOrderEdit.isUpdating) {
        let content = ''
        let type = 'success'
        if (serviceOrderEdit.error) {
          type = 'error'
          if (serviceOrderEdit.error.fields) {
            content = serviceOrderEdit.error.fields.map(
              (err) => `${(err.naField ? `${err.naField}:` : '') + err.dsDetail}\n`,
            )
          } else {
            content = serviceOrderEdit.error.message.dsMessage
          }
        } else if (Object.keys(serviceOrderEdit.record).length) {
          this.handleUpdateOldOrderStatus()
          content = t('serviceOrders.saved')
        }

        if (content) {
          if (type === 'success') {
            renderToastSuccessMsg(content)
          } else {
            renderToastWarn(content)
          }
        }
        this.handleFetchStatuses()
      }
    }

    if (
      !Object.keys(prevProps.serviceOrder).length &&
      !isEqual(serviceOrder, prevProps.serviceOrder) &&
      !statuses.length
    ) {
      this.handleFetchStatuses()
    }

    this.checkModifyOnSelectBranches(prevProps)
  }

  handleUpdateOldOrderStatus = () => {
    const { serviceOrderEdit } = this.props
    this.setState({
      oldStatus: serviceOrderEdit?.record?.servcOrdStusCd,
    })
  }

  async checkModifyOnSelectBranches(prevProps) {
    const { fields } = this.props

    const valueFieldExists = fields.servcPrvdrPlntCd.input.value
    const isNotEqualServcPrvdrPlntCd = !isEqual(
      fields.servcPrvdrPlntCd.input.value,
      prevProps.fields.servcPrvdrPlntCd.input.value,
    )

    if (isNotEqualServcPrvdrPlntCd && valueFieldExists) {
      await this.setState({
        servcPrvdrPlntCd: fields.servcPrvdrPlntCd.input.value.value,
      })

      await this.getServiceProviders({ nomePrestadorServico: '' })
    }
  }

  checkServiceOrder = () => {
    const {
      match: { params },
      getServiceOrder,
      auth: { user },
    } = this.props

    return getServiceOrder(user.dsToken, params)
  }

  handleObsChange = (event) => {
    const { mergeWithServiceOrder } = this.props
    const { value } = event.target

    if (!isNil(value)) {
      mergeWithServiceOrder({ servcOrdRmrkTxt: value })
    }
  }

  closeConfirmModal = () => {
    this.setState({
      confirmModal: false,
      message: null,
      action: null,
      params: [],
      properties: {},
    })
  }

  renderConfirmModal = () => {
    const { confirmModal, action, message, params, properties } = this.state
    const { t } = this.props

    if (!confirmModal) {
      return
    }

    return (
      <ConfirmModal
        {...properties}
        t={t}
        closeModal={this.closeConfirmModal}
        action={action}
        messageHeader={message}
        params={params}
      />
    )
  }

  closeServiceOrderModal = () => {
    this.setState({ serviceOrderModal: false })
  }

  openServiceOrderModal = () => {
    this.setState({ serviceOrderModal: true })
  }

  openAttachmentModal = () => {
    modalType = DOCUMENTS
    this.openServiceOrderModal()
  }

  openDocumentsModal = () => {
    modalType = LIST_DOCUMENTS
    this.openServiceOrderModal()
  }

  openLaudoModal = () => {
    modalType = LAUDO
    this.openServiceOrderModal()
  }

  onSuccessUpdateServiceOccurrence = (values) => {
    const { serviceOrder } = this.props
    serviceOrder.serviceOrderOccurrence.splice(0, 0, {
      codUserRegistroOcor: values.occurrenceRegUsrCd,
      datOcorOrdemServico: values.servcOrdOccurrenceDt,
      idOrdemServicoOcor: values.servcOrdOccurrenceId,
      descOcorOrdemServico: values.servcOrdOccurrenceDesc,
      nomUserRegistroOcor: values.servcOrdEvntUsrNm,
    })
  }

  updateServiceOccurrence = (token, values) => {
    const { updateServiceOccurrence } = this.props
    updateServiceOccurrence(token, values, this.onSuccessUpdateServiceOccurrence)
  }

  renderServiceOrderModal = () => {
    const { serviceOrderModal } = this.state
    const { t, serviceOrder, auth, attachments, error, dispatch } = this.props

    if (!serviceOrderModal) {
      return
    }

    let props = {
      t,
      modalType,
      closeModal: this.closeServiceOrderModal,
      disabled: false,
    }

    switch (modalType) {
      case LIST_DOCUMENTS:
        props = {
          ...props,
          values: serviceOrder.serviceOrderDocument,
          disabled: this.isDisabled(),
          auth,
        }

        break

      case LAUDO:
        if (isNil(serviceOrder.descLaudoTecnico)) {
          serviceOrder.descLaudoTecnico = ''
        }

        const initialValues = {
          descLaudoTecnico: serviceOrder.descLaudoTecnico,
        }

        props = {
          ...props,
          values: serviceOrder,
          initialValues,
          disabled: true,
          auth,
        }

        break

      case DOCUMENTS:
        props = {
          disabled: false,
          t,
          modalType: DOCUMENTS,
          closeModal: this.closeServiceOrderModal,
          initialValues: {
            servcOrdSeq: serviceOrder.servcOrdSeq,
          },
          auth,
          attachments,
          error,
          onSubmit: this.handleSaveFile,
          onDelete: this.handleRemoveFile,
          downloadFile: this.handleDownloadFile,
          dispatch,
          attachmentId: 'servcOrdItemAtachmtId',
          relationId: 'servcOrdSeq',
          servcOrdSeq: serviceOrder.servcOrdSeq,
        }
        break

      default:
        props = {
          ...props,
          values: serviceOrder.serviceOrderOccurrence,
          onSubmit: this.updateServiceOccurrence,
          initialValues: {
            servcOrdSeq: serviceOrder.servcOrdSeq,
            descOcorOrdemServico: '',
          },
          auth,
          disabled: this.isDisabled(),
          showCloseButton: false,
          className: 'resize-none height-eighty-percent',
        }

        break
    }

    return <ServiceOrderModal {...props} />
  }

  handleSaveFile = async (values) => {
    const {
      saveServcOrdFiles,
      auth: { user },
    } = this.props

    await saveServcOrdFiles(user.dsToken, {
      ...values,
      isPortalService: SERVICE_PORTAL,
    })

    this.handleFetchServiceOrderAttachment()
  }

  handleDownloadFile = (file, name) => {
    downloadFile(file, name)
  }

  handleRemoveFile = async (document) => {
    const {
      deleteServcOrdFiles,
      auth: { user },
    } = this.props

    await deleteServcOrdFiles(user.dsToken, document)
    await this.fetchBudgetsFiles()
  }

  fetchBudgetsFiles = async () => {
    const {
      serviceOrder,
      fetchServiceOrderAttachmentBudgets,
      auth: { user },
    } = this.props

    await fetchServiceOrderAttachmentBudgets(
      user.dsToken,
      serviceOrder.servcOrdSeq,
      ATTACHMENT_CLASSIFICATION.BUDGET_ATTACHMENT_CUSTOMER,
    )
    await fetchServiceOrderAttachmentBudgets(
      user.dsToken,
      serviceOrder.servcOrdSeq,
      ATTACHMENT_CLASSIFICATION.BUDGET_ATTACHMENT_STORE,
    )
  }

  handleRemoveFileBudget = async (document) => {
    const {
      deleteServcOrdFilesBudgets,
      auth: { user },
    } = this.props

    const { budgetUploadModal } = this.state

    await deleteServcOrdFilesBudgets(user.dsToken, document)
    await this.handleFetchServiceOrderAttachment()

    if (budgetUploadModal) {
      this.handleCheckTypeFileBudget()
    }
  }

  handleOpenModalFileBudget = (list, id, idTypeFiles) => {
    this.setState({
      budgetUploadModal: true,
      listBudgets: list,
      idFiles: id,
      idTypeFileBudget: idTypeFiles,
    })
  }

  handleCloseUploadFileBudget = () => {
    this.setState({
      budgetUploadModal: false,
      listBudgets: [],
      idFiles: null,
      idTypeFileBudget: null,
    })
  }

  uploadBudgetFile = () => {
    const { budgetUploadModal, listBudgets, idTypeFileBudget } = this.state
    const { t, auth, dispatch, serviceOrder } = this.props

    if (!budgetUploadModal) return

    const props = {
      t,
      closeModal: this.handleCloseUploadFileBudget,
      disabled: false,
      auth,
      attachments: listBudgets,
      dispatch,
      onSubmit: this.onSubmitUploadBudgetFile,
      attachmentId: 'servcOrdItemAtachmtId',
      relationId: idTypeFileBudget,
      deleteAtchmt: this.handleRemoveFileBudget,
      downloadFile: this.handleDownloadFile,
      blockButtonRemove: serviceOrder.lastEvntStusInd !== BUDGET_ORDERS,
    }
    return <BudgetsModal {...props} />
  }

  onSubmitUploadBudgetFile = async (values) => {
    const {
      serviceOrder,
      saveServcOrdFilesBudgets,
      auth: { user },
    } = this.props

    const param = {
      servcOrdSeq: serviceOrder.servcOrdSeq,
      budgAtchmtPathTxt: values.files,
      type: 'pdf',
    }

    await saveServcOrdFilesBudgets(user.dsToken, param)
    await this.handleFetchServiceOrderAttachment()

    this.handleCheckTypeFileBudget()

    this.setState({ budgetUploadModal: false })
  }

  handleCheckTypeFileBudget() {
    const { idTypeFileBudget } = this.state
    const { listBudgetsClient, listBudgetsStore } = this.props

    if (idTypeFileBudget === 'listBudgetsClient') {
      this.setState({ listBudgets: listBudgetsClient })
    } else {
      this.setState({ listBudgets: listBudgetsStore })
    }
  }

  handleBudgetAccepted = (value) => {
    const { handleAcceptedBudgets, handleDaniedBudgets, handleAcceptedBudgetsClear } = this.props
    if (value === null) {
      handleAcceptedBudgetsClear()
    } else if (value === true) {
      handleAcceptedBudgets()
    } else {
      handleDaniedBudgets()
    }
  }

  sendBudgetDistributed = () => {
    const {
      serviceOrder,
      auth: { user },
      handleBudgetADistributed,
      handleSelectedBudgetDistributed,
    } = this.props

    handleSelectedBudgetDistributed(true)
    handleBudgetADistributed(user.dsToken, serviceOrder.servcOrdSeq)
  }

  cancelBudgetDistributed = () => {
    this.setState({ budgetDistributedModal: false })
  }

  handleBudgetADistributed = () => {
    this.setState({ budgetDistributedModal: true })
  }

  renderBudgetDistributedModal = () => {
    const { t } = this.props
    const { budgetDistributedModal } = this.state

    if (!budgetDistributedModal) {
      return ''
    }

    return (
      <ConfirmModal
        t={t}
        closeModal={this.cancelBudgetDistributed}
        action={this.sendBudgetDistributed}
        messageHeader='servc.ord.budget.component.title.distributed.budget.delete'
      />
    )
  }

  shouldRenderShowDocumentsButton = () => {
    const {
      serviceOrder: { serviceOrderDocument },
    } = this.props
    return serviceOrderDocument && serviceOrderDocument.length
  }

  handleFetchStatuses = async (servcOrd = null) => {
    const {
      serviceOrder: { servcOrdStusCd },
      auth: { user },
    } = this.props

    const currentStus = servcOrdStusCd || servcOrd?.servcOrdStusCd

    if (currentStus) {
      fetchOsStatuses(user.dsToken, currentStus).then((statuses) => this.setState({ statuses }))
    }
  }

  handleChangeStatus = (value, isStatusSelect) => {
    const { serviceOrder, setFormValue, mergeWithServiceOrder } = this.props

    if (value === ORDER_STATUS_TYPE.SCHEDULED && !serviceOrder.servcPrvdrNm) {
      this.handleFetchServiceOrderWithReport()
    }

    setFormValue({ servcOrdStusCd: value })
    mergeWithServiceOrder({ servcOrdStusCd: value })

    this.setState({ currentStatus: value })
  }

  submitForm = (values) => {
    const {
      serviceOrder,
      t,
      clearServiceOrderResponse,
    } = this.props

    if (values) {
      this.setState({ values })
    }

    clearServiceOrderResponse()

    const { servcCatgryTypCd } = values

    let params = {
      ...serviceOrder,
      ...values,
      internalJustification: true,
    }

    if (servcCatgryTypCd === undefined) {
      params = {
        ...params,
        servcCatgryTypCd: serviceOrder.servcCatgryTypCd,
      }
    }

    delete params.images
    delete params.serviceOrderOccurrence

    // Invalid fields from the form
    const errorFields = []

    // If there is no errors in the form
    if (!errorFields.length) {
      this.handleUpdateServiceOrder(params)
    } else {
      errorFields.forEach((field) => renderToastWarn(t(`requiredQuestionError.${field}`)))
    }
  }

  handleUpdateServiceOrder(params) {
    const {
      auth: { user },
      updateServiceOrder,
      serviceOrder,
    } = this.props

    const payload = {
      ...params,
      // INST-12976: for some reason values of this fields came null on first render blocking update operations
      idSlot: serviceOrder.idSlot,
      idSlotApplication: serviceOrder.idSlotApplication,
      dsSlot: serviceOrder.dsSlot,
    }

    updateServiceOrder(user.dsToken, payload, this.onSuccess).then(() => this.afterUpdate())
  }

  onSuccess = (params) => {
    const {
      serviceOrder: { servcOrdStusCd },
      mergeWithServiceOrder,
    } = this.props

    mergeWithServiceOrder({
      servcOrdStusCd: params.servcOrdStusCd,
      serviceOrderOccurrence: params.serviceOrderOccurrence,
    })

    this.setState({ realStatus: servcOrdStusCd })
    this.handleFetchStatuses()
    this.handleChangeStatus(params.servcOrdStusCd, true)
  }

  getServiceProviders = (filters) => {
    const {
      auth: {
        user,
        setupParameters: { enableSelectBranchsOnServcOrder },
      },
      serviceOrder,
      fetchServiceProviderAutocomplete,
    } = this.props
    const { realStatus, servcPrvdrPlntCd } = this.state

    const verifyStatus =
      serviceOrder.servcOrdStusCd === ServiceOrderStatusType.STATUS_PRE_AGENDADA.id ||
      serviceOrder.servcOrdStusCd === ServiceOrderStatusType.STATUS_AGENDADA.id

    const branchIsSelected =
      servcPrvdrPlntCd !== undefined &&
      servcPrvdrPlntCd.length > 0 &&
      enableSelectBranchsOnServcOrder &&
      verifyStatus

    let servcPrvdrMainPlntCd = branchIsSelected ? servcPrvdrPlntCd : serviceOrder.plntCd
    // eslint-disable-next-line no-param-reassign
    filters.actvServcPrvdrInd = ACTV_SERVC_PRVDR_IND_TYPES.ACTIVE
    // eslint-disable-next-line no-param-reassign
    filters.servcPrvdrAddRgstrnCurrntStepNr = -1
    // eslint-disable-next-line no-param-reassign
    filters.actvServcPrvdrOrdRecvInd = ACTV_SERVC_PRVDR_ORD_RECV_IND_TYPES.ACTIVE
    // eslint-disable-next-line no-param-reassign
    filters.servcOrdSeq = serviceOrder.servcOrdSeq
    // eslint-disable-next-line no-param-reassign
    filters.state = serviceOrder.servcAddrStCd
    // eslint-disable-next-line no-param-reassign
    filters.servcRgnCd = serviceOrder.servcRgnCd

    // Se o status é diferente de 00 e 01 enviar novos filtros
    if (
      realStatus &&
      realStatus !== ServiceOrderStatusType.STATUS_PRE_AGENDADA.id &&
      realStatus !== ServiceOrderStatusType.STATUS_AGENDADA.id
    ) {
      // eslint-disable-next-line no-param-reassign
      filters = {
        servcPrvdrId: serviceOrder.servcPrvdrCd,
        servcPrvdrNm: serviceOrder.servcPrvdrNm,
      }
      servcPrvdrMainPlntCd = null
    }

    clearTimeout(this.timeOut)
    this.timeOut = setTimeout(() => {
      fetchServiceProviderAutocomplete(
        user.dsToken,
        filters,
        servcPrvdrMainPlntCd,
        true,
        this.getStateDate(),
        this.getStateShift(),
      )
    }, 400)
  }

  getServiceSpecialists = (servcPrvdrCd, servcOrdSchdlgTs) => {
    const {
      auth: {
        user,
        setupParameters: { enableSelectBranchsOnServcOrder },
      },
      serviceOrder,
      fetchServiceSpecialistList,
    } = this.props
    const { realStatus, servcPrvdrPlntCd } = this.state

    const verifyStatus =
      serviceOrder.servcOrdStusCd === ServiceOrderStatusType.STATUS_PRE_AGENDADA.id ||
      serviceOrder.servcOrdStusCd === ServiceOrderStatusType.STATUS_AGENDADA.id

    const branchIsSelected =
      servcPrvdrPlntCd !== undefined &&
      servcPrvdrPlntCd.length > 1 &&
      enableSelectBranchsOnServcOrder &&
      verifyStatus

    let filter = {
      servcPrvdrId: servcPrvdrCd,
      actvServcPrvdrAgntInd: 1,
      schdlDt: servcOrdSchdlgTs,
      serviceOrderPlntCd: branchIsSelected ? servcPrvdrPlntCd : serviceOrder.plntCd,
      state: serviceOrder.servcAddrStCd,
      shiftCd: this.getStateShift,
    }

    // Se o status é diferente de 00 e 01 enviar novos filtros
    if (
      realStatus &&
      realStatus !== ServiceOrderStatusType.STATUS_PRE_AGENDADA.id &&
      realStatus !== ServiceOrderStatusType.STATUS_AGENDADA.id &&
      !(
        realStatus === ServiceOrderStatusType.STATUS_DISTRIBUIDA.id &&
        serviceOrder.servcPrvdrAgntCd === null
      )
    ) {
      filter = {
        servcPrvdrId: serviceOrder.servcPrvdrCd,
        servcPrvdrAgntId: serviceOrder.servcPrvdrAgntCd,
        schdlDt: servcOrdSchdlgTs,
      }
    }

    if (!isNil(servcPrvdrCd)) {
      fetchServiceSpecialistList(user.dsToken, filter)
    }
  }

  afterUpdate = async () => {
    const {
      getServiceOrder,
      auth: { user },
      match: { params },
      serviceOrder,
    } = this.props

    this.checkServiceOrder().then((response) => {
      const servcOrd = response[0] || []

      this.updateFields(this.getOSInitialValues(servcOrd))
    })

    getServiceOrder(user.dsToken, params)

    this.handleFetchServiceOrderImages()
  }

  handleFetchServiceOrderWithReport(servcOrd) {
    const {
      auth: { user },
      fetchServiceOrderWithReport,
      serviceOrder,
      match: { params },
    } = this.props

    const custIdNr = serviceOrder?.custIdNr || servcOrd?.custIdNr
    const servcOrdSeq = params?.servcOrdCd

    fetchServiceOrderWithReport(user.dsToken, servcOrdSeq, custIdNr)
  }

  handleFetchServiceOrderAttachment = async () => {
    const {
      auth: { user },
      fetchServiceOrderAttachment,
      match: { params },
    } = this.props

    await fetchServiceOrderAttachment(user.dsToken, params.servcOrdCd)
  }

  async handleFetchServiceOrderImages(servcOrd) {
    const {
      auth: { user },
      fetchServiceOrderImages,
      serviceOrder,
      match: { params },
    } = this.props

    const coCd = serviceOrder.coCd || servcOrd.coCd
    const plntCd = serviceOrder.coCd || servcOrd.plntCd
    const servcOrdSeq = params.servcOrdCd

    await fetchServiceOrderImages(user.dsToken, coCd, plntCd, servcOrdSeq)
  }

  setCanSubmit = (canSubmit) => {
    this.setState({ canSubmit })
  }

  canDistributePreScheduled = () => {
    const { auth } = this.props
    const { realStatus } = this.state

    return (
      realStatus === ServiceOrderStatusType.STATUS_PRE_AGENDADA.id &&
      auth?.setupParameters?.acceptanceSalesConditions === false
    )
  }

  isDisabled = () => {
    const {
      auth: { user },
      auth,
      disabled,
      serviceOrder,
    } = this.props

    const { realStatus, oldStatus } = this.state

    if (serviceOrder.isConformity && auth.setupParameters.conformity) {
      return true
    }

    if (realStatus === ServiceOrderStatusType.NAO_CONFORME.id) {
      return true
    }

    if (oldStatus === ServiceOrderStatusType.NAO_CONFORME.id) {
      return true
    }

    if (
      (realStatus === ServiceOrderStatusType && user?.funcaoAcesso?.inFuncao00 !== 1) ||
      (realStatus === ServiceOrderStatusType.STATUS_AGENDADA.id &&
        user?.funcaoAcesso?.inFuncao01 !== 1) ||
      (realStatus === ServiceOrderStatusType.STATUS_DISTRIBUIDA.id &&
        user?.funcaoAcesso?.inFuncao02 !== 1) ||
      (realStatus === ServiceOrderStatusType.STATUS_ASSOCIADA.id &&
        user?.funcaoAcesso?.inFuncao03 !== 1) ||
      (realStatus === ServiceOrderStatusType.STATUS_CONFIRMADA.id &&
        user?.funcaoAcesso?.inFuncao04 !== 1) ||
      (realStatus === ServiceOrderStatusType.STATUS_EM_REALIZACAO.id &&
        user?.funcaoAcesso?.inFuncao05 !== 1) ||
      (realStatus === ServiceOrderStatusType.STATUS_REALIZADO_COM_SUCESSO.id &&
        user?.funcaoAcesso?.inFuncao06 !== 1) ||
      (realStatus === ServiceOrderStatusType.STATUS_REALIZADO_PARCIALMENTE.id &&
        user?.funcaoAcesso?.inFuncao07 !== 1) ||
      (realStatus === ServiceOrderStatusType.STATUS_ENCERRADO.id &&
        user?.funcaoAcesso?.inFuncao08 !== 1) ||
      (realStatus === ServiceOrderStatusType.STATUS_CANCELADA.id &&
        user?.funcaoAcesso?.inFuncao09 !== 1) ||
      (realStatus === ServiceOrderStatusType.NAO_CONFORME.id &&
        user?.funcaoAcesso?.inFuncao10 !== 1)
    ) {
      return true
    }
    if (
      user?.funcaoAcesso?.inFuncao00 === 1 ||
      user?.funcaoAcesso?.inFuncao01 === 1 ||
      user?.funcaoAcesso?.inFuncao02 === 1 ||
      user?.funcaoAcesso?.inFuncao03 === 1 ||
      user?.funcaoAcesso?.inFuncao04 === 1 ||
      user?.funcaoAcesso?.inFuncao05 === 1 ||
      user?.funcaoAcesso?.inFuncao06 === 1 ||
      user?.funcaoAcesso?.inFuncao07 === 1 ||
      user?.funcaoAcesso?.inFuncao08 === 1 ||
      user?.funcaoAcesso?.inFuncao09 === 1 ||
      user?.funcaoAcesso?.inFuncao10 === 1
    ) {
      return false
    }
    return disabled
  }

  getStateDate = () => {
    const { date } = this.state
    return date
  }

  setStateDate = (date) => {
    this.setState({ date })
  }

  setStateShift = (shift) => {
    this.setState({ shift })
  }

  getStateShift = () => {
    const { shift } = this.state
    return shift
  }

  getOSInitialValues = (osInit = null) => {
    const { serviceOrder } = this.props

    const body = osInit || serviceOrder

    const {
      servcPrvdrCd,
      servcPrvdrNm,
      servcPrvdrAgntCd,
      servcPrvdrAgntNm,
      servcOrdStusCd,
      servcOrdSchdlgTs,
      plntServcCalndrCd,
      idSlotApplication,
      dsSlot,
      custBusnsPhonNr,
      custHmPhonNr,
      custMobilePhonNr,
      servcAddrStrNm,
      servcAddrNr,
      servcAddrCmplmtryTxt,
      servcAddrDstrctNm,
      servcAddrPstlCd,
      servcAddrCityNm,
      servcAddrStCd,
      custEmailTxt,
      servcAddrRefPointDesc,
      servcPrvdrTrdNm,
      servcCatgryTypCd,
      ibgeCode,
    } = body

    const date = servcOrdSchdlgTs ? moment(servcOrdSchdlgTs, 'YYYY/MM/DD') : null

    return {
      servcPrvdrCd,
      servcPrvdrNm,
      servcPrvdrAgntCd,
      servcPrvdrAgntNm,
      servcOrdStusCd,
      servcOrdSchdlgTs,
      date,
      plntServcCalndrCd,
      idSlot: body.dsSlotKey,
      idSlotApplication,
      dsSlot,
      justificativa: '',
      custBusnsPhonNr,
      custHmPhonNr,
      custMobilePhonNr,
      servcAddrStrNm,
      servcAddrNr,
      servcAddrCmplmtryTxt,
      servcAddrDstrctNm,
      servcAddrPstlCd,
      servcAddrCityNm,
      servcAddrStCd,
      custEmailTxt,
      servcAddrRefPointDesc,
      phone1: custMobilePhonNr,
      phone2: custBusnsPhonNr,
      phone3: custHmPhonNr,
      servcPrvdrTrdNm,
      servcCatgryTypCd,
      ibgeCode,
    }
  }

  updateFields = (initOS) => {
    const { setFormValue } = this.props

    setFormValue(initOS)
  }

  save = () => {
    const { t, values, serviceOrderEdit, auth, serviceOrder, optionBudgetAccepted } = this.props
    const {
      canSubmit,
      currentStatus,
      oldStatus,
      date,
    } = this.state

    if (!canSubmit) {
      renderToastWarn(t('serviceOrder.make-evaluation-message'))
      return
    }

    if (
      currentStatus !== oldStatus &&
      currentStatus === ServiceOrderStatusType.STATUS_ENCERRADO.id &&
      !this.techReportValidation()
    ) {
      renderToastWarn(t('servcOrd.technicalReport.required'))
      return
    }

    if (date) {
      values.data = date.format('L')
      values.servcOrdSchdlgTs = date.format()
    }

    delete values.date

    if (currentStatus === oldStatus && serviceOrderEdit) {
      switch (values.servcOrdStusCd) {
        case ORDER_STATUS_TYPE.PRE_SCHEDULE:
          if (
            auth.setupParameters.acceptanceSalesConditions &&
            acceptanceSalesConditions(serviceOrder)
          ) {
            values.servcOrdStusCd = oldStatus
          }
          break
        case ORDER_STATUS_TYPE.SCHEDULED:
          if (values.servcPrvdrCd) {
            // salva para distribuido quando tem o prestador
            values.servcOrdStusCd = ORDER_STATUS_TYPE.DISTRIBUTED
          }
          break
        case ORDER_STATUS_TYPE.ASSOCIATED:
          if (values.servcPrvdrCd && values.servcPrvdrAgntCd) {
            values.servcOrdStusCd = ORDER_STATUS_TYPE.CONFIRMED
          }
      }
    }

    if (this.handleCheckBudgetsServiceOrderEvents()) {
      values.optionBudgetAccepted = optionBudgetAccepted
      values.isBudget = true
    } else {
      values.isBudget = false
    }

    const valuesSub = {
      ...values,
      oldStatus,
      servcPrvdrPlntCd: values.servcPrvdrPlntCd.value,
      custBusnsPhonNr: serviceOrder.custBusnsPhonNr,
      custHmPhonNr: serviceOrder.custHmPhonNr,
      custMobilePhonNr: serviceOrder.custMobilePhonNr,
      servcAddrStrNm: serviceOrder.servcAddrStrNm,
      servcAddrNr: serviceOrder.servcAddrNr,
      servcAddrCmplmtryTxt: serviceOrder.servcAddrCmplmtryTxt,
      servcAddrDstrctNm: serviceOrder.servcAddrDstrctNm,
      servcAddrPstlCd: serviceOrder.servcAddrPstlCd,
      servcAddrCityNm: serviceOrder.servcAddrCityNm,
      servcAddrStCd: serviceOrder.servcAddrStCd,
      custEmailTxt: serviceOrder.custEmailTxt,
      servcAddrRefPointDesc: serviceOrder.servcAddrRefPointDesc,
      ibgeCode: serviceOrder.ibgeCode,
    }
    this.submitForm(valuesSub)
  }

  onSubmitHeaderObs = () => {
    const {
      changeObs,
      auth: { user },
      serviceOrder,
    } = this.props

    const values = {
      servcOrdSeq: serviceOrder.servcOrdSeq,
      servcOrdObs: serviceOrder.servcOrdRmrkTxt,
    }

    changeObs(user.dsToken, values)
  }

  onSubmitItemObs = (serviceOrderItem) => {
    const {
      changeItemObs,
      auth: { user },
    } = this.props

    const values = {
      servcOrdSeq: serviceOrderItem.servcOrdSeq,
      servcOrdItemEvntId: serviceOrderItem.idOrdemServicoItem,
      servcOrdObs: serviceOrderItem.servcOrdRmrkTxt,
    }

    changeItemObs(user.dsToken, values)
  }

  renderLoading = () => (
    <div className='padding-double align-center'>
      <SpinnerCircle />
    </div>
  )

  onChange = (name, value) => {
    const { setFormValue } = this.props

    setFormValue({ [name]: value })
  }

  renderServiceOrderImages = (images = []) => {
    const { t } = this.props

    let regularImages = []
    const needHelpPhotos = []
    const evaluationPhotos = []

    if (images !== null) {
      images.forEach((image) => {
        switch (image.servcOrdItemAtchmtClsfctnCd) {
          case ATTACHMENT_CLASSIFICATION.NEED_HELP_PHOTO:
            needHelpPhotos.push(image)
            return
          case ATTACHMENT_CLASSIFICATION.EVALUATION_PHOTO:
            evaluationPhotos.push(image)
            return
          default:
            regularImages.push(image)
        }
      })
    }

    regularImages = regularImages.filter(
      (image) => image.servcOrdItemAtchmtClsfctnCd !== ATTACHMENT_CLASSIFICATION.MEASUREMENT_SHEET,
    )

    return (
      <>
        <ServiceOrderImages
          t={t}
          images={regularImages}
          label={t('serviceOrders.photos')}
          startPhoto
        />
        <hr />
        <ServiceOrderImages t={t} images={needHelpPhotos} label={t('serviceOrders.photos.help')} />
        <hr />
        <ServiceOrderImages
          t={t}
          images={evaluationPhotos}
          label={t('serviceOrders.photos.evaluation')}
        />
      </>
    )
  }

  closeModalQrTerms = () => this.setState({ modalQrTerms: false })

  closeModalQrConformTerms = () => this.setState({ modalQrConformity: false })

  sectionQRCode = () => {
    const { t, serviceOrder, auth } = this.props

    return (
      <SectionQRCode
        t={t}
        openModalQrTerms={this.openModalQrTerms}
        openModalQrConformTerms={this.openModalQrConformTerms}
        serviceOrder={serviceOrder}
        auth={auth}
      />
    )
  }

  openModalQrTerms = async () => {
    const {
      auth: { user },
      getShortLink,
      serviceOrder,
    } = this.props

    await getShortLink(user.dsToken, {
      path: `terms-acceptance/${serviceOrder.encodedVaSlsOrdNr}/${serviceOrder.encodedPlntCd}`,
    })

    this.setState({ modalQrTerms: true })
  }

  openModalQrConformTerms = () => {
    const {
      auth: { user },
      getShortLink,
      serviceOrder,
    } = this.props

    getShortLink(user.dsToken, {
      path: `conformity/${serviceOrder.servcOrdSeq}`,
    }).then(() => this.setState({ modalQrConformity: true }))
  }

  handleSendMsg = () => {
    const {
      t,
      auth: { user },
      sendMsgTermsAcceptance,
      serviceOrder,
    } = this.props

    const { vaSlsOrdNr, plntCd, servcOrdSeq } = serviceOrder

    sendMsgTermsAcceptance(user.dsToken, { vaSlsOrdNr, plntCd, servcOrdSeq })
      .then(() => {
        this.closeModalQrTerms()
        renderToastSuccessMsg(t('send.msg.terms.success'))
      })
      .catch(() => renderToastWarn(t('sectionQRCode.request.error')))
  }

  handleSendMsgConformity = () => {
    const {
      t,
      auth: { user },
      sendMsgConformity,
      serviceOrder,
    } = this.props

    const { servcOrdSeq } = serviceOrder

    sendMsgConformity(user.dsToken, { servcOrdSeq })
      .then(() => {
        this.closeModalQrConformTerms()
        renderToastSuccessMsg(t('send.msg.terms.success'))
      })
      .catch(() => renderToastWarn(t('sectionQRCode.request.error')))
  }

  renderModalQRCode = () => {
    const { modalQrTerms } = this.state
    const { t, serviceOrder, qrcode } = this.props

    if (modalQrTerms) {
      return (
        <ModalQRCode
          {...qrcode}
          ordersCode={serviceOrder.servcOrdSeq}
          size='sm'
          servcOrdSeq={serviceOrder.servcOrdSeq}
          closeModal={this.closeModalQrTerms}
          vaSlsOrdNr={serviceOrder.vaSlsOrdNr}
          plntCd={serviceOrder.plntCd}
          t={t}
          sendMsg={this.handleSendMsg}
        />
      )
    }
  }

  renderModalConformityQRCode = () => {
    const { t, serviceOrder, qrcode } = this.props
    const { modalQrConformity } = this.state

    if (modalQrConformity) {
      return (
        <ModalQRCode
          {...qrcode}
          size='sm'
          servcOrdSeq={serviceOrder.servcOrdSeq}
          closeModal={this.closeModalQrConformTerms}
          t={t}
          sendMsg={this.handleSendMsgConformity}
          titleModal='title.modal.conformity'
        />
      )
    }
  }

  techReportValidation = () => {
    const { serviceOrder } = this.props

    const needTechnicalReport = needTechReport(serviceOrder.servcCatgryTypCd)

    const isFullFilled =
      serviceOrder.servcOrdStusCd === ServiceOrderStatusType.STATUS_REALIZADO_COM_SUCESSO.id ||
      serviceOrder.servcOrdStusCd === ServiceOrderStatusType.STATUS_ENCERRADO.id ||
      serviceOrder.servcOrdStusCd === ServiceOrderStatusType.NAO_CONFORME.id

    const hasTechReportOcurrency =
      serviceOrder.serviceOrderOccurrence.findIndex(
        (event) => event.servcOrdEvntStusInd === ServcOrdEvntStusType.TECH_REPORT.id,
      ) !== -1

    const showEvaluation = needTechnicalReport
      ? hasTechReportOcurrency && isFullFilled
      : isFullFilled

    return showEvaluation
  }

  validationComponentBudgetDistributed = (distributed) => {
    const { auth, serviceOrder, listBudgetsStore, listBudgetsClient } = this.props

    let isBudget = false
    if (this.handleCheckBudgetsServiceOrderEvents()) {
      isBudget = true
    }

    return (
      auth.user?.funcaoAcesso?.inFuncao85 &&
      isBudget &&
      !isEmpty(listBudgetsClient) &&
      !isEmpty(listBudgetsStore) &&
      listBudgetsClient.length === listBudgetsStore.length &&
      serviceOrder.servcOrdStusCd === ServiceOrderStatusType.STATUS_REALIZADO_COM_SUCESSO.id &&
      (distributed ? serviceOrder.lastEvntStusInd !== BUDGET_DISTRIBUTED : true)
    )
  }

  handleCheckBudgetsServiceOrderEvents() {
    const { serviceOrder } = this.props

    return (
      serviceOrder.lastEvntStusInd === BUDGET_WITHOUT_ANSWER ||
      serviceOrder.lastEvntStusInd === BUDGET_ORDERS ||
      serviceOrder.lastEvntStusInd === BUDGET_DISTRIBUTED ||
      serviceOrder.lastEvntStusInd === QUOTATION_REFUSED_BY_EXPIRATION_JOB
    )
  }

  warrantyConditions(os) {
    let warrantyShouldAppear = true

    const deniedCategories = [
      'STORE_SERVICE',
      'TECHNICAL_VISIT',
      'TECHNICAL_VISIT_PLANNED_FURNITURE',
      'TECHNICAL_VISIT_HIGH_POTENTIAL',
      'CUSTOM_FURNITURE_FREIGHT',
    ]

    deniedCategories.forEach((category) => {
      if (os.servcCatgryTypCd === ServcOrdCategoryType[category].id) warrantyShouldAppear = false
    })

    if (os.servcOrdStusCd !== ServiceOrderStatusType.STATUS_ENCERRADO.id)
      warrantyShouldAppear = false

    return warrantyShouldAppear
  }

  goToServiceOrder = (numberOs) =>
    window.location.replace(window.location.origin.concat(`/serviceOrder/edit/${numberOs}`))

  handleChangeStatusOrder = async (params) => {
    const {
      onChangeOrderStatus,
      auth: { user },
    } = this.props

    await onChangeOrderStatus(user.dsToken, params, this.onSuccess)
  }

  render() {
    const { currentStatus, oldStatus, realStatus } = this.state

    const {
      auth: {
        user,
        setupParameters: {
          acceptanceSalesConditions,
        },
      },
      t,
      serviceOrder,
      auth,
      setFormValue,
      register,
      values,
      dispatch,
      attachments,
      listBudgetsStore,
      listBudgetsClient,
      boxBudgetDistributedSelected,
      handleFetchStatuses,
      warrantyActivation,
      clearServiceOrderResponse,
      installersList,
      unnacomplishedReasonsList,
    } = this.props

    const { statuses, loading } = this.state

    const checkBudgetsEvents = this.handleCheckBudgetsServiceOrderEvents()

    let isBudget = false

    if (isEmpty(serviceOrder) || loading) {
      return this.renderLoading()
    }

    if (checkBudgetsEvents) {
      isBudget = true
    }

    const indStatusOrdemServicoOrigem = serviceOrder.servcOrdStusCd
    const hasPermissionToManageDocuments =
      (user.accessFunctionList && user.accessFunctionList?.includes(75)) ||
      user.accessFunctionList?.includes(112)

    const hasPermissionToShowBudgetList =
      user?.funcaoAcesso?.inFuncao85 &&
      user?.funcaoAcesso?.inFuncao85 === 1 &&
      isBudget &&
      serviceOrder.servcOrdStusCd === ServiceOrderStatusType.STATUS_REALIZADO_COM_SUCESSO.id

    const footerDisabled =
      this.isDisabled()

    const showDistributeButton =
      realStatus === ServiceOrderStatusType.STATUS_AGENDADA.id || this.canDistributePreScheduled()

    const showRescheduleButton =
      realStatus === ServiceOrderStatusType.STATUS_AGENDADA.id ||
      realStatus === ServiceOrderStatusType.STATUS_PRE_AGENDADA.id ||
      realStatus === ServiceOrderStatusType.STATUS_DISTRIBUIDA.id

    const formSectionProp = {
      ...this.state,
      ...this.props,
      serviceSpecialists: installersList,
      realStatus,
      warrantyActivation,
      showDistributeButton,
      showRescheduleButton,
      clearServiceOrderResponse,
      updateFields: this.updateFields,
      initialValues: this.getOSInitialValues(),
      serviceOrderStatuses: statuses,
      getServiceProviders: this.getServiceProviders,
      getServiceSpecialists: this.getServiceSpecialists,
      handleChangeStatus: this.handleChangeStatus,
      funcaoAcesso: user.funcaoAcesso,
      indStatusOrdemServicoOrigem,
      disabled: this.isDisabled(),
      setFormValue,
      register,
      handleFetchStatuses,
      oldStatus,
      getDate: this.getStateDate,
      setDate: this.setStateDate,
      getShift: this.getStateShift,
      setShift: this.setStateShift,
      disabledSpecialist: serviceOrder.editSpecialistOn,
      onSubmit: this.submitForm,
      handleObsChange: this.handleObsChange,
      onSubmitHeaderObs: this.onSubmitHeaderObs,
      serviceConditionAcceptance: acceptanceSalesConditions,
      update: this.afterUpdate,
      setCanSubmit: this.setCanSubmit,
      onSubmitItemObs: this.onSubmitItemObs,
      goToServiceOrder: this.goToServiceOrder,
      afterUpdate: this.afterUpdate,
      checkServiceOrder: this.checkServiceOrder,
      handleChangeStatusOrder: this.handleChangeStatusOrder,
      currentStatus,
      values,
    }

    return (
      <ErrorBoundary
        FallbackComponent={<PageErrorFallback t={t} />}
        onError={() => window.location.reload()}
      >
        <div className='service-order-edit'>
          <ServiceOrderProvider formSectionProp={formSectionProp}>
            <FormSection serviceOrderStatuses={statuses} />

            <section className='service-order-edit-body'>
              <ServicesList />

              {this.sectionQRCode()}
              {this.renderModalQRCode()}
              {this.renderModalConformityQRCode()}

              {hasPermissionToShowBudgetList && (
                <>
                  <BudgetList
                    t={t}
                    title={t('servc.ord.budget.component.title.client')}
                    buttonText={t('servc.ord.budget.component.title.client.button')}
                    files={listBudgetsClient}
                    deleteAtchmt={this.handleRemoveFileBudget}
                    handleSendFile={this.handleOpenModalFileBudget}
                    idFiles='listBudgetsClient'
                    idTypeFile={ATTACHMENT_CLASSIFICATION.BUDGET_ATTACHMENT_CUSTOMER}
                    blockButtonRemove={serviceOrder.lastEvntStusInd === BUDGET_ORDERS}
                  />
                  <BudgetList
                    t={t}
                    title={t('servc.ord.budget.component.title.store')}
                    buttonText={t('servc.ord.budget.component.title.store.button')}
                    files={listBudgetsStore}
                    deleteAtchmt={this.handleRemoveFileBudget}
                    handleSendFile={this.handleOpenModalFileBudget}
                    idFiles='listBudgetsStore'
                    idTypeFile={ATTACHMENT_CLASSIFICATION.BUDGET_ATTACHMENT_STORE}
                    blockButtonRemove={serviceOrder.lastEvntStusInd === BUDGET_ORDERS}
                  />
                </>
              )}

              {this.validationComponentBudgetDistributed(false) && (
                <BudgetDistributed
                  t={t}
                  budgetDistributed={this.handleBudgetADistributed}
                  block={serviceOrder.lastEvntStusInd === BUDGET_DISTRIBUTED}
                  boxSelected={boxBudgetDistributedSelected}
                />
              )}

              {this.validationComponentBudgetDistributed(true) && (
                <AcceptedBudgets
                  t={t}
                  serviceOrderOccurrenceQuotationRefusedByExpiration={
                    serviceOrder.serviceOrderOccurrenceQuotationRefusedByExpiration
                  }
                  budgetAccepted={this.handleBudgetAccepted}
                  unnacomplishedReasonsList={unnacomplishedReasonsList}
                  onChange={this.onChange}
                />
              )}

              {serviceOrder.servcOrdRmrkTxt && <Notes t={t} note={serviceOrder.servcOrdRmrkTxt} />}

              {serviceOrder.pyxisDesc && (
                <Notes t={t} note={serviceOrder.pyxisDesc} name={t('serviceOrders.pyxisDesc')} />
              )}

              {this.warrantyConditions(serviceOrder) && (
                <Grid fluid className='separator-top no-padding-top'>
                  <Row className='top-and-bottom-padding'>
                    <Col xs={12}>
                      <Warranty />
                    </Col>
                  </Row>
                </Grid>
              )}

              {hasPermissionToManageDocuments && (
                <ManageDocumentsContent
                  t={t}
                  attachments={attachments}
                  clickAction={this.openAttachmentModal}
                />
              )}

              <Occurrences
                t={t}
                onSubmit={this.updateServiceOccurrence}
                initialValues={{
                  servcOrdSeq: serviceOrder.servcOrdSeq,
                }}
                auth={auth}
                occurrences={serviceOrder.serviceOrderOccurrence}
                disabled={this.isDisabled()}
                serviceOrder={serviceOrder}
                dispatch={dispatch}
              />

              {serviceOrder.images !== null && this.renderServiceOrderImages(serviceOrder.images)}

              {this.shouldRenderShowDocumentsButton() && (
                <ShowDocumentsButton t={t} clickAction={this.openDocumentsModal} />
              )}

              {serviceOrder.descLaudoTecnico && (
                <TechnicalReportDesc clickAction={this.openLaudoModal} t={t} />
              )}

              {this.uploadBudgetFile()}
            </section>
            <Footer
              t={t}
              auth={auth}
              serviceOrder={serviceOrder}
              totalValue={
                serviceOrder.servcOrdAmt
              }
              disabled={footerDisabled}
              save={this.save}
            />

            {this.renderServiceOrderModal()}
            {this.renderConfirmModal()}
            {this.renderBudgetDistributedModal()}
          </ServiceOrderProvider>
        </div>
      </ErrorBoundary>
    )
  }
}

const mapStateToProps = ({ serviceOrderReducer, auth }) => ({
  auth,
  serviceOrder: serviceOrderReducer.get.record,
  serviceOrderEdit: serviceOrderReducer.edit,
  disabled: serviceOrderReducer.get.disabled,
  changeObsResult: serviceOrderReducer.changeObsResult,
  attachments: serviceOrderReducer.atchmt.list,
  attachmentsMessage: serviceOrderReducer.atchmt.message,
  atchmt: serviceOrderReducer.atchmt,
  qrcode: serviceOrderReducer.qrcode,
  optionBudgetAccepted: serviceOrderReducer.optionBudgetAccepted,
  listBudgetsClient: serviceOrderReducer.atchmtBudgetClient.list,
  listBudgetsStore: serviceOrderReducer.atchmtBudgetStore.list,
  boxBudgetDistributedSelected: serviceOrderReducer.boxBudgetDistributedSelected,
  isFetchingOrder: serviceOrderReducer.get.isFetching,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...serviceOrderActions,
      ...serviceOccurrenceActions,
      dispatch,
    },
    dispatch,
  )

const ServiceOrderEditTranslated = withTranslation()(ServiceOrderEdit)

const ServiceOrderEditConnected = WithContext(
  connect(mapStateToProps, mapDispatchToProps)(ServiceOrderEditTranslated),
)

ServiceOrderEdit.propTypes = {
  t: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  serviceOrder: PropTypes.object.isRequired,
  updateServiceOrder: PropTypes.func.isRequired,
  getServiceOrderError: PropTypes.func.isRequired,
  fetchServiceProviderAutocomplete: PropTypes.func.isRequired,
  fetchServiceSpecialistList: PropTypes.func.isRequired,
  serviceProviders: PropTypes.array,
  installersList: PropTypes.array,
  updateServiceOccurrence: PropTypes.func.isRequired,
  serviceOrderEdit: PropTypes.object.isRequired,
  clearServiceOrderResponse: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  fetchServiceOrderWithReport: PropTypes.func.isRequired,
  fetchServiceOrderImages: PropTypes.func.isRequired,
  fetchServiceOrderAttachment: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  setFormValue: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object,
  params: PropTypes.object,
  getServiceOrder: PropTypes.func.isRequired,
  mergeWithServiceOrder: PropTypes.func.isRequired,
  changeObsResult: PropTypes.object.isRequired,
  changeObs: PropTypes.func.isRequired,
  changeItemObs: PropTypes.func.isRequired,
  resetChangeObs: PropTypes.func.isRequired,
  attachments: PropTypes.array,
  error: PropTypes.object,
  saveServcOrdFiles: PropTypes.func.isRequired,
  deleteServcOrdFiles: PropTypes.func.isRequired,
  attachmentsMessage: PropTypes.object,
  sendMsgTermsAcceptance: PropTypes.func,
  sendMsgConformity: PropTypes.func,
  qrcode: PropTypes.object,
  getShortLink: PropTypes.func,
  fetchUnnacomplishedReasonsList: PropTypes.func,
  unnacomplishedReasonsList: PropTypes.array,
  optionBudgetAccepted: PropTypes.string,
  listBudgetsClient: PropTypes.array,
  listBudgetsStore: PropTypes.array,
  deleteServcOrdFilesBudgets: PropTypes.func.isRequired,
  saveServcOrdFilesBudgets: PropTypes.func,
  handleAcceptedBudgets: PropTypes.func.isRequired,
  handleDaniedBudgets: PropTypes.func.isRequired,
  handleAcceptedBudgetsClear: PropTypes.func.isRequired,
  handleBudgetADistributed: PropTypes.func.isRequired,
  handleSelectedBudgetDistributed: PropTypes.func.isRequired,
  boxBudgetDistributedSelected: PropTypes.bool,
  fetchReasonsList: PropTypes.func,
  reasons: PropTypes.array,
  warrantyActivation: PropTypes.func,
  isFetchingOrder: PropTypes.bool,
  onChangeOrderStatus: PropTypes.func,
  handleFetchStatuses: PropTypes.func,
  match: PropTypes.object,
  register: PropTypes.func,
  clickAction: PropTypes.func,
  fetchServiceOrderAttachmentBudgets: PropTypes.func,
}

export default ServiceOrderEditConnected

export { ServiceOrderEdit }

function TechnicalReportDesc(props) {
  const { t, clickAction } = props

  return (
    <Grid fluid>
      <Row className='padding'>
        <Col xs={12} className='button-align'>
          <button className='button button-primary button-full' type='button' onClick={clickAction}>
            {t('serviceOrders.laudo')}
          </button>
        </Col>
      </Row>
    </Grid>
  )
}

TechnicalReportDesc.propTypes = {
  t: PropTypes.func.isRequired,
  clickAction: PropTypes.func.isRequired,
}

function ShowDocumentsButton(props) {
  const { t, clickAction } = props

  return (
    <Grid fluid>
      <Row className='padding'>
        <Col xs={12} className='button-align'>
          <button className='button button-primary button-full' type='button' onClick={clickAction}>
            {t('serviceOrders.show-documents')}
          </button>
        </Col>
      </Row>
    </Grid>
  )
}

ShowDocumentsButton.propTypes = {
  t: PropTypes.func.isRequired,
  clickAction: PropTypes.func.isRequired,
}

function ManageDocumentsContent(props) {
  const { t, attachments, clickAction } = props

  return (
    <Grid fluid>
      <Row>
        <Col xs={12} className='button-align separator no-padding-side'>
          <button className='button button-primary' type='button' onClick={clickAction}>
            {t('option.manageDocuments')}
          </button>
          <span className='padding'>
            {`(${attachments ? attachments.length : '0'} ${t('servc.ord.select.attachments')})`}
          </span>
        </Col>
      </Row>
    </Grid>
  )
}

ManageDocumentsContent.propTypes = {
  t: PropTypes.func.isRequired,
  attachments: PropTypes.array,
  clickAction: PropTypes.func.isRequired,
}
