const IndicatorType = {
  WAITING_FOR_EVALUATION: 'WAITING_FOR_EVALUATION',
  WAITING_FOR_QUOTATION: 'WAITING_FOR_QUOTATION',
  COORDINATOR_ATTENTION: 'COORDINATOR_ATTENTION',
  ALMOST_LATE_SERVICES: 'ALMOST_LATE_SERVICES',
  ORDERS_WAITING_FOR_MEASUREMENT_SHEET_VALIDATION:
    'ORDERS_WAITING_FOR_MEASUREMENT_SHEET_VALIDATION',
  SERVICES_ONTIME: 'SERVICES_ONTIME',
  SERVICES_ONTIME_CONFIRMED: 'SERVICES_ONTIME_CONFIRMED',
  SERVICES_ONTIME_IN_PROGRESS: 'SERVICES_ONTIME_IN_PROGRESS',
  ORDERS_WAITING_FOR_MEASUREMENT_SHEET: 'ORDERS_WAITING_FOR_MEASUREMENT_SHEET',
  EMERGENCY_EXPIRED: 'EMERGENCY_EXPIRED',
  BUDGET_PENDING: 'BUDGET_PENDING',
  TODAY_LATE: 'TODAY_LATE',
  PRE_SCHEDULED: 'PRE_SCHEDULED',
  ASSOCIATED: 'ASSOCIATED',
  SCHEDULED: 'SCHEDULED',
  REALIZED: 'REALIZED',
  DOING: 'DOING',
  OPEN_REWORK: 'OPEN_REWORK',
  WAITING_BUDGET: 'WAITING_BUDGET',
  RECOMMEND_INSPECTION: 'RECOMMEND_INSPECTION',
  ATTENTION_TO_DELIVERY: 'ATTENTION_TO_DELIVERY',
  NEGATIVE_OV: 'NEGATIVE_OV',
}

export default IndicatorType
