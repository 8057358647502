import i18next from 'i18next'
import { ATTACHMENT_CLASSIFICATION } from '../../../constants/config'
import API_URL from '../../../core/Envs/endpoints'
import Api from '../../../core/Http/FetchAdapter'
import { renderToastSuccessMsg, renderToastWarn } from '../../../helpers/ToastUtils'
import { hideSpinner, showSpinner } from '../spinner'

import {
  BACK_STATUS_WITH_OCCURRENCE_ERROR,
  BACK_STATUS_WITH_OCCURRENCE_REQUEST,
  BACK_STATUS_WITH_OCCURRENCE_SUCCESS,
  CANCEL_SERVICE_ORDER_ERROR,
  CANCEL_SERVICE_ORDER_REQUEST,
  CANCEL_SERVICE_ORDER_SUCCESS,
  CLEAR_SERVICE_ORDER_LIST,
  CLEAR_SERVICE_ORDER_RESPONSE,
  CLEAR_SERVICE_ORDER_REWORK,
  DELETE_SERVC_ORD_ATCHMT_BUDGET_CLIENT_ERROR,
  DELETE_SERVC_ORD_ATCHMT_BUDGET_CLIENT_SUCCESS,
  DELETE_SERVC_ORD_ATCHMT_BUDGET_REQUEST,
  DELETE_SERVC_ORD_ATCHMT_BUDGET_STORE_ERROR,
  DELETE_SERVC_ORD_ATCHMT_BUDGET_STORE_SUCCESS,
  DELETE_SERVC_ORD_ATCHMT_ERROR,
  DELETE_SERVC_ORD_ATCHMT_REQUEST,
  DELETE_SERVC_ORD_ATCHMT_SUCCESS,
  FETCH_SERVC_ORD_ATCHMT_ERROR,
  FETCH_SERVC_ORD_ATCHMT_REQUEST,
  FETCH_SERVC_ORD_ATCHMT_SUCCESS,
  FETCH_SERVC_ORD_MEASUREMENT_FILES_SUCCESS,
  FETCH_SERVICE_ORDERS_ERROR,
  FETCH_SERVICE_ORDERS_FROM_MENU,
  FETCH_SERVICE_ORDERS_FROM_SOMEWHERE_ELSE,
  FETCH_SERVICE_ORDERS_REQUEST,
  FETCH_SERVICE_ORDERS_SUCCESS,
  FETCH_SERVICE_ORDER_IMAGES_SUCCESS,
  FETCH_SERVICE_ORDER_WITH_REPORT_SUCCESS,
  FIND_SHORT_LINK_ERROR,
  FIND_SHORT_LINK_REQUEST,
  FIND_SHORT_LINK_SUCCESS,
  GENERATE_OCCURRENCE_ERROR,
  GENERATE_OCCURRENCE_REQUEST,
  GENERATE_OCCURRENCE_SUCCESS,
  GET_SERVICE_ORDER_ERROR,
  GET_SERVICE_ORDER_FROM_LIST_ERROR,
  GET_SERVICE_ORDER_REQUEST,
  GET_SERVICE_ORDER_SUCCESS,
  IS_BACK_ORDER,
  LINK_SERVICE_ORDERS_FILTERS,
  MERGE_WITH_SERVICE_ORDER,
  RESET_EDIT_SERVICE_ORDER,
  RESET_SERVICE_ORDERS,
  SAVE_FILTERS,
  SAVE_SERVC_ORD_ATCHMT_BUDGET_CLIENT_ERROR,
  SAVE_SERVC_ORD_ATCHMT_BUDGET_CLIENT_SUCCESS,
  SAVE_SERVC_ORD_ATCHMT_BUDGET_REQUEST,
  SAVE_SERVC_ORD_ATCHMT_BUDGET_STORE_ERROR,
  SAVE_SERVC_ORD_ATCHMT_BUDGET_STORE_SUCCESS,
  SAVE_SERVC_ORD_ATCHMT_ERROR,
  SAVE_SERVC_ORD_ATCHMT_REQUEST,
  SAVE_SERVC_ORD_ATCHMT_SUCCESS,
  SAVE_SO_SCROLL_POSITION,
  SEND_MSG_CONFORMITY_REQUEST,
  SEND_MSG_TERMS_ERROR,
  SEND_MSG_TERMS_REQUEST,
  SEND_MSG_TERMS_SUCCESS,
  SERVC_ORD_BUDGETS_ACCEPTED_OPTION,
  SERVC_ORD_BUDGETS_DENIED_OPTION,
  SERVC_ORD_BUDGETS_DENIED_OPTION_CLEAR,
  SERVC_ORD_BUDGETS_DISTRIBUTED_NOT_SELECTED,
  SERVC_ORD_BUDGETS_DISTRIBUTED_SELECTED,
  SET_SERVICE_ORDER,
  SHOULD_RESTORE_SCROLL,
  UPDATE_OS_MENU_SHORTCUT_ERROR,
  UPDATE_OS_MENU_SHORTCUT_REQUEST,
  UPDATE_OS_MENU_SHORTCUT_SUCCESS,
  UPDATE_SERVC_ORD_OBSERVATION_ERROR,
  UPDATE_SERVC_ORD_OBSERVATION_REQUEST,
  UPDATE_SERVC_ORD_OBSERVATION_RESET,
  UPDATE_SERVC_ORD_OBSERVATION_SUCCESS,
  UPDATE_SERVICE_ORDER_ERROR,
  UPDATE_SERVICE_ORDER_REQUEST,
  UPDATE_SERVICE_ORDER_SUCCESS,
  VIEW_SERVICE_ORDER_FROM_LIST,
} from '../../actionTypes/serviceOrder'

import history from '../../../helpers/history'

import { actionSet, reject, resolve } from '../../../helpers/reduxHelpers'
import { GET_PAGES } from '../../actionTypes/pagination'

const getPages = (metadata = {}) => ({
  type: GET_PAGES,
  pages: metadata.pageCount,
  current: metadata.page,
  count: metadata.totalCount,
})

export const returnServiceOrder = (token, servcOrdCd) => (dispatch) => {
  dispatch(showSpinner())

  return new Api(token, dispatch)
    .get(API_URL.SERVICE_ORDER_FIND, { servcOrdCd })
    .then((response) => Promise.resolve(response.data))
    .finally(() => dispatch(hideSpinner()))
}

/*
 * REQUEST SERVICE_ORDERS
 */

export const fetchServiceOrdersRequest = () => ({
  type: FETCH_SERVICE_ORDERS_REQUEST,
})

export const fetchServiceOrderFromSomewhereElse = () => ({
  type: FETCH_SERVICE_ORDERS_FROM_SOMEWHERE_ELSE,
})

export const serviceOrdersSuccess = (data, params) => ({
  type: FETCH_SERVICE_ORDERS_SUCCESS,
  payload: data,
  filter: params,
})

export const updateMissionRequest = (data) => ({
  type: UPDATE_SERVICE_ORDER_REQUEST,
  payload: data,
})

export const updateMissionSuccess = (data, onSuccess) => {
  onSuccess(data)

  return {
    type: UPDATE_SERVICE_ORDER_SUCCESS,
    payload: data,
  }
}

export const updateMissionError = (error) => ({
  type: UPDATE_SERVICE_ORDER_ERROR,
  payload: error,
})

const onFetchServiceOrdersSuccess = (data, dispatch, query) => {
  if (data) {
    dispatch(serviceOrdersSuccess(data, query))
  }
}

export const fetchServiceOrders =
  (token, fields, page = 1) =>
  (dispatch) => {
    dispatch(fetchServiceOrdersRequest())
    dispatch(fetchServiceOrderFromSomewhereElse())

    const metadata = {
      page,
      size: 10,
    }

    const query = {
      ...fields,
      hasFilters: true,
    }

    return new Api(token, dispatch, metadata)
      .get(API_URL.SERVICE_ORDER_FIND, query)
      .then(dispatch(showSpinner()))
      .then((response) => {
        dispatch(getPages(response.metadata))
        onFetchServiceOrdersSuccess(response.data, dispatch, query)
        return Promise.resolve(response.data)
      })
      .catch((error) => reject(dispatch, FETCH_SERVICE_ORDERS_ERROR, error))
      .finally(() => dispatch(hideSpinner()))
  }

/*
 * REQUEST SERVICE_ORDERS
 */
export const fetchServiceOrdersV2 =
  (token, fields, page = 1) =>
  (dispatch) => {
    dispatch(fetchServiceOrdersRequest())
    dispatch(fetchServiceOrderFromSomewhereElse())

    const metadata = {
      page,
      size: 10,
    }

    const query = {
      ...fields,
      hasFilters: true,
    }

    return new Api(token, dispatch, metadata)
      .get(API_URL.SERVICE_ORDER_FIND_V2, query)
      .then(dispatch(showSpinner(API_URL.SERVICE_ORDER_FIND_V2)))
      .then((response) => {
        dispatch(getPages(response.metadata))
        onFetchServiceOrdersSuccess(response.data, dispatch, query)
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        dispatch(getPages({ pageCount: 0, page: 1, totalCount: 0, size: 0 }))
        reject(dispatch, FETCH_SERVICE_ORDERS_ERROR, error)
      })
      .finally(() => dispatch(hideSpinner(API_URL.SERVICE_ORDER_FIND_V2)))
  }

/*
 * SAVE or UPDATE SERVICE_ORDER
 */
export const updateServiceOrder = (token, values, onSuccess) => (dispatch, getState) => {
  const { auth } = getState()

  dispatch(updateMissionRequest())
  const URL = API_URL.SERVICE_ORDER_UPDATE

  const fields = {
    ...values,
    updateIdb: auth.setupParameters.updateIdb,
  }

  return new Api(token, dispatch)
    .post(URL, fields)
    .then(dispatch(showSpinner()))
    .then((response) => {
      dispatch(updateMissionSuccess(response.data, onSuccess))
      dispatch(hideSpinner())
    })
    .catch((error) => {
      dispatch(hideSpinner())
      dispatch(updateMissionError(error))
    })
}

/*
 * GET SERVICE ORDER
 */
export const getServiceOrder = (token, filters) => (dispatch) => {
  dispatch(actionSet(GET_SERVICE_ORDER_REQUEST))

  return new Api(token, dispatch)
    .get(API_URL.SERVICE_ORDER_FIND, filters)
    .then(dispatch(showSpinner(API_URL.SERVICE_ORDER_FIND)))
    .then((response) => {
      resolve(dispatch, GET_SERVICE_ORDER_SUCCESS, response.data)
      return response.data
    })
    .catch((error) => reject(dispatch, GET_SERVICE_ORDER_ERROR, error))
    .finally(() => dispatch(hideSpinner(API_URL.SERVICE_ORDER_FIND)))
}

/*
 * SET SERVICE ORDER
 */
export const setServiceOrder = (serviceOrder) => (dispatch) => {
  dispatch({
    type: SET_SERVICE_ORDER,
    payload: serviceOrder,
  })
}

export const mergeWithServiceOrder = (newData) => (dispatch) => {
  dispatch({
    type: MERGE_WITH_SERVICE_ORDER,
    payload: newData,
  })
}

/*
 * VIEW SERVICE_ORDER FROM LIST
 */
export const viewServiceOrder = (index) => (dispatch) => {
  dispatch({
    type: VIEW_SERVICE_ORDER_FROM_LIST,
    index,
  })

  history.push(`/serviceOrder/view/${index}`)
  window.scrollTo(0, 0)
}

/*
 * ERROR GET SERVICE_ORDER FROM LIST
 */
export const getServiceOrderError = () => (dispatch) => {
  dispatch({
    type: GET_SERVICE_ORDER_FROM_LIST_ERROR,
  })

  history.push(`/serviceOrder`)
}

/*
 * REMOVE SERVICE_ORDER
 */
export const remove = (token, serviceOrder) => (dispatch) =>
  new Api(token, dispatch)
    .post(API_URL.SERVICE_ORDER_REMOVE, serviceOrder)
    .then(dispatch(showSpinner()))
    .then(() => {
      dispatch(hideSpinner())
      history.push(`/serviceOrder`)
    })
    .catch((error) => {
      dispatch(hideSpinner())
      dispatch(error)
    })

export const resetServiceOrders = () => ({
  type: RESET_SERVICE_ORDERS,
})

export const resetEditServiceOrders = () => ({
  type: RESET_EDIT_SERVICE_ORDER,
})

export const saveFilters = (filters) => ({
  type: SAVE_FILTERS,
  payload: filters,
})

export const isBackOrder = (isBackOrder) => (dispatch) => {
  dispatch({
    type: IS_BACK_ORDER,
    payload: isBackOrder,
  })
}
export const clearServiceOrderResponse = () => ({
  type: CLEAR_SERVICE_ORDER_RESPONSE,
})

export const clearServiceOrderRework = () => ({
  type: CLEAR_SERVICE_ORDER_REWORK,
})

export const clearServciOrderList = () => ({
  type: CLEAR_SERVICE_ORDER_LIST,
})

export const fetchServiceOrderWithReportSuccess = (data) => ({
  type: FETCH_SERVICE_ORDER_WITH_REPORT_SUCCESS,
  payload: data,
})

export const fetchServiceOrderWithReport =
  (token, servcOrdSeq, custIdNr) => (dispatch) => {
    const query = {
      servcOrdSeq,
      custIdNr,
    }

    return new Api(token, dispatch)
      .get(API_URL.FIND_SERVICE_ORDER_WITH_REPORT, query)
      .then(dispatch(showSpinner()))
      .then((response) => {
        dispatch(fetchServiceOrderWithReportSuccess(response.data))
        dispatch(hideSpinner())
      })
      .catch(() => {
        dispatch(fetchServiceOrderWithReportSuccess(null))
        dispatch(hideSpinner())
      })
  }

const saveServcOrdFilesRequest = () => ({
  type: SAVE_SERVC_ORD_ATCHMT_REQUEST,
})

const saveServcOrdFilesSuccess = (response) => ({
  type: SAVE_SERVC_ORD_ATCHMT_SUCCESS,
  payload: response,
})

const saveServcOrdFilesError = (error) => ({
  type: SAVE_SERVC_ORD_ATCHMT_ERROR,
  payload: error,
})

const fetchServiceOrderAttachmentRequest = () => ({
  type: FETCH_SERVC_ORD_ATCHMT_REQUEST,
})

const fetchServiceOrderAttachmentSuccess = (data) => ({
  type: FETCH_SERVC_ORD_ATCHMT_SUCCESS,
  payload: data,
})

const fetchServiceOrderAttachmentError = (error) => ({
  type: FETCH_SERVC_ORD_ATCHMT_ERROR,
  payload: error,
})

export const fetchServiceOrderAttachment = (token, id) => (dispatch) => {
  dispatch(fetchServiceOrderAttachmentRequest())
  const query = {
    servcOrdSeq: id,
    servcOrdItemAtchmtClsfctnCd: [3, 8, 9, 10, 11],
    actvServcOrdItemAtchmtInd: 1,
  }
  return new Api(token, dispatch)
    .get(API_URL.FIND_ORDER_SERVC_ATTACHMENT, query)
    .then(dispatch(showSpinner()))
    .then(
      (response) => {
        dispatch(fetchServiceOrderAttachmentSuccess(response.data))
      },
      (error) => {
        dispatch(fetchServiceOrderAttachmentError(error))
      },
    )
    .finally(() => {
      dispatch(hideSpinner())
    })
}

export const saveServcOrdFiles = (token, values) => async (dispatch) => {
  dispatch(showSpinner())
  dispatch(saveServcOrdFilesRequest())

  try {
    const response = await new Api(token, dispatch).post(API_URL.ORDER_SERVC_ATTACHMENT, values)

    await dispatch(saveServcOrdFilesSuccess(response))
    renderToastSuccessMsg(response.message.dsMessage)
    await dispatch(fetchServiceOrderAttachment(token, values.servcOrdSeq))
  } catch (error) {
    dispatch(saveServcOrdFilesError(error))
  } finally {
    dispatch(hideSpinner())
  }
}

export const addReworkValue = (token, numberOs, param) => (dispatch) => {
  dispatch(showSpinner())
  return new Api(token, dispatch)
    .put(API_URL.ADD_REWORK_VALUE.replace('{numberOs}', numberOs), param)
    .finally(() => dispatch(hideSpinner()))
}

export const warrantyActivation = (token, params) => (dispatch) => {
  dispatch(showSpinner())
  return new Api(token, dispatch)
    .post(API_URL.WARRANTY_ACTIVATION, params)
    .finally(() => dispatch(hideSpinner()))
}

const deleteServcOrdFilesRequest = () => ({
  type: DELETE_SERVC_ORD_ATCHMT_REQUEST,
})

const deleteServcOrdFilesSuccess = (response) => ({
  type: DELETE_SERVC_ORD_ATCHMT_SUCCESS,
  payload: response,
})

const deleteServcOrdFilesError = (error) => ({
  type: DELETE_SERVC_ORD_ATCHMT_ERROR,
  payload: error,
})

export const deleteServcOrdFiles = (token, values) => (dispatch) => {
  dispatch(deleteServcOrdFilesRequest())
  dispatch(showSpinner())

  return new Api(token, dispatch)
    .put(API_URL.ORDER_SERVC_ATTACHMENT, values)
    .then((response) => {
      dispatch(deleteServcOrdFilesSuccess(response))
      renderToastSuccessMsg(response.message.dsMessage)
    })
    .then(() => {
      dispatch(fetchServiceOrderAttachment(token, values.servcOrdSeq))
    })
    .catch((error) => {
      dispatch(deleteServcOrdFilesError(error))
    })
    .finally(() => {
      dispatch(hideSpinner())
    })
}

export const fetchServiceOrderMeasurementFiles = (token, id) => (dispatch) => {
  const query = {
    servcOrdSeq: id,
    servcOrdItemAtchmtClsfctnCd: [ATTACHMENT_CLASSIFICATION.MEASUREMENT_SHEET],
    actvServcOrdItemAtchmtInd: 1,
  }
  return new Api(token, dispatch)
    .get(API_URL.FIND_ORDER_SERVC_ATTACHMENT, query)
    .then(dispatch(showSpinner()))
    .then((response) => {
      dispatch({
        type: FETCH_SERVC_ORD_MEASUREMENT_FILES_SUCCESS,
        payload: response.data,
      })
    })
    .finally(() => {
      dispatch(hideSpinner())
    })
}

export const fetchServiceOrderImagesSuccess = (data) => ({
  type: FETCH_SERVICE_ORDER_IMAGES_SUCCESS,
  payload: data,
})

export const fetchServiceOrderFromMenu = () => ({
  type: FETCH_SERVICE_ORDERS_FROM_MENU,
})

export const fetchServiceOrderImages = (token, coCd, plntCd, servcOrdSeq) => (dispatch) => {
  const query = {
    coCd,
    plntCd,
    servcOrdSeq,
    fileTyp: 'img',
  }

  return new Api(token, dispatch)
    .get(API_URL.FIND_SERVICE_ORDER_IMAGES, query)
    .then(dispatch(showSpinner()))
    .then((response) => {
      dispatch(fetchServiceOrderImagesSuccess(response.data))
      dispatch(hideSpinner())
    })
    .catch(() => {
      dispatch(fetchServiceOrderImagesSuccess(null))
      dispatch(hideSpinner())
    })
}

export const linkServiceOrdersFilters =
  (filters, redirect = true) =>
  (dispatch) => {
    dispatch(fetchServiceOrderFromSomewhereElse())

    dispatch({
      type: LINK_SERVICE_ORDERS_FILTERS,
      payload: filters,
    })

    if (redirect) history.push(`/serviceOrder`)
  }

/*
 * BACK STATUS WITH OCCURRENCE
 */

export const backStatusWithOccurrenceRequest = () => ({
  type: BACK_STATUS_WITH_OCCURRENCE_REQUEST,
})

export const backStatusWithOccurrenceSuccess = (data, index) => {
  const payloadWithIndex = {
    ...data,
    index,
  }

  return {
    type: BACK_STATUS_WITH_OCCURRENCE_SUCCESS,
    payload: payloadWithIndex,
  }
}

export const backStatusWithOccurrenceError = (error) => ({
  type: BACK_STATUS_WITH_OCCURRENCE_ERROR,
  payload: error,
})

export const backStatusWithOccurrence = (token, values, index) => (dispatch) => {
  dispatch(backStatusWithOccurrenceRequest())

  return new Api(token, dispatch)
    .get(API_URL.BACK_STATUS_WITH_OCCURRENCE, values)
    .then(dispatch(showSpinner()))
    .then((response) => {
      dispatch(backStatusWithOccurrenceSuccess(response.data, index))
    })
    .catch((error) => {
      dispatch(backStatusWithOccurrenceError(error))
    })
    .finally(() => dispatch(hideSpinner()))
}

/*
 * UPDATE ORDER WITH MENU SHORTCUT
 */

export const updateOsMenuShorcutRequest = () => ({
  type: UPDATE_OS_MENU_SHORTCUT_REQUEST,
})

export const updateOsMenuShorcutSuccess = (data, index) => {
  const payloadWithIndex = {
    ...data,
    index,
  }

  return {
    type: UPDATE_OS_MENU_SHORTCUT_SUCCESS,
    payload: payloadWithIndex,
  }
}

export const updateOsMenuShorcutError = (error) => ({
  type: UPDATE_OS_MENU_SHORTCUT_ERROR,
  payload: error,
})

export const updateOsMenuShorcut =
  (token, values, index, success = null) =>
  (dispatch) => {
    dispatch(updateOsMenuShorcutRequest())

    return new Api(token, dispatch)
      .post(API_URL.SERVICE_ORDER_UPDATE, values)
      .then(dispatch(showSpinner()))
      .then((response) => {
        success && success(response.data)
        dispatch(updateOsMenuShorcutSuccess(response.data, index))
      })
      .catch((error) => {
        dispatch(updateOsMenuShorcutError(error))
      })
      .finally(() => dispatch(hideSpinner()))
  }

/*
 * UPDATE ORDER STATUS WITH MENU SHORTCUT
 */
export const updateOsStatusMenuShorcut =
  (token, values, index, success = null) =>
  (dispatch) => {
    dispatch(updateOsMenuShorcutRequest())

    return new Api(token, dispatch)
      .post(API_URL.SERVICE_ORDER_STATUS, values)
      .then(dispatch(showSpinner()))
      .then((response) => {
        success && success(response.data)
        dispatch(updateOsMenuShorcutSuccess(response.data, index))
      })
      .catch((error) => {
        dispatch(updateOsMenuShorcutError(error))
      })
      .finally(() => dispatch(hideSpinner()))
  }

/*
 * CANCEL SERVICE ORDER
 */

export const cancelServiceOrderRequest = () => ({
  type: CANCEL_SERVICE_ORDER_REQUEST,
})

export const cancelServiceOrderSuccess = (data, index) => {
  const payloadWithIndex = {
    ...data,
    index,
  }

  return {
    type: CANCEL_SERVICE_ORDER_SUCCESS,
    payload: payloadWithIndex,
  }
}

export const cancelServiceOrderError = (error) => ({
  type: CANCEL_SERVICE_ORDER_ERROR,
  payload: error,
})

export const cancelServiceOrder = (token, values, index) => (dispatch) => {
  dispatch(cancelServiceOrderRequest())

  return new Api(token, dispatch)
    .post(API_URL.SERVICE_ORDER_CANCEL, values)
    .then(dispatch(showSpinner()))
    .then((response) => {
      dispatch(cancelServiceOrderSuccess(response.data, index))
    })
    .catch((error) => {
      dispatch(cancelServiceOrderError(error))
    })
    .finally(() => dispatch(hideSpinner()))
}

export const generateOccurrenceRequest = () => ({
  type: GENERATE_OCCURRENCE_REQUEST,
})

export const generateOccurrenceSuccess = (data, index) => {
  const payloadWithIndex = {
    ...data,
    index,
  }

  return {
    type: GENERATE_OCCURRENCE_SUCCESS,
    payload: payloadWithIndex,
  }
}

export const generateOccurrenceError = (error) => ({
  type: GENERATE_OCCURRENCE_ERROR,
  payload: error,
})

export const generateOccurrenceSimple = (token, values, index) => (dispatch) => {
  dispatch(generateOccurrenceRequest())

  return new Api(token, dispatch)
    .post(API_URL.CREATE_OCCURRENCE_SIMPLE, values)
    .then(dispatch(showSpinner()))
    .then((response) => {
      dispatch(generateOccurrenceSuccess(response.data, index))
      renderToastSuccessMsg(i18next.t('serviceOrders.updated'))
    })
    .catch((error) => {
      dispatch(generateOccurrenceError(error))
    })
    .finally(() => dispatch(hideSpinner()))
}

export const generateOccurrence = (token, values, index) => (dispatch) => {
  dispatch(generateOccurrenceRequest())

  return new Api(token, dispatch)
    .post(API_URL.CREATE_OCCURRENCE, values)
    .then(dispatch(showSpinner()))
    .then((response) => {
      dispatch(generateOccurrenceSuccess(response.data, index))
    })
    .catch((error) => {
      dispatch(generateOccurrenceError(error))
    })
    .finally(() => dispatch(hideSpinner()))
}

/*

 * SCROLL
 */
export const saveScrollPosition = () => (dispatch) => {
  dispatch({
    type: SAVE_SO_SCROLL_POSITION,
    payload: document.documentElement.scrollTop || window.pageYOffset,
  })
}

export const shouldRestoreScroll = (should) => (dispatch) => {
  dispatch({
    type: SHOULD_RESTORE_SCROLL,
    payload: should,
  })
}

export const changeObsRequest = () => ({
  type: UPDATE_SERVC_ORD_OBSERVATION_REQUEST,
})

export const changeObsSuccess = (data) => ({
  type: UPDATE_SERVC_ORD_OBSERVATION_SUCCESS,
  payload: data,
})

export const changeObsError = (error) => ({
  type: UPDATE_SERVC_ORD_OBSERVATION_ERROR,
  payload: error,
})

export const changeObs = (token, values) => (dispatch) => {
  dispatch(changeObsRequest())

  return new Api(token, dispatch)
    .post(API_URL.SERVC_ORD_CHANGE_OBS, values)
    .then(dispatch(showSpinner()))
    .then((response) => {
      dispatch(changeObsSuccess(response.message))
    })
    .catch((error) => {
      dispatch(changeObsError(error))
    })
    .finally(() => dispatch(hideSpinner()))
}

export const changeItemObs = (token, values) => (dispatch) => {
  dispatch(changeObsRequest())

  return new Api(token, dispatch)
    .post(API_URL.SERVC_ORD_ITEM_CHANGE_OBS, values)
    .then(dispatch(showSpinner()))
    .then((response) => {
      dispatch(changeObsSuccess(response.message))
    })
    .catch((error) => {
      dispatch(changeObsError(error))
    })
    .finally(() => dispatch(hideSpinner()))
}

export const resetChangeObs = () => (dispatch) => {
  dispatch({ type: UPDATE_SERVC_ORD_OBSERVATION_RESET })
}

export const getShortLink = (token, data) => (dispatch) => {
  dispatch(showSpinner())
  dispatch(actionSet(FIND_SHORT_LINK_REQUEST))
  return new Api(token, dispatch)
    .get(API_URL.FIND_SHORT_LINK, data)
    .then(
      (response) => resolve(dispatch, FIND_SHORT_LINK_SUCCESS, response.data),
      (error) => reject(dispatch, FIND_SHORT_LINK_ERROR, error),
    )
    .finally(() => dispatch(hideSpinner()))
}

export const sendMsgTermsAcceptance = (token, data) => (dispatch) => {
  dispatch(showSpinner())
  dispatch(actionSet(SEND_MSG_TERMS_REQUEST))
  return new Api(token, dispatch)
    .get(API_URL.SEND_MSG_TERMS, data)
    .then(
      (response) => resolve(dispatch, SEND_MSG_TERMS_SUCCESS, response.data),
      (error) => reject(dispatch, SEND_MSG_TERMS_ERROR, error),
    )
    .finally(() => dispatch(hideSpinner()))
}

export const sendMsgConformity = (token, data) => (dispatch) => {
  dispatch(showSpinner())
  dispatch(actionSet(SEND_MSG_CONFORMITY_REQUEST))
  return new Api(token, dispatch)
    .get(API_URL.SEND_MSG_CONFORMITY, data)
    .then(renderToastSuccessMsg(i18next.t('send.msg.terms.success')))
    .catch((error) => {
      renderToastWarn(error)
    })
    .finally(() => dispatch(hideSpinner()))
}

export const saveServiceOrderFilesBudgets = (token, values) => {
  // eslint-disable-next-line no-param-reassign
  values.uploadFileFromMobile = 0
  return new Api(token).post(API_URL.BUDGET_ATTACHMENT, values)
}

export const deleteServiceOrderFilesBudgets = (token, values) => {
  // eslint-disable-next-line no-param-reassign
  values.servcOrdBudgAtchmtCd = values.servcOrdItemAtachmtId
  // eslint-disable-next-line no-param-reassign
  values.uploadFileFromMobile = 0
  return new Api(token).delete(API_URL.BUDGET_ATTACHMENT, values)
}

const deleteServcOrdFilesBudgetsRequest = () => ({
  type: DELETE_SERVC_ORD_ATCHMT_BUDGET_REQUEST,
})

const deleteServcOrdFilesBudgetsSuccess = (response, type) => ({
  type,
  payload: response.data,
})

const deleteServcOrdFilesBudgetsError = (error, type) => ({
  type,
  payload: error,
})

const saveServcOrdFilesBudgetsRequest = () => ({
  type: SAVE_SERVC_ORD_ATCHMT_BUDGET_REQUEST,
})

const saveServcOrdFilesBudgetsSuccess = (response, type) => ({
  type,
  payload: response.data,
})

const saveServcOrdFilesBudgetsError = (error, type) => ({
  type,
  payload: error,
})

export const fetchServiceOrderAttachmentBudgets = (token, id, type) => (dispatch) => {
  dispatch(fetchServiceOrderAttachmentRequest())
  const query = {
    servcOrdSeq: id,
    typeAtchmt: type,
  }

  return new Api(token, dispatch)
    .get(API_URL.BUDGET_ATTACHMENT, query)
    .then(dispatch(showSpinner()))
    .then(
      (response) => {
        if (type === ATTACHMENT_CLASSIFICATION.BUDGET_ATTACHMENT_CUSTOMER) {
          dispatch(
            deleteServcOrdFilesBudgetsSuccess(
              response,
              DELETE_SERVC_ORD_ATCHMT_BUDGET_CLIENT_SUCCESS,
            ),
          )
        } else {
          dispatch(
            deleteServcOrdFilesBudgetsSuccess(
              response,
              DELETE_SERVC_ORD_ATCHMT_BUDGET_STORE_SUCCESS,
            ),
          )
        }
      },
      (error) => {
        dispatch(fetchServiceOrderAttachmentError(error))
      },
    )
    .finally(() => {
      dispatch(hideSpinner())
    })
}

export const deleteServcOrdFilesBudgets = (token, values) => (dispatch) => {
  dispatch(deleteServcOrdFilesBudgetsRequest())
  // eslint-disable-next-line no-param-reassign
  values.servcOrdBudgAtchmtCd = values.servcOrdItemAtachmtId
  // eslint-disable-next-line no-param-reassign
  values.uploadFileFromMobile = 0
  return new Api(token, dispatch)
    .delete(API_URL.BUDGET_ATTACHMENT, values)
    .then(dispatch(showSpinner()))
    .then(
      () => {
        if (
          values.servcOrdItemAtchmtClsfctnCd ===
          ATTACHMENT_CLASSIFICATION.BUDGET_ATTACHMENT_CUSTOMER
        ) {
          dispatch(
            fetchServiceOrderAttachmentBudgets(
              token,
              values.servcOrdSeq,
              ATTACHMENT_CLASSIFICATION.BUDGET_ATTACHMENT_CUSTOMER,
            ),
          )
          dispatch(getServiceOrder(token, { servcOrdCd: values.servcOrdSeq }))
        } else {
          dispatch(
            fetchServiceOrderAttachmentBudgets(
              token,
              values.servcOrdSeq,
              ATTACHMENT_CLASSIFICATION.BUDGET_ATTACHMENT_STORE,
            ),
          )
          dispatch(getServiceOrder(token, { servcOrdCd: values.servcOrdSeq }))
        }
        renderToastSuccessMsg(i18next.t('documentRemove.removedSuccess'))
      },
      (error) => {
        if (
          values.servcOrdItemAtchmtClsfctnCd ===
          ATTACHMENT_CLASSIFICATION.BUDGET_ATTACHMENT_CUSTOMER
        ) {
          dispatch(
            deleteServcOrdFilesBudgetsError(error, DELETE_SERVC_ORD_ATCHMT_BUDGET_CLIENT_ERROR),
          )
        } else {
          dispatch(
            deleteServcOrdFilesBudgetsError(error, DELETE_SERVC_ORD_ATCHMT_BUDGET_STORE_ERROR),
          )
        }
      },
    )
    .finally(() => {
      dispatch(hideSpinner())
    })
}

export const saveServcOrdFilesBudgets = (token, values) => (dispatch) => {
  dispatch(saveServcOrdFilesBudgetsRequest())
  // eslint-disable-next-line no-param-reassign
  values.uploadFileFromMobile = 0
  return new Api(token, dispatch)
    .post(API_URL.BUDGET_ATTACHMENT, values)
    .then(dispatch(showSpinner()))
    .then(
      (response) => {
        if (
          values.servcOrdItemAtchmtClsfctnCd ===
          ATTACHMENT_CLASSIFICATION.BUDGET_ATTACHMENT_CUSTOMER
        ) {
          dispatch(
            saveServcOrdFilesBudgetsSuccess(response, SAVE_SERVC_ORD_ATCHMT_BUDGET_CLIENT_SUCCESS),
          )
          dispatch(
            fetchServiceOrderAttachmentBudgets(
              token,
              values.servcOrdSeq,
              ATTACHMENT_CLASSIFICATION.BUDGET_ATTACHMENT_CUSTOMER,
            ),
          )
          dispatch(getServiceOrder(token, { servcOrdCd: values.servcOrdSeq }))
        } else {
          dispatch(
            saveServcOrdFilesBudgetsSuccess(response, SAVE_SERVC_ORD_ATCHMT_BUDGET_STORE_SUCCESS),
          )
          dispatch(
            fetchServiceOrderAttachmentBudgets(
              token,
              values.servcOrdSeq,
              ATTACHMENT_CLASSIFICATION.BUDGET_ATTACHMENT_STORE,
            ),
          )

          renderToastSuccessMsg(i18next.t('imageTransfer.uploadSuccess'))
          dispatch(getServiceOrder(token, { servcOrdCd: values.servcOrdSeq }))
        }
      },
      (error) => {
        if (
          values.servcOrdItemAtchmtClsfctnCd ===
          ATTACHMENT_CLASSIFICATION.BUDGET_ATTACHMENT_CUSTOMER
        ) {
          dispatch(saveServcOrdFilesBudgetsError(error, SAVE_SERVC_ORD_ATCHMT_BUDGET_CLIENT_ERROR))
        } else {
          dispatch(saveServcOrdFilesBudgetsError(error, SAVE_SERVC_ORD_ATCHMT_BUDGET_STORE_ERROR))
        }
      },
    )
    .finally(() => {
      dispatch(hideSpinner())
    })
}

export const handleAcceptedBudgets = () => ({
  type: SERVC_ORD_BUDGETS_ACCEPTED_OPTION,
})

export const handleDaniedBudgets = () => ({
  type: SERVC_ORD_BUDGETS_DENIED_OPTION,
})

export const handleAcceptedBudgetsClear = () => ({
  type: SERVC_ORD_BUDGETS_DENIED_OPTION_CLEAR,
})

export const handleBudgetDistributedPromisse = (token, servcOrdSeq) => {
  const query = {
    servcOrdSeq,
  }
  return new Api(token).post(API_URL.BUDGET_DISTRIBUTED, query)
}

export const handleBudgetADistributed = (token, servcOrdSeq) => (dispatch) => {
  dispatch(saveServcOrdFilesBudgetsRequest())

  return handleBudgetDistributedPromisse(token, servcOrdSeq)
    .then(dispatch(showSpinner()))
    .then(() => {
      dispatch(getServiceOrder(token, { servcOrdCd: servcOrdSeq }))
    })
    .finally(() => {
      dispatch(hideSpinner())
    })
}

export const selectedBudgetDistributed = (type) => ({
  type,
})

export const handleSelectedBudgetDistributed = (type) => (dispatch) => {
  if (type) {
    dispatch(selectedBudgetDistributed(SERVC_ORD_BUDGETS_DISTRIBUTED_SELECTED))
  } else {
    dispatch(selectedBudgetDistributed(SERVC_ORD_BUDGETS_DISTRIBUTED_NOT_SELECTED))
  }
}

export const handleClosingNoConfirmity = (token, servcOrdSeq) => (dispatch) => {
  const query = { servcOrdSeq }

  return new Api(token, dispatch)
    .post(API_URL.SERVICE_ORDER_CLOSING_NO_CONFORMITY, query)
    .then(dispatch(showSpinner()))
    .catch((error) => {
      dispatch(hideSpinner())
      dispatch(error)
    })
    .finally(() => dispatch(hideSpinner()))
}

// Contact information Order
export const onSubmitContactInformation = (token, servcOrdSeq, params) => (dispatch) => {
  const URL = `${API_URL.UPDATE_CONTACT_INFORMATION}/${servcOrdSeq}`
  new Api(token, dispatch)
    .put(URL, params)
    .then(dispatch(showSpinner()))
    .then((response) => {
      renderToastSuccessMsg(response.success?.dsMessage || i18next.t('serviceOrders.saved'))
      dispatch(getServiceOrder(token, { servcOrdCd: servcOrdSeq }))
    })
    .catch((error) => {
      renderToastWarn(error?.message?.dsMessage || i18next.t('dao.save.error'))
    })
    .finally(() => dispatch(hideSpinner()))
}

/*
 * CREATE NEW BID
 */
export const onSubmitNewBid = (token, params) => (dispatch) =>
  new Api(token, dispatch)
    .post(API_URL.CREATE_BID, params)
    .then(dispatch(showSpinner()))
    .then(() => {
      renderToastSuccessMsg(i18next.t('serviceOrders.bid.saved'))
    })
    .catch((error) => {
      renderToastWarn(error?.message?.dsMessage || i18next.t('dao.save.error'))
    })
    .finally(() => dispatch(hideSpinner()))

/*
 * CHANGE ORDER STATUS
 */
export const onChangeOrderStatus = (token, params, onSuccess) => (dispatch) => {
  dispatch(showSpinner())

  return new Api(token, dispatch)
    .put(API_URL.BACK_TO_RESCHEDULED, params)
    .then((response) => {
      dispatch(updateMissionSuccess(response.data, onSuccess))
    })
    .catch((error) => {
      dispatch(updateMissionError(error))
    })
    .finally(() => dispatch(hideSpinner()))
}

export const downloadTechReport = (dispatch, serviceOrderSeq) => {
  dispatch(showSpinner())

  return new Api(null, dispatch)
    .getFile(`${API_URL.DOWNLOAD_SERVICE_ORDER_FILES}/${serviceOrderSeq}`)
    .catch((error) => error)
    .finally(() => dispatch(hideSpinner()))
}

export const getServiceOrderPromisse = (token, filters) =>
  new Api(token).get(API_URL.SERVICE_ORDER_FIND, filters)

export const updateServiceOrderPromisse = (token, values) => {
  const URL = API_URL.SERVICE_ORDER_UPDATE

  const fields = {
    ...values,
    // updateIdb: auth.setupParameters.updateIdb,
  }

  return new Api(token).post(URL, fields)
}
