export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const LOGOUT = 'LOGOUT'
export const LOGOUT_COMPLETE = 'LOGOUT_COMPLETE'
export const ACCESS_ALLOWED = 'ACCESS_ALLOWED'
export const ACCESS_NOT_ALLOWED = 'ACCESS_NOT_ALLOWED'
export const SESSION_EXPIRED = 'SESSION_EXPIRED'
export const CHANGE_TOKEN = 'CHANGE_TOKEN'
export const USER_MENU_SUCCESS = 'USER_MENU_SUCCESS'

export const SETUP_PARAMETERS_ERROR = 'SETUP_PARAMETERS_ERROR'
export const SETUP_PARAMETERS_SUCCESS = 'SETUP_PARAMETERS_SUCCESS'
