import i18next from 'i18next'
import { toast } from '../../../../leroy-merlin-br/backyard-react'

import API_REQUEST from '../../../core/Http/FetchAdapter'
import API_URL from '../../../core/Envs/endpoints'

import User from '../../../core/User'

import store from '../../../redux/store'

const user = new User().currentUser

export interface UpdateAddressParams {
  servcAddrPstlCd: string
  servcAddrStrNm: string
  servcAddrNr: string
  servcAddrCmplmtryTxt: string
  servcAddrRefPointDesc: string
  servcAddrDstrctNm: string
  servcAddrCityNm: string
  servcAddrStCd: string
}

export const updateAddress = async (params: UpdateAddressParams, servcOrdSeq: number) => {
  try {
    const { cdStatus } = await new API_REQUEST(user.dsToken, store.dispatch).patch(
      `${API_URL.V1_ORDERS}/${servcOrdSeq}`,
      params,
    )

    toast.success(
      i18next.t('success.text'),
      i18next.t('customer.service.order.save.address.success'),
      '',
    )

    return cdStatus
  } catch (error: any) {
    toast.error('', error?.message?.dsMessage || i18next.t('dao.save.error'), '')
    return console.error(error)
  }
}
