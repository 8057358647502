import { toast } from '../../../../../../../../leroy-merlin-br/backyard-react'
import i18next from 'i18next'
import { useState } from 'react'
import Modal from '../../../../../../../components/Modal'
import MozaicButton from '../../../../../../../components/MozaicButton'
import MozaicText from '../../../../../../../components/MozaicText'
import HttpStatusCode from '../../../../../../../enums/HttpStatusCode'
import { deleteServiceOrderAttachment } from '../../../../../../../services/pages/ServiceOrderDetail/DeleteAttachment'
import { ServiceOrderAttachment } from '../../../../../../../services/pages/ServiceOrderDetail/GetServiceOrderAttachments'

interface AttachmentDeleteModalProps {
  readonly isOpen: boolean
  readonly onClose: (attachmentIsDeleted?: boolean) => void
  readonly attachment: ServiceOrderAttachment
}

export default function AttachmentDeleteModal({
  isOpen,
  onClose,
  attachment,
}: AttachmentDeleteModalProps) {
  const [isDeleting, setIsDeleting] = useState(false)

  const handleSubmit = async () => {
    setIsDeleting(true)

    try {
      const { cdStatus } = await deleteServiceOrderAttachment(attachment.servcOrdItemAtachmtId)

      setIsDeleting(false)

      toast.success(
        i18next.t('success.text'),
        i18next.t('serviceOrder.attachments.deletedSuccessfully'),
        '',
      )

      if (cdStatus === HttpStatusCode.OK) {
        onClose(true)
      }
    } catch (error: any) {
      toast.error('', error?.message?.dsMessage || i18next.t('dao.save.error'), '')
    }
  }

  const getAttachmentTitle = () =>
    attachment.atchmtDocmntTypDesc || attachment.servcOrdItemAtchmtDesc
  const getExtension = () => attachment.fileTyp

  return (
    <Modal
      size='lg'
      isOpen={isOpen}
      onClose={() => onClose()}
      contentLabel='confirm.removal'
      className='delete-modal'
      closeInOverlay
    >
      <div id='attachment-delete-modal'>
        <MozaicText>
          {i18next.t('serviceOrder.attachments.delete.wantToDelete')}{' '}
          <b>
            {getAttachmentTitle()}.{getExtension()}
          </b>
          ?
        </MozaicText>

        <div className='actions'>
          <MozaicButton
            isDisabled={isDeleting}
            onClick={() => onClose()}
            variant='bordered'
            theme='primary-02'
          >
            {i18next.t('button.label.cancel')}
          </MozaicButton>
          <MozaicButton isDisabled={isDeleting} onClick={() => handleSubmit()}>
            {i18next.t('button.label.remove')}
          </MozaicButton>
        </div>
      </div>
    </Modal>
  )
}
