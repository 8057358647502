import { Text } from '../../../../../leroy-merlin-br/backyard-react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { BaseModal } from 'react-spring-modal'
import MozaicButton from '../../../../components/MozaicButton'
import MozaicIcon from '../../../../components/MozaicIcon'
import Spacer from '../../../../components/Spacer'
import { useSideNavContext } from '../../../../contexts/sidenav'
import { logoutUser } from '../../../../redux/actions/auth/logoutUser'
import store from '../../../../redux/store'
import BuCd from './components/BuCd'

export const RIGHT_MODAL_NAVBAR = {
  position: 'fixed',
  top: 92,
  right: 0,
  width: 360,
  maxWidth: 360,
  maxHeight: 574,
  background: '#ffffff',
  boxShadow: '0px 8px 8px 0px rgba(60, 55, 56, 0.07)',
  borderRadius: 6,
  zIndex: 10,
}

/**
 * @description Container with info of current user
 */

function ProfileInfo({ t, auth: user, auth }) {
  const {
    openedProfileInfo,
    handleCloseProfileInfo,
    branchesLabel,
    coresLabel,
    handleOpenBuCdModal,
  } = useSideNavContext()

  const { user: currentUser } = user

  const handleLogout = async () => {
    const payload = await logoutUser(auth.setupParameters.pingoneEnable, true)

    store.dispatch(payload)
  }

  return (
    <BaseModal
      isOpen={openedProfileInfo}
      onDismiss={() => handleCloseProfileInfo()}
      contentTransition={{
        from: { transform: 'translateX(100%)' },
        enter: { transform: 'translateX(0)' },
        leave: { transform: 'translateX(100%)' },
      }}
      contentProps={{ style: RIGHT_MODAL_NAVBAR }}
    >
      <section id='profile-info'>
        <header>
          <section className='photo'>
            <div className='photo-container'>
              <MozaicIcon icon='AccountProfileView48' className='profile-svg' />
            </div>
            <MozaicIcon icon='Camera32' className='switch-profile' />
            <section className='close'>
              <MozaicIcon icon='ControlCross32' onClick={() => handleCloseProfileInfo()} />
            </section>
          </section>
        </header>

        <Text className='username'>{currentUser?.fullName || '-'}</Text>
        <Text className='office'>{currentUser?.department || '-'}</Text>
        <Text className='email'>{currentUser?.email || '-'}</Text>
        <Text className='ldap'>{currentUser?.username || '-'}</Text>

        <BuCd handleOpenBuCdModal={handleOpenBuCdModal} />

        <Spacer />
        <Text className='cores'>{coresLabel}</Text>
        <Text className='stores'>{branchesLabel}</Text>
        <Text className='access-validity'>
          {t('access.validate')}: {t('undetermined.text')}
        </Text>

        <Spacer marginBottom='16px' />
        <MozaicButton size='s' variant='bordered' className='logout' onClick={handleLogout}>
          {t('option.exit')}
        </MozaicButton>
      </section>
    </BaseModal>
  )
}

ProfileInfo.propTypes = {
  t: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
}

const mapStateToProps = ({ auth }) => ({
  auth,
})

const ProfileInfoTranslated = withTranslation()(ProfileInfo)

export default connect(mapStateToProps)(ProfileInfoTranslated)
