import { memo } from 'react'

import { Text } from '../../../../../leroy-merlin-br/backyard-react'

import CounterContainer from '../../../../components/Counter'
import MozaicIcon from '../../../../components/MozaicIcon'
import { useSideNavContext } from '../../../../contexts/sidenav'
import StoreClustersModal from '../LocationModal'

function LocationContainer() {
  const { handleToggleStores, totalBranches, branchesLabel, coresLabel } =
    useSideNavContext()

  return (
    <div id='location-container'>
      <section className='store-info'>
        {coresLabel && <Text className='cores'>{coresLabel}</Text>}

        <Text className='branches ellipsis'>{branchesLabel}</Text>
      </section>

      <section
        className='icon-map'
        onClick={() => {
          handleToggleStores()
        }}
      >
        <MozaicIcon icon='MonochromeStoreLocation32' color='black' size={40} />
        <CounterContainer text={totalBranches || 0} theme='success' />
      </section>

      <StoreClustersModal />
    </div>
  )
}

export default memo(LocationContainer)
