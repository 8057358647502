import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'

import 'font-awesome/css/font-awesome.css'
import 'primereact/resources/primereact.min.css'
import 'primereact/resources/themes/omega/theme.css'
import 'react-dates/lib/css/_datepicker.css'
import 'react-phone-number-input/style.css'
import 'react-spring-modal/styles.css'
import './styles/fonts/LeroyMerlinSans.ttf'

import ptBR from 'date-fns/locale/pt-BR'
import localforage from 'localforage'
import { registerLocale, setDefaultLocale } from 'react-datepicker'
import { IS_PRODUCTION } from './constants/viteEnvironment'
import Main from './core/Main'

import '../leroy-merlin-br/lm-instala-components/dist/cjs/index.css'
import '@mozaic-ds/react/lib/index.css'
import 'garden/dist/css/garden.min.css'
import 'react-dates/initialize'
import 'react-loading-skeleton/dist/skeleton.css'

import './styles/main.scss'

registerLocale('pt-BR', ptBR)
setDefaultLocale('pt-BR')

if (IS_PRODUCTION) {
  fetch(`${window.location.origin}/manifest.json`)
    .then((response) => response.json())
    .catch((error) => {
      console.error('Error fetching manifest.json', error)
    })
}

localforage.config({
  name: 'lmInstala',
  version: 1.0,
  storeName: 'lmInstalaStore',
  description: 'Store of lmInstala',
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(<Main />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
