import { toast } from '../../../../../../../../../../leroy-merlin-br/backyard-react'
import i18next from 'i18next'
import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import Accordeon from '../../../../../../../../../components/Accordeon'
import MozaicIcon from '../../../../../../../../../components/MozaicIcon'
import {
  BUDGET_DISTRIBUTED,
  BUDGET_ORDERS,
  BUDGET_WITHOUT_ANSWER,
  QUOTATION_REFUSED_BY_EXPIRATION_JOB,
} from '../../../../../../../../../constants/config'
import User from '../../../../../../../../../core/User'
import ServcOrdCategoryType from '../../../../../../../../../enums/ServiceOrder/category/serviceCategoryType'
import ServiceOrderStatusType from '../../../../../../../../../enums/ServiceOrder/status/ServiceOrderStatusType'
import {
  deleteServiceOrderFilesBudgets,
  getServiceOrderPromisse,
  handleBudgetDistributedPromisse,
  saveServiceOrderFilesBudgets,
  updateServiceOrderPromisse,
} from '../../../../../../../../../redux/actions/serviceOrder'
import { hideSpinner, showSpinner } from '../../../../../../../../../redux/actions/spinner'
import store from '../../../../../../../../../redux/store'
import { downloadFile } from '../../../../../../../../../services/amazonAws/DownloadFile'
import {
  ServiceOrderAttachment,
  getServiceOrderAttachmentsDocuments,
} from '../../../../../../../../../services/pages/ServiceOrderDetail/GetServiceOrderAttachments'
import { getReasonsList } from '../../../../../../../../../services/pages/ServiceOrderDetail/getReasonsList'
import { ServiceOrderDetailStore } from '../../../../../../../context/models'
import { HistoricOccurrence } from '../../../../../Historic/components/HistoricOccurrences/models/historic-occurrence'
import { WithContext } from '../../context'
import AcceptedBudgets from '../AcceptedBudgets'
import BudgetDistributed from '../BudgetDistributed'
import BudgetFiles from '../BudgetFiles'
import ConfirmModal from '../ConfirmModal'

const user = new User().currentUser
const budgetEvents = ['26', '27', '28', '29', '76']
const accordeonHeader = {
  title: 'budget.details.title.text',
  icon: <MozaicIcon icon='Bill24' size={25} mr={-5} />,
}
const refuseBudgetReasonType = 11

function DetailsBudget({
  serviceOrderBody,
  loadingBody,
  loadingDetails,
  occurrences,
  fetchOccurrences,
  reloadServiceOrder,
}: ServiceOrderDetailStore) {
  const [showBudgetDistributedModal, setShowBudgetDistributedModal] = useState(false)
  const [showAcceptedQuotationModal, setShowAcceptedQuotationModal] = useState(false)
  const [showDeniedQuotationModal, setShowDeniedQuotationModal] = useState(false)
  const [documentsStore, setDocumentsStore] = useState<ServiceOrderAttachment[]>([])
  const [documentsClient, setDocumentsClient] = useState<ServiceOrderAttachment[]>([])
  const [removeBlocked, setRemoveBlocked] = useState<boolean>()
  const [distributedBlocked, setDistributedBlocked] = useState<boolean>()
  const [boxBudgetDistributedSelected, setBoxBudgetDistributedSelected] = useState<boolean>()
  const [isBudget, setIsBudget] = useState<boolean>()
  const [lastBudgetEvent, setLastBudgetEvent] = useState<number>(1)
  const [
    serviceOrderOccurrenceQuotationRefusedByExpiration,
    setServiceOrderOccurrenceQuotationRefusedByExpiration,
  ] = useState<HistoricOccurrence>()
  const [refuseBudgetsReasons, setRefuseBudgetsReasons] = useState<any[]>([])
  const [defaultSelectedAcceptedBudget, setDefaultSelectedAcceptedBudget] = useState<any>(null)
  const [selectedDeniedBudget, setSelectedDeniedBudget] = useState<any>(null)
  const [isLoadingFiles, setIsLoadingFiles] = useState<boolean>(true)
  const [isLoadingRemoveFile, setIsLoadingRemoveFile] = useState<boolean>(false)
  const [isLoadingAddFile, setIsLoadingAddFile] = useState<boolean>(false)

  const getLastBudgetEvent = (occurrences: Array<any>): number => {
    if (occurrences.length === 0) {
      return -1
    }

    const budgetOccurrences = occurrences.filter((occur) =>
      budgetEvents.some((cd) => cd === occur.servcOrdEvntStusInd),
    )
    return budgetOccurrences.length > 0 ? parseInt(budgetOccurrences[0].servcOrdEvntStusInd) : -1
  }

  const isBudgetServiceOrder = (lastBudgetEvent: number) =>
    lastBudgetEvent === BUDGET_WITHOUT_ANSWER ||
    lastBudgetEvent === BUDGET_ORDERS ||
    lastBudgetEvent === BUDGET_DISTRIBUTED ||
    lastBudgetEvent === QUOTATION_REFUSED_BY_EXPIRATION_JOB

  const getServiceOrderOccurrenceQuotationRefusedByExpiration = (occurrences: Array<any>) => {
    const occurs = occurrences.filter(
      (occur) =>
        occur.servcOrdEvntStusInd === QUOTATION_REFUSED_BY_EXPIRATION_JOB.toString() &&
        occur.nomUserRegistroOcor !== null &&
        occur.nomUserRegistroOcor !== undefined &&
        occur.nomUserRegistroOcor === 'thread',
    )
    return occurs.length > 0 ? occurs[0] : undefined
  }

  const handleOccurrences = (occurrences: Array<any>) => {
    const lastBudgetEvent = getLastBudgetEvent(occurrences)
    setRemoveBlocked(lastBudgetEvent !== BUDGET_ORDERS)
    setDistributedBlocked(lastBudgetEvent === BUDGET_DISTRIBUTED)
    setLastBudgetEvent(lastBudgetEvent)
    setIsBudget(isBudgetServiceOrder(lastBudgetEvent))
    setServiceOrderOccurrenceQuotationRefusedByExpiration(
      getServiceOrderOccurrenceQuotationRefusedByExpiration(occurrences),
    )
  }

  const fetchAndHandleOccurrences = async () => {
    const occurs = await fetchOccurrences()
    handleOccurrences(occurs)
  }

  const getDocuments = async (isToRefreshOccurrences = false) => {
    if (serviceOrderBody === null) {
      return
    }

    setIsLoadingFiles(true)
    setDocumentsStore([])
    setDocumentsClient([])

    try {
      const documentList = await getServiceOrderAttachmentsDocuments(
        serviceOrderBody.servcOrdSeq,
        [8, 9],
      )
      const docsClient = documentList.filter((d) => d.servcOrdItemAtchmtClsfctnCd === 8)
      const docsStore = documentList.filter((d) => d.servcOrdItemAtchmtClsfctnCd === 9)
      setDocumentsClient(docsClient)
      setDocumentsStore(docsStore)
      if (isToRefreshOccurrences) {
        await fetchAndHandleOccurrences()
      }
      setIsLoadingFiles(false)
    } catch (error: any) {
      const errorMsg =
        error?.message?.dsMessage || i18next.t('serviceOrder.attachments.documents.fetchError')
      toast.error('', errorMsg, '')
    }
  }

  const fetchRefuseBudgetsReasons = async () => {
    try {
      const response = await getReasonsList({ servcOrdEvntTyp: refuseBudgetReasonType })
      setRefuseBudgetsReasons(response.data)
    } catch (error: any) {
      const errorMsg = error?.message?.dsMessage || i18next.t('dao.save.error')
      toast.error('', errorMsg, '')
    }
  }

  useEffect(() => {
    getDocuments()
    fetchRefuseBudgetsReasons()
  }, [serviceOrderBody])

  useEffect(() => {
    fetchAndHandleOccurrences()
  }, [occurrences])

  const handleRemoveFileBudget = async (document: any) => {
    setIsLoadingRemoveFile(true)
    try {
      await deleteServiceOrderFilesBudgets(user.dsToken, document)
      toast.success('', i18next.t('documentRemove.removedSuccess'), '')
      setIsLoadingRemoveFile(false)
      getDocuments(true)
    } catch (error: any) {
      const errorMsg =
        error?.message?.dsMessage || i18next.t('serviceOrder.attachments.documents.fetchError')
      toast.error('', errorMsg, '')
    }
  }

  const handleDownloadFile = (file: any, name: any) => {
    downloadFile(file, name)
  }

  const hasPermissionToShowBudgetList = () =>
    user?.funcaoAcesso?.inFuncao85 &&
    user?.funcaoAcesso?.inFuncao85 === 1 &&
    serviceOrderBody?.servcCatgryTypCd === parseInt(ServcOrdCategoryType.BUDGET.id) &&
    (serviceOrderBody?.servcOrdStusCd === ServiceOrderStatusType.STATUS_REALIZADO_COM_SUCESSO.id ||
      serviceOrderBody?.servcOrdStusCd === ServiceOrderStatusType.STATUS_ENCERRADO.id ||
      serviceOrderBody?.servcOrdStusCd === ServiceOrderStatusType.STATUS_CANCELADA.id ||
      serviceOrderBody?.servcOrdStusCd === ServiceOrderStatusType.NAO_CONFORME.id)

  const validationComponentBudgetDistributed = (distributed: any) =>
    user?.funcaoAcesso?.inFuncao85 &&
    isBudget &&
    !isEmpty(documentsClient) &&
    !isEmpty(documentsStore) &&
    documentsClient.length === documentsStore.length &&
    serviceOrderBody?.servcOrdStusCd === ServiceOrderStatusType.STATUS_REALIZADO_COM_SUCESSO.id &&
    (distributed ? lastBudgetEvent !== BUDGET_DISTRIBUTED : true)

  const onCloseBudgetDistributedModal = () => {
    setShowBudgetDistributedModal(false)
  }

  const sendBudgetDistributed = async () => {
    try {
      await handleBudgetDistributedPromisse(user.dsToken, serviceOrderBody?.servcOrdSeq)
      toast.success('', i18next.t('servc.ord.budget.component.distributed.success'), '')
      await fetchAndHandleOccurrences()
      setBoxBudgetDistributedSelected(true)
      onCloseBudgetDistributedModal()
    } catch (error: any) {
      const errorMsg = error?.message?.dsMessage || i18next.t('dao.save.error')
      toast.error('', errorMsg, '')
    }
  }

  const renderBudgetDistributedModal = () => (
    <ConfirmModal
      handleClose={onCloseBudgetDistributedModal}
      handleRight={onCloseBudgetDistributedModal}
      handleLeft={sendBudgetDistributed}
      title='servc.ord.budget.component.distributed.modal.title'
      message='servc.ord.budget.component.title.distributed.budget.delete'
      leftBtnLabel='servc.ord.budget.component.distributed.modal.action.btn'
      rightBtnLabel='cancel_button_label'
      actionButton='left'
    />
  )

  const onCloseAcceptedQuotationModal = () => {
    setDefaultSelectedAcceptedBudget(null)
    setShowAcceptedQuotationModal(false)
  }

  const onCloseDaniedQuotationModal = () => {
    setSelectedDeniedBudget(null)
    setShowDeniedQuotationModal(false)
  }

  const sendAcceptedOrDeniedQuotation = async (accepted: boolean) => {
    try {
      store.dispatch(showSpinner())

      const serviceOrderRes = await getServiceOrderPromisse(user.dsToken, {
        servcOrdCd: serviceOrderBody?.servcOrdSeq,
      })
      const serviceOrder = serviceOrderRes.data[0]
      const toUpdate = {
        ...serviceOrder,
        optionBudgetAccepted: accepted,
        custBusnsPhonNr: serviceOrder.custBusnsPhonNr,
        custHmPhonNr: serviceOrder.custHmPhonNr,
        custMobilePhonNr: serviceOrder.custMobilePhonNr,
        servcAddrStrNm: serviceOrder.servcAddrStrNm,
        servcAddrNr: serviceOrder.servcAddrNr,
        servcAddrCmplmtryTxt: serviceOrder.servcAddrCmplmtryTxt,
        servcAddrDstrctNm: serviceOrder.servcAddrDstrctNm,
        servcAddrPstlCd: serviceOrder.servcAddrPstlCd,
        servcAddrCityNm: serviceOrder.servcAddrCityNm,
        servcAddrStCd: serviceOrder.servcAddrStCd,
        custEmailTxt: serviceOrder.custEmailTxt,
        servcAddrRefPointDesc: serviceOrder.servcAddrRefPointDesc,
        ibgeCode: serviceOrder.ibgeCode,
        selectedReason: accepted ? undefined : selectedDeniedBudget,
      }

      await updateServiceOrderPromisse(user.dsToken, toUpdate)
      await reloadServiceOrder()

      if (accepted) {
        toast.success('', i18next.t('servc.ord.budget.component.accepted.success'), '')
        onCloseAcceptedQuotationModal()
      } else {
        toast.success('', i18next.t('servc.ord.budget.component.denied.success'), '')
        onCloseDaniedQuotationModal()
      }
      await fetchAndHandleOccurrences()
    } catch (error: any) {
      await fetchAndHandleOccurrences()
      const errorMsg = error?.message?.dsMessage || i18next.t('dao.save.error')
      toast.error('', errorMsg, '')
    }
    store.dispatch(hideSpinner())
  }

  const renderAcceptedQuotationdModal = () => (
    <ConfirmModal
      handleClose={onCloseAcceptedQuotationModal}
      handleRight={onCloseAcceptedQuotationModal}
      handleLeft={() => sendAcceptedOrDeniedQuotation(true)}
      title='servc.ord.budget.component.accepted.modal.title'
      message='servc.ord.budget.component.accepted.modal.message'
      beforeMessage='servc.ord.budget.component.accepted.option.yes.subtitle'
      beforeMessageClassName='confirm-text-sub-option'
      leftBtnLabel='servc.ord.budget.component.accepted.modal.action.btn'
      rightBtnLabel='cancel_button_label'
      actionButton='left'
    />
  )

  const renderDeniedQuotationdModal = () => (
    <ConfirmModal
      handleClose={onCloseDaniedQuotationModal}
      handleRight={onCloseDaniedQuotationModal}
      handleLeft={() => sendAcceptedOrDeniedQuotation(false)}
      title='servc.ord.budget.component.denied.modal.title'
      message='servc.ord.budget.component.denied.modal.message'
      leftBtnLabel='servc.ord.budget.component.denied.modal.action.btn'
      rightBtnLabel='cancel_button_label'
      actionButton='left'
    />
  )

  const onSubmitUploadBudgetFile = async (files: any) => {
    const param = {
      servcOrdSeq: serviceOrderBody?.servcOrdSeq,
      budgAtchmtPathTxt: files,
      type: 'pdf',
    }
    try {
      setIsLoadingAddFile(true)
      await saveServiceOrderFilesBudgets(user.dsToken, param)
      toast.success('', i18next.t('imageTransfer.uploadSuccess'), '')
      setIsLoadingAddFile(false)
      getDocuments(true)
    } catch (error: any) {
      const errorMsg =
        error?.message?.dsMessage || i18next.t('serviceOrder.attachments.documents.fetchError')
      toast.error('', errorMsg, '')
    }
  }

  const onShowBudgetDistributedModal = () => {
    setShowBudgetDistributedModal(true)
  }

  const onShowAcceptedQuotationModal = () => {
    setShowAcceptedQuotationModal(true)
  }

  const onShowDeniedQuotationModal = () => {
    setShowDeniedQuotationModal(true)
  }

  const handleBudgetAccepted = (value: boolean) => {
    setDefaultSelectedAcceptedBudget(value)
    if (value) {
      onShowAcceptedQuotationModal()
    } else {
      onShowDeniedQuotationModal()
    }
  }

  const onChangeAcceptedBudgetsAnswer = (reasonValue: any) => {
    setSelectedDeniedBudget(reasonValue)
    onShowDeniedQuotationModal()
  }

  const isFetching = loadingBody && loadingDetails

  const renderBudget = () => {
    if (isLoadingFiles || isLoadingAddFile || isLoadingRemoveFile || isFetching)
      return (
        <>
          <div className='order-tag-skeleton skeleton' />
          <div className='order-tag-skeleton skeleton' />
          <div className='order-tag-skeleton skeleton' />
        </>
      )

    return (
      <>
        <div>
          <div>
            <BudgetFiles
              onSubmitUploadBudgetFile={onSubmitUploadBudgetFile}
              documentsClient={documentsClient}
              documentsStore={documentsStore}
              handleDeleteFile={handleRemoveFileBudget}
              hideButtonRemove={removeBlocked}
              downloadFile={handleDownloadFile}
              blockUpload={!isBudget}
            />
          </div>

          {validationComponentBudgetDistributed(false) && (
            <BudgetDistributed
              budgetDistributed={onShowBudgetDistributedModal}
              block={distributedBlocked}
              boxSelected={boxBudgetDistributedSelected}
            />
          )}
          {validationComponentBudgetDistributed(true) && (
            <AcceptedBudgets
              serviceOrderOccurrenceQuotationRefusedByExpiration={
                serviceOrderOccurrenceQuotationRefusedByExpiration
              }
              budgetAccepted={handleBudgetAccepted}
              refuseBudgetsReasons={refuseBudgetsReasons}
              onChangeAnswer={onChangeAcceptedBudgetsAnswer}
              defaultSelected={defaultSelectedAcceptedBudget}
              selectedDeniedBudget={selectedDeniedBudget}
            />
          )}
        </div>
        {showBudgetDistributedModal && renderBudgetDistributedModal()}
        {showAcceptedQuotationModal && renderAcceptedQuotationdModal()}
        {showDeniedQuotationModal && renderDeniedQuotationdModal()}
      </>
    )
  }

  // Only show budget details if has the right permission and category
  if (hasPermissionToShowBudgetList()) {
    return (
      <div id='budget-details-container'>
        <Accordeon header={accordeonHeader}>
          <div id='details-budget-container'>{renderBudget()}</div>
        </Accordeon>
      </div>
    )
  }
  return <></>
}

export default WithContext(DetailsBudget)
