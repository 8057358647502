import i18next from 'i18next'

import API_REQUEST from '../../../core/Http/FetchAdapter'
import API_URL from '../../../core/Envs/endpoints'

import User from '../../../core/User'
import store from '../../../redux/store'
import { toast } from '../../../../leroy-merlin-br/backyard-react'

const user = new User().currentUser

export const DistributeService = async (form: any, silent = false) => {
  try {
    const response = await new API_REQUEST(user.dsToken, store.dispatch).post(
      API_URL.DISTRIBUTE_SERVICE,
      form,
    )

    if (!silent) toast.success('', i18next.t('service.order.detail.distribute.modal.success'), '')
    return response
  } catch (err: any) {
    const code = err?.errorCode || 500
    const message = `${i18next.t('service.front.order.detail.observations.edit.error')}: ${code}`

    toast.error('', message, '')
  }
}
